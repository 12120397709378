// Electionform.js
import React, { useState } from 'react';
import Modal from "react-modal";
import { AppImages } from '../../../../Asset/images/image';

const Electionform = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [cnic, setCnic] = useState('');
  const [message, setMessage] = useState('');
  const [formdata , setformData] = useState({
    name:'',
    cnic:'',
    phone:''
  })

  const [errors , setErrors] = useState({
    name:'',
    cnic:'',
    phone:''
  })

  const submitYes = (e)=>{
     setModalMessage(`آپ کا جواب دینا کا بہت شکریہ`);
    setShowModal(true);
  }
  const submitNo = (e)=>{
    setModalMessage(`آپ کا جواب دینا کا بہت شکریہ ہم جلد ہی آپ سے رابطہ کریں گے۔`);
    setShowModal(true);
  }

  const closeModal = () => {
  window.location.reload();
    setShowModal(false);
    setModalMessage('');
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formdata, [name]: value });
    // setErrors({ ...errors, [name]: '' });
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if CNIC is provided
    if (!cnic) {
      setMessage('Please enter your CNIC.');
      return;
    }

    // Perform API request here
    try {
        console.log("Cniccc",cnic)
      // Replace the following line with your API request logic
      // const response = await fetch('your_api_endpoint', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ cnic }),
      // });
      // const data = await response.json();
      setMessage('API request successful!');
    } catch (error) {
      setMessage('Error occurred during API request.');
    }
  };

   const handleSubmit1 = async (e) => {
    e.preventDefault();

   const newErrors = {};
  Object.keys(formdata).forEach((key) => {
    if (!formdata[key]) {
      newErrors[key] = 'This field is required';
    }
  });

  if (Object.keys(newErrors).length > 0) {
    setErrors(newErrors);
    return;
  }

    // Perform API request here
    try {
        console.log("FormDataaa",formdata)
      // Replace the following line with your API request logic
      // const response = await fetch('your_api_endpoint', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ cnic }),
      // });
      // const data = await response.json();
      setMessage('API request successful!');
    } catch (error) {
      setMessage('Error occurred during API request.');
    }
  };

  return (
    <div className="container mx-auto mt-8">
        
      <form onSubmit={handleSubmit} className="max-w-md mx-auto px-4">
       <div className="flex justify-center">
          <img src={AppImages.electionImage} alt="" className='mx-auto w-[30%]'/>
       </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cnic">
            CNIC:
          </label>
          <input
            type="text"
            id="cnic"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter your CNIC"
            value={cnic}
            onChange={(e) => setCnic(e.target.value)}
          />
          {/* {message && <p className="text-red-500 text-xs italic">{message}</p>} */}
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-green-800 w-full hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Search
          </button>
        </div>
        </form>
        {
          message === '' ? (''):
          (   
        //     <div>
        // <form onSubmit={handleSubmit1} className="max-w-md mx-auto px-4">
        //  <div className="mb-4">
        //   <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
        //     Name
        //   </label>
        //   <input
        //     type="text"
        //     id="name"
        //     name="name"
        //     placeholder='name'
        //     value={formdata.name}
        //     onChange={handleInputChange}
        //     className={`w-full p-2 border ${errors.name && 'border-red-500'}`}
        //   />
        //   {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
        // </div>
        // <div className="mb-4">
        //   <label htmlFor="cnic" className="block text-gray-700 text-sm font-bold mb-2">
        //     Cnic
        //   </label>
        //   <input
        //     type="text"
        //     id="cnic"
        //     name="cnic"
        //     placeholder='cnic'
        //     value={formdata.cnic}
        //     onChange={handleInputChange}
        //     className={`w-full p-2 border ${errors.cnic && 'border-red-500'}`}
        //   />
        //   {errors.cnic && <p className="text-red-500 text-xs mt-1">{errors.cnic}</p>}
        // </div>
        // <div className="mb-4">
        //   <label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">
        //     Phone
        //   </label>
        //   <input
        //     type="number"
        //     id="phone"
        //     name="phone"
        //     placeholder='phone'
        //     value={formdata.phone}
        //     onChange={handleInputChange}
        //     className={`w-full p-2 border ${errors.phone && 'border-red-500'}`}
        //   />
        //   {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
        // </div>
        // <div className="flex items-center justify-between">
        //   <button
        //     type="submit"
        //     className="bg-green-800 w-full hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        //   >
        //     Submit
        //   </button>
        // </div>
        // </form>
        //     </div>
             <div className='flex flex-col max-w-md mx-auto px-4'>
           <div className='flex flex-col py-2'>
             <div className='font-bold text-gray-700 text-[18px]'>Name : </div>
             <div className='text-[18px]'>Umer Daraz</div>
           </div>
           <div className='flex flex-col py-2'>
             <div className='font-bold text-gray-700 text-[18px]'>Phone number : </div>
             <div className='text-[18px]'>0321 44457874</div>
           </div>
           <div className='flex flex-col py-2'>
             <div className='font-bold text-gray-700 text-[18px]'>Cnic : </div>
             <div className='text-[18px]'>35201-7845716-3</div>
           </div>
           <div>
            <div className='font-bold text-gray-700 text-[18px]' dir='rtl'>
              کیا آپ کو آپ کی ادائیگی موصول ہوئی ہے؟
            </div>
            <div className='flex flex-row justify-between mx-[70px] my-2'>
               <div
  className='bg-red-500 text-white w-[25%] rounded-md text-center font-semibold py-2 cursor-pointer hover:bg-red-400'
  onClick={submitNo}
>
  نہیں
</div>

              <div className='bg-green-500 text-white w-[25%] rounded-md text-center font-semibold py-2 cursor-pointer hover:bg-green-400'
             onClick={submitYes}> ہاں</div>
              
            </div>

           </div>

        </div>
        )
        }
       <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Payment S"
        // className="modal"
        // overlayClassName="overlay"
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '10px',
            marginRight: '10px',
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '400px',
            width: '100%',
            transform: 'none',
          },
        }}
      >
        <div className="text-center">
          <div className='font-semibold text-slate-600 text-[18px]'>{modalMessage}</div>
          <button onClick={closeModal} className="bg-blue text-white px-4 py-2 rounded mt-4">
            Close
          </button>
        </div>
      </Modal>
      
    </div>
  );
};

export default Electionform;
