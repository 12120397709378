import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function BackArrow({ onClick }) {
  const navigate = useNavigate()
  return (  
    <div
      className="pl-4 my-2"
      onClick={(e) => {
        navigate(-1);
        if(typeof onClick === 'function') {
          onClick(e)
        }
      }}
    >
      <div className="flex items-center">
        <div className="mr-2">
          <FaArrowLeft size={'15px'} />
        </div>
        <div className="text-[15px] font-semibold">Back</div>
      </div>
    </div>
  );
}

export default BackArrow;