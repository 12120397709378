import React from "react";
import StripeCheckout from "react-stripe-checkout";

export default function Stripe() {
  return (
    <div className="App">
      <StripeCheckout
        stripeKey="pk_test_51LZTNvKyvQGLQFBk5V8X94CXji1ZhAM9Sqlx8W6ksGNkVPyCGVmk3R9jTAIrU20ygK0bdGe5DqwEkB1q1uJsYGJl00vDuLHGlJ"
        token={() => {}}
        billingAddress
        shippingAddress
        amount={50 * 100}
        name="Abonnement Annuelle" 
      />
    </div>
  );
}
