import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackArrow from "../BackArrow.jsx";
import { formatNumber } from "../../../../utils/helpers.js";
import ModalImage from "react-modal-image";
import Modal from "react-modal";
import { AppImages } from "../../../../Asset/images/image.js";
import firebase_service from "../../../../utils/firebase_service1.js";

const SingleProductViewBusiness = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedItem, setUpdatedItem] = useState({});
  const [updatedImages, setUpdatedImages] = useState([]);
  const [updateFields, setUpdateFields] = useState({}); // State to track updated fields
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage] = useState(null);

const navigate = useNavigate();
  const openModal = () => {
    setIsModalOpen(true);
    setUpdatedItem({
      name: item.name,
      price: item.price?.amount  || 0,
      description: item.description,
      availability: item.availability,
    });
    setUpdatedImages([...item.images]);
    setUpdateFields({}); // Initialize the updateFields state
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdate = () => {
  // Construct the request body with updated fields
  setIsLoading(true);
  const requestBody = {
    product_id: item._id,
    name: updateFields.name || item.name, // Use updated name or the original if not updated
    description: updateFields.description || item.description, // Use updated description or the original if not updated
    category: updateFields.category || item.category, // Use updated category or the original if not updated
    image_urls: updatedImages, // Updated images
    price:  updateFields.price || item.price, // Use updated price or the original if not updated
    availability: updateFields.availability || item.availability,
  };

  // Log the updated values to the console
  console.log("Updated values:", requestBody);

  // Send a POST request to the API
  fetch("https://7b7xlap5jvkahyo5himfrzqy640qnadr.lambda-url.eu-west-1.on.aws/app/my-business/products/update", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      if (!response.ok) {
        setIsLoading(false);
        throw new Error("API request failed");
         
      }
      return response.json();
    })
    .then((data) => {
      // Handle the response data if needed
       setIsLoading(false);
       navigate("/app/business")
       closeModal();
    })
    .catch((error) => {
      console.error("API request error:", error);
    });

  
};


 const handleImageChange = async (e) => {
    const selectedImages = Array.from(e.target.files);
     setIsLoading(true)
    if (selectedImages.length > 0) {
      const imageUrls = await firebase_service.uploadManyFilesOnFirebase({
        files: selectedImages,
        foldername: "business-portal-images",
      });
    setIsLoading(false)
      setUpdatedImages([...updatedImages, ...imageUrls]); // Add new image URLs to the existing array
    }
  };

console.log("seletedImagesss",selectedImage)


  const handleRemoveImage = (index) => {
    
    console.log("indexxxxx",index)
    const updatedImagesCopy = [...updatedImages];
    updatedImagesCopy.splice(index, 1);
    setUpdatedImages(updatedImagesCopy);
  };
  const location = useLocation();
  const { item } = location.state || {}; // Initialize with an empty object
  console.log("ItemDataaa",item)
  if (!item) {
    return <div>Item not found</div>;
  }
  return (
    <div>
      <div className="flex flex-col mt-[10px]  w-[full] ">
        <BackArrow />
        <div className="flex flex-col items-center justify-center w-full h-[300px]">
          {item?.images.length > 0 ? (
            <img
              src={item?.images[0]}
              alt=""
              className="rounded-md h-full"
            />
          ) : (
            <div className="bg-slate-100 text-[22px] font-semibold w-full h-full flex items-center justify-center">
              {item?.name.slice(0, 2)}
            </div>
          )}
        </div>

        <div className="flex flex-row justify-between mt-2 text-[#2B2B2B] text-[17px] mx-3">
          <div className="font-semibold">{item?.name}</div>
          <div>
            {" "}
            <div className="text-[#2B2B2B] font-semibold opacity-50">
              {item?.price?.amount
                ? `Rs. ${formatNumber(item.price.amount)}`
                : ""}
            </div>
          </div>
        </div>
        {item?.description === "" ? (
          <div></div>
        ) : (
          <div>
            <div className="text-[13px] mx-3 mt-1 text-[#2B2B2B] opacity-50">
              {item?.description}
            </div>
          </div>
        )}
        <div className="text-[13px] mx-3 mt-1 text-[#2B2B2B] opacity-50">
          {item?.availability === "0" ? "not available" : "available"}
        </div>
        <div className="border-1 border-slate-400"></div>
        <div className=" mt-6 ">
          <div className="my-3 mx-3 flex flex-wrap">
            {item?.images.map((file, index) => (
            //   <img
            //   src={file}
            //   alt="First Image"
            //   className="h-[80px] w-[100px]  mr-1 mb-1 rounded-md"
            // />
              <ModalImage
                key={index}
                small={file}
                large={file}
                // alt={`Uploaded Image ${index}`}
                className="h-[80px] w-[100px]  mr-1 mb-1 rounded-md"
              />
            ))}
          </div>
        </div>
        
      </div>
      {/* <button onClick={openModal}>Update</button> */}
       <div className="flex items-center justify-center mt-3 ">
            <div className="">
              <button
                className="rounded-md py-2 mb-5 text-[15px] w-full bg-[#009bfb] hover:bg-lightBlue-600 text-white disabled:bg-slate-200"
                onClick={openModal}
                // disabled={isLoading}
              >
                {/* {isLoading ? (
                  <img src={AppImages.loading} className="w-[20px] mx-5 py-2" />
                ) : ( */}
                  <span className="mx-2 mx-1"> Update Product</span>
                {/* )} */}
              </button>
            </div>
          </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Update Item"
        >
          <h2>Update Item</h2>
          <form>
            
            <input
              type="text"
              placeholder="Name"
              value={updatedItem.name}
              className="bg-gray-100 px-3 py-3 border-[#5e5954] rounded-md focus:outline-[#2196F3] w-full text-[13px] my-2"
              onChange={(e) => {
                setUpdatedItem({ ...updatedItem, name: e.target.value });
                setUpdateFields({ ...updateFields, name: e.target.value }); // Track the update in the updateFields state
              }}
            />
            <input
              type="number"
              placeholder="Price"
              value={updatedItem.price}
              className="bg-gray-100 px-3 py-3 border-[#5e5954] rounded-md focus:outline-[#2196F3] w-full text-[13px] my-2"
              onChange={(e) => {
                setUpdatedItem({ ...updatedItem, price: e.target.value });
                setUpdateFields({ ...updateFields, price: e.target.value }); // Track the update in the updateFields state
              }}
            />
            <textarea
              placeholder="Description"
              value={updatedItem.description}
              className="bg-gray-100 px-3 py-3 border-[#5e5954] rounded-md focus:outline-[#2196F3] w-full text-[13px] my-2"
              onChange={(e) => {
                setUpdatedItem({ ...updatedItem, description: e.target.value });
                setUpdateFields({ ...updateFields, description: e.target.value }); // Track the update in the updateFields state
              }}
            />
            <select
              value={updatedItem.availability}
              className="bg-gray-100 px-3 py-3 border-[#5e5954] rounded-md focus:outline-[#2196F3] w-full text-[13px] my-2"
              onChange={(e) => {
                setUpdatedItem({ ...updatedItem, availability: e.target.value });
                setUpdateFields({ ...updateFields, availability: e.target.value }); // Track the update in the updateFields state
              }}
            >
              <option value="0">Not available</option>
              <option value="1">Available</option>
            </select>
            {updatedImages.map((image, index) => (
          <div key={index} className="relative w-[130px] h-[120px]">
              <img src={image} alt={`${index}`} className="w-[130px] h-[100px]" />
  <button
    onClick={(e) => {
      e.preventDefault();
      handleRemoveImage(index);
    }}
    className="absolute top-0 right-0 px-[5px]  bg-red-500 text-white rounded-full"
  >
    x
  </button>
</div>

        ))}
        <input type="file" accept="image/*" onChange={handleImageChange} />

            {/* <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            
            <div>
              <MBUploadReceipt
                      onChange={(files) => {
                        if (files?.length) {
                          setImageFiles(files);
                        }
                      }}
                    />
            </div> */}

            {/* Add other fields you want to update here */}
            <div className="flex items-center justify-center mt-3 ">
            <div className="">
              <button
                className="rounded-md py-2 mb-5 text-[15px] w-full bg-[#009bfb] hover:bg-lightBlue-600 text-white disabled:bg-slate-200"
                onClick={(e) => handleUpdate()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <img src={AppImages.loading} className="w-[20px] mx-5 py-2" alt="" />
                ) : (
                  <span className="mx-2 mx-1"> Update</span>
                )}
              </button>
            </div>
          </div>
           <div className="flex items-center justify-center mt-3 ">
            <div className="">
              <button
                className="rounded-md py-2 mb-5 text-[15px] w-full bg-[#009bfb] hover:bg-lightBlue-600 text-white disabled:bg-slate-200"
                onClick={closeModal}
                // disabled={isLoading}
              >
                {/* {isLoading ? (
                  <img src={AppImages.loading} className="w-[20px] mx-5 py-2" />
                ) : ( */}
                  <span className="mx-2 mx-1"> Cancel</span>
                {/* )} */}
              </button>
            </div>
          </div>
            
          </form>
        </Modal>
    </div>
  );
};

export default SingleProductViewBusiness;
