import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppImages } from "../../../../Asset/images/image.js";
import ReactModal from "react-modal";
import firebase_service from "../../../../utils/firebase_service.js";
import {
  LAMBDA_API_BUSINESS_PORTAL_URL,
  MyBusinessContext,
} from "../contexts/MyBusinessContext.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import AppToastContainer from "../../../basic/AppToast.jsx";
import FlutterInterface from "../../../../utils/flutter_interface.js";
import { isIOS } from "../../../../utils/helpers.js";
import MBUploadPhotos1 from "./MBUploadPhotos1.jsx";

function AddItem() {
  const ctx = useContext(MyBusinessContext);
   const [isModalOpen, setIsModalOpen] = useState(false);

   const openModal1 = () => {
   setShowSuccessModal1(true)
  };
  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();
  const isApple = useMemo(() => isIOS(), []);
  const fields = {
    description: null,
    category:null,
    discount:null,
    title: null,
    name:null,
    price: 0,
    availability: null,
    prep_time: null,
    // images: null,
    // terms: null,
  };
  const option = [
    { name: "available", value: 1 },
    { name: "not available", value: 0 },
  ];

  const [formFields, setFormFields] = useState(fields);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
   const [showSuccessModal, setShowSuccessModal] = useState(false);
   const [showSuccessModal1, setShowSuccessModal1] = useState(false);
  const [imageFiles, setImageFiles] = useState(null);
  // console.log("Imagesss",imageFiles)
  const [next1] = useState(0);
  // console.log("Next",next1)
  useEffect(() => {
    if (ctx.business) {
      setFormFields({
        ...formFields,
        category: ctx.business.category,
      });
    }
  }, [ctx.business, formFields]);
   
  const [discount, setDiscount] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const handleDiscountChange = (e) => {
    // console.log("Type", typeof formFields.price)
    const newDiscount = parseFloat(e.target.value);
    setDiscount(newDiscount);
      const discountedAmount = formFields.price - (formFields.price * newDiscount) / 100;
      setDiscountPrice(discountedAmount)
    // } else {
    //   toast.error("Please enter a valid discount value between 0 and 90");
    // }
  };
  const [selectedItems, setSelectedItems] = useState([]);
  const handleCheckboxChange = (item) => {
    // Add the data of the clicked checkbox to a new array
    setSelectedItems((prevArray) => [...prevArray, {...item, is_mandatory: item.is_mandatory === 'mandatory', is_free: item.is_free !== 'additonal'}]);
  };

  const handleRemoveItem = (itemToRemove) => {
    // Filter out the selected item to remove it from the array
    const updatedItems = selectedItems.filter(item => item !== itemToRemove);
    setSelectedItems(updatedItems);
  };
  const [addons, setAddons] = useState([])
  const handleChange = (e) => {
  };

  const handleAddItem = (e) => {
    e.preventDefault()
    const fd = new FormData(e.target)
    const title = fd.get('title')
    const is_mandatory = fd.get('is_mandatory') === 'mandatory'
    const is_free = fd.get('is_free') === 'free'
    const price = fd.get('price')
    const item = {
      title,
      is_mandatory,
      is_free,
      price
    }

    setAddons([...addons, item])

   setShowSuccessModal1(false);
  };

  // console.log(formFields, selectedItems);
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("AddOnss",selectedItems)
    // return;
    setFieldErrors(null);
   
    setIsLoading(true);

    const errors = {};
    if (!formFields.description) {
      errors.description = "Please enter description";
    }
    if (!formFields.title) {
      errors.title = "Please enter title of your business";
    }
    if (!formFields.price) {
      errors.price = "Please enter address of your price";
    }
    // if (!formFields.category) {
    //   errors.category = "Please choose category your business belongs to.";
    // }
    if (!formFields.availability) {
      errors.availability = "This field is availabilty";
    }

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      setIsLoading(false);
      return;
    }

    let image_urls = [];
    if (imageFiles?.length) {
      image_urls = await firebase_service.uploadManyFilesOnFirebase({
        files: imageFiles.map((f) => f),
        foldername: "business-portal-images",
      });
    }
    // console.log("imagesss", image_urls);
   
     let business_id = ctx.env?.business_id;
    // console.log("BusinesssId",business_id)
    if (!business_id) {
      business_id = await FlutterInterface.getUserId();
      if (business_id) {
        ctx.setEnv({ ...ctx.env, business_id: business_id });
      }
    }

    axios.post(LAMBDA_API_BUSINESS_PORTAL_URL + "/app/my-business/products/add", {
        business_id: business_id,
        name: formFields.title,
        title: formFields.title,
        description: formFields.description,
        price: formFields.price,
        availability: formFields.availability,
        category: formFields.category,
        image_urls: image_urls,
        addons: selectedItems,
        discount: discount,

      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data?.status !== 200) {
          throw res.data;
        } else {
          
          setShowSuccessModal(true);
         
        }
      })
      .catch((e) => {
        // console.log(e);
        if (e?.error) {
          toast.error(
            e?.isOperationalError ? "Something went wrong" : e?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

  };
  return (
    <>
      <Helmet>
        <style>
          {`
          .PhoneInputInput {
            background-color: rgb(243 244 246 / var(--tw-bg-opacity));
            padding: 0.75rem;
            outline-color: #2196F3;
          }
          `}
        </style>
      </Helmet>
      <AppToastContainer />
      <div className="flex flex-col mt-[10px] items-center w-[full]">
        <div className="flex items-center justify-center text-[#181D27] text-[19px]">
          Add Item
        </div>
        <div className=" border-[1px] border-[#E8E8E9] w-full mx-2 mt-3"></div>
        <form
          onSubmit={onSubmit}
          className="text-[#2B2B2B]  mt-[1px] text-[20px] w-[80%] md:w-[60%] mx-auto"
        >
          <div className={next1 === 0 ? "" : "hidden"}>
            <div className="">
              <div className=" ">
                <TextField
                  label={"Title"}
                  placeholder="Enter item title"
                  value={formFields?.title ?? ""}
                  error={fieldErrors?.title}
                  onChange={(e) =>
                    setFormFields({ ...formFields, title: e.target.value })
                  }
                />
                <TextField
                  label={"Description"}
                  placeholder="Type here"
                  error={fieldErrors?.description}
                  onChange={(e) =>
                    setFormFields({
                      ...formFields,
                      description: e.target.value,
                    })
                  }
                />

                <div className="mt-4">
                  <TextFieldLabel label={"Business Category"} />
                  <select
                    className="text-[13px] block w-full bg-gray-100 disabled:bg-gray-100 py-3 rounded-md px-3 focus:outline-[#2196F3]"
                    onChange={(e) =>
                      setFormFields({ ...formFields, category: e.target.value })
                    }
                    value={ctx.business?.category}
                    disabled
                  >
                    <option>Choose one..</option>
                    <option value={ctx.business?.category}>
                      {ctx.business?.category}
                    </option>
                  </select>
                  {fieldErrors?.category ? (
                    <TextFieldError text={fieldErrors?.category} />
                  ) : (
                    <></>
                  )}
                </div>
                

          <TextField2
        label={"Item Price"}
        placeholder="e.g PKR 500.00"
        error={fieldErrors?.price}
        onChange={(e) => setFormFields({ ...formFields, price: e.target.value })}
      />

<div className="flex flex-row">
          <div>
             <TextField1
        label={"Discount"}
        placeholder="% 50"
        error={fieldErrors?.discount}
        onChange={handleDiscountChange}
      />
          </div>
          <div className=" mt-[42px] ml-2">
            <div className="px-3 py-3 border-[#E8E8E9] border-[1px] rounded-md w-[118px] focus:outline-[#2196F3] text-[13px]">
            {discountPrice === 0 || isNaN(discountPrice) ? 'Rs. 0' : `Rs. ${discountPrice ?? ""}`}  
            </div>
           
          </div>
        </div>

        {
          discountPrice === 0 || isNaN(discountPrice)? (
            ''
          ) : (
            <div className="text-[13px] text-[#2196F3] mt-2">
              New Item Price after discount: PKR {discountPrice}
              </div>
          )
        }
     

     

                 <TextField2
                  label={"Preparation time"}
                  placeholder="e.g 25min"
                  // error={fieldErrors?.description}
                  onChange={(e) =>
                    setFormFields({
                      ...formFields,
                      prep_time: e.target.value,
                    })
                  }
                />

                <div className="mt-4">
                  <TextFieldLabel label={"Availability"} />
                  <select
                    className={
                      "text-[13px] block w-full bg-gray-100 py-3 rounded-md px-3 focus:outline-[#2196F3]" +
                      (isApple ? " appearance-none h-[42px]" : "")
                    }
                    onChange={(e) =>
                      setFormFields({
                        ...formFields,
                        availability: e.target.value,
                      })
                    }
                  >
                    <option>Select</option>
                    {option.map((item, index) => (
                      <option key={"item" + index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {fieldErrors?.availability ? (
                    <TextFieldError text={fieldErrors?.availability} />
                  ) : (
                    <></>
                  )}
                </div>

                <div className="mt-4">
                  <TextFieldLabel label="Upload Images" />
                  {fieldErrors?.images ? (
                    <TextFieldError text={fieldErrors?.images} />
                  ) : (
                    <></>
                  )}

                  <MBUploadPhotos1
                    onChange={(files) => {
                      if (files?.length > 0) {
                        setImageFiles(files);
                        // compressImages(files);
                      }
                    }}
                  />
                </div>
                
                <div className="mt-4">
                   <div className="text-[#181D27] text-[13px] font-semibold mb-2">Add-ons</div>
 <ul>
          {selectedItems.map(selectedItem => (
            <li key={selectedItem}>
              <div className="flex flex-row justify-between my-2">
                <div className="flex flex-row items-center">
                <div className="text-[16px]">
                {selectedItem.title}
                </div>
                <div className="text-[11px] ml-3">
                <span>{selectedItem.is_mandatory}</span>,<span>{selectedItem.is_free}</span>
                </div>
                </div>
               <div className="text-[11px] text-[#FF4444]" onClick={() => handleRemoveItem(selectedItem)}>
                  Remove
                </div>
              </div>
            </li>
          ))}
        </ul>
                   <div
  className="rounded-md mb-5 text-[16px] w-[131px] h-[38px] border-[1px] border-[#E8E8E9] hover:bg-lightBlue-600 text-[#2196F3] disabled:bg-slate-200"
   onClick={openModal}
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
  <span>
    Add <span className="text-[22px] ml-3">+</span>
  </span>
</div>

            
                </div>
              </div>
            </div>
            <button
                className="rounded-md py-2 mb-5 text-[15px] w-full bg-[#009bfb] hover:bg-lightBlue-600 text-white disabled:bg-slate-200"
                disabled={isLoading}
              >
                {isLoading ? (
                  <img src={AppImages.loading} className="w-[20px] mx-auto" alt="" />
                ) : (
                  <span>Submit</span>
                 )} 
              </button>
          </div>

        </form>
         <ReactModal
           isOpen={isModalOpen}
            className="Modal Success-Modal"
            overlayClassName="Modal-Overlay"
          >
              <div className="text-center text-[23px] text-[#181D27] font-medium">Add-ons</div>
      <form>
      
         <ul>
       {addons.map(item => (
          <li>
            <div className="flex flex-row w-full my-2">
              <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(item)}
              />
              <div className="flex flex-row justify-between w-full ml-1 mr-2">
                <div>{item.title}</div>
                <div>{item.is_mandatory ? "Mandatory" : "Optional"}</div>
              </div>
            </div>
          </li>
        ))}
      </ul>

        
          <div className="">
             <div
                className="py-2 block mx-auto text-center w-full border-[1px] border-[#2196F3] my-4 text-[#2196F3]"
               onClick={
                openModal1}
               
              >
                  
                  <span className=" text-[14px]"><span className="text-[20px] mr-2"> + </span> Add a new Add-on</span>
              
              </div>
              <div
                className="py-2 block mx-auto text-[14px] text-center w-full bg-[#E8E8E9] my-4 hover:bg-lightBlue-600 text-white"
               onClick={
                closeModal}
               
              >
              
                  <span>Submit</span>
              
              </div>
              <div
                className="text-center text-[#1E89DD] bg-[#E9F5FE] px-4 border-[#24ACE3] py-2 block mx-auto text-[14px]"
                onClick={closeModal}
              >
                Cancel
              </div>
            </div>
      </form>
          </ReactModal>
            {showSuccessModal1 && (
          <ReactModal
            isOpen={true}
            className="Modal Success-Modal"
            overlayClassName="Modal-Overlay"
          >
            <div className="p-4">
              <form onSubmit={handleAddItem}>
        <div>
          
          <label htmlFor="title" className="text-[13px] my-1 text-[#181D27] font-semibold">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            className="px-3 py-3 border-[#E8E8E9] border-[1px] rounded-md focus:outline-[#2196F3] w-full text-[13px]"
            // value={formData.title}
            onChange={handleChange}
          />
        </div>

        <div className="mt-4 text-[13px]">
          <label className="text-[13px] my-1 text-[#181D27] font-semibold">Is this add-on optional or mandatory?</label>
          <div className="mt-2">
            <label>
              <input
                type="radio"
                name="is_mandatory"
                value="optional"
                // checked={formData.is_mandatory === "optional"}
                // onChange={() => handleRadioChange("is_mandatory", "optional")}
              />
              Optional
            </label>
            <label className="ml-3">
              <input
                type="radio"
                name="is_mandatory"
                value="mandatory"
                // checked={formData.is_mandatory === "mandatory"}
                // onChange={() => handleRadioChange("is_mandatory", "mandatory")}
              />
              Mandatory
            </label>
          </div>
        </div>

        <div className="mt-4 text-[13px]">
          <label className="text-[13px] my-1 text-[#181D27] font-semibold">Is this add-on free or are there additional charges?</label>
          <div className="mt-2">
            <label>
              <input
                type="radio"
                name="is_free"
                value="free"
                // checked={formData.is_free === "free"}
                // onChange={() => handleRadioChange("is_free", "free")}
              />
              Free
            </label>
            <label className="ml-3">
              <input
                type="radio"
                name="is_free"
                value="additional"
                // checked={formData.is_free === "additional"}
                // onChange={() => handleRadioChange("is_free", "additional")}
              />
              Additional Charges
            </label>
          </div>
        </div>

        <div className="mt-4">
          <label htmlFor="price" className="text-[13px] my-1 text-[#181D27] font-semibold">Price:</label>
          <input
            type="text"
            id="price"
            name="price"
            className="px-3 py-3 border-[#E8E8E9] border-[1px] rounded-md focus:outline-[#2196F3] w-full text-[13px]"
            // value={formData.amount}
            onChange={handleChange}
          />
        </div>

        
          <div className="mt-4">
            <button   className="rounded-md py-2 mb-5 text-[15px] w-full bg-[#2196F3]  hover:bg-lightBlue-600 text-white disabled:bg-slate-200">Add a new item</button>
            
              <div
                className="text-center py-2 text-[#1E89DD] bg-[#E9F5FE] px-4 border-[#24ACE3] block mx-auto text-[14px]"
               onClick={(e) => {
                   setShowSuccessModal1(false);
                }}
              >
                Cancel
              </div>
            </div>
      </form>
            </div>
          </ReactModal>
        )}
        {showSuccessModal && (
          <ReactModal
            isOpen={true}
            className="Modal Success-Modal"
            overlayClassName="Modal-Overlay"
          >
            <div className="p-4">
              <div className="flex items-center justify-center">
                <img
                  src={AppImages.checkmark}
                  alt="checkmark"
                  className="w-[60px] svg-green"
                />
              </div>
              <p className="text-center my-4 text-green-600 font-medium">
                Your Item has been added successfully!
              </p>
              <div
                className="text-center p-2 cursor-pointer"
                onClick={(e) => {
                  
                  navigate("/app/my-business/stats");
                  // window.location.reload();
                }}
              >
                <p className=" font-bold text-[14px] text-green-600 border-dotted border-b-[1px] inline">
                  Click to view
                </p>
              </div>
            </div>
          </ReactModal>
        )}
      </div>
    </>
  );
}

function TextFieldLabel({ label, htmlFor }) {
  return (
    <label htmlFor={htmlFor} className="text-[13px] my-1 text-[#181D27] font-semibold">
      {label}
    </label>
  );
}

function TextField({ label, id, placeholder = "", disabled = false, onChange, error = null }) {
  return (
    <div className="mt-4">
      <TextFieldLabel label={label} htmlFor={id} />
      <input
        id={id}
        type="text"
        disabled={disabled}
        placeholder={placeholder ?? label}
        className="px-3 py-3 border-[#E8E8E9] border-[1px] rounded-md focus:outline-[#2196F3] w-full text-[13px]"
        onChange={onChange}
      />
      {error ? <TextFieldError text={error} /> : <></>}
    </div>
  );
}

function TextField2({ label, id, placeholder = "", disabled = false, onChange, error = null }) {
  return (
    <div className="mt-4">
      <TextFieldLabel label={label} htmlFor={id} />
      <input
        id={id}
        type="number"
        disabled={disabled}
        placeholder={placeholder ?? label}
        className="px-3 py-3 border-[#E8E8E9] border-[1px] rounded-md focus:outline-[#2196F3] w-full text-[13px]"
        onChange={onChange}
      />
      {error ? <TextFieldError text={error} /> : <></>}
    </div>
  );
}

function TextField1({ label, id, placeholder = "", disabled = false, onChange, error = null }) {
  return (
    <div className="mt-4 flex flex-col">
      <TextFieldLabel label={label} htmlFor={id} />
      <input
        id={id}
        type="text"
        disabled={disabled}
        placeholder={placeholder ?? label}
        className="px-3 py-3 border-[#E8E8E9] border-[1px] rounded-md w-[118px] focus:outline-[#2196F3] text-[13px]"
        onChange={onChange}
      />
      {error ? <TextFieldError text={error} /> : <></>}
    </div>
  );
}

function TextFieldError({ text }) {
  return <div className="text-red-500 text-[13px] my-1">{text}</div>;
}

export default AddItem;
