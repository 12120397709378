import { useEffect, useState } from "react";
import { AppImages } from "../../../../Asset/images/image.js";
import { Button } from "@mui/material";
import Modal from "react-modal";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FlutterInterface from "../../../../utils/flutter_interface.js";

function SingleDiscountScreen() {
    const modalStyle = {
        content: {
            top: "50%",
            maxWidth: "488px",
            width: "90vw",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "90vh",
            height: '27vh',
            overflowY: "hidden",
            borderRadius: "18px",
            position: "relative"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0, 0.5)",
        },
    };
    const { messageId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ifLoading, setIfLoading] = useState(false);
    const [apiData, setApiData] = useState("");
    const [apiError, setApiError] = useState("")
    const [isBalance, setIsBalance] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
    const [usageId, setUsageId] = useState('');
    const [voucherId, setVoucherId] = useState('');
    const [isError, setIsError] = useState('')
    const [ifError, setIfError] = useState('')
    const [isAcceptable, setIsAcceptable] = useState(null);
    const [isRejectable, setIsRejectable] = useState(null);
    const [status, setStatus] = useState('');
    const [isStatus, setIsStatus] = useState(false);
    const [isApiError, setIsApiError] = useState(false);
    const [isAmountField, setIsAmountField] = useState(false);
    const [amountError, setAmountError] = useState(false);
    const [amountLoading, setAmountLoading] = useState(false);
    const [formFields, setFormFields] = useState({
        amount: '',
    });
    const [errors, setFormErrors] = useState({
        amount: '',
        api: null,
    });
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/super-messages/get-message-detail?super_message_id=${messageId}&ref=verify_redeem_offer_request`)
                // const data = await response.json();
                console.log("api response for offer info", response);
                if (response.data.error === true) {
                    setIsApiError(true);
                } else {
                    if (response?.data.status === 200) {
                        console.log("response--------", response);
                        setApiData(response?.data.data);
                        setUsageId(response?.data?.data?.usage_id);
                        setVoucherId(response?.data?.data?.voucher_id);
                        setIsAcceptable(response?.data.data.is_acceptable);
                        setIsRejectable(response?.data.data.is_rejectable);
                        if (response?.data.data.show_amount_field) {
                            setIsAmountField(true);
                        } else {
                            setIsAmountField(false);
                        }
                        if (response?.data.data.status !== '') {
                            if (response?.data.data.status === "accepted") {
                                setStatus("This Offer has already been accepted")
                                setIsStatus(true);
                            } else if (response?.data.data.status === "rejected") {
                                setStatus("This offer has already been rejected");
                                setIsStatus(true);
                            } else {
                                setStatus('');
                            }
                        }
                    } else {
                        setApiError(response?.message ?? "something went wrong!")
                    }
                }
            } catch (error) {
                console.error("Error", error)
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);

    console.log("data for offer:", apiData);
    const handleClick = async () => {
        try {
            let enteredAmount
            if(isAmountField) {
                enteredAmount = parseFloat(formFields.amount)
                if(!enteredAmount || isNaN(enteredAmount) || enteredAmount <= 0) {
                    toast.error('Please enter the discount amount')
                    return;
                }
            }
            setLoading(true);
            const response = await axios.post('https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/vouchers/accept-redeem-offer-request', {
                voucher_id: voucherId,
                voucher_usage_uuid: usageId,
                discount_amount: enteredAmount
            })
            // const data = await response.json();
            console.log("response", response);
            if (response?.data.error) {
                setIsError(response?.data.message)
                console.log("error", response?.data.message);
                setIsModalOpen(true);
            } else {
                setIsModalOpen(true);
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setLoading(false);
        }
        setIsModalOpen(true);
    }

    const handleReject = async () => {
        try {
            setIfLoading(true);
            const response = await axios.post('https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/vouchers/reject-redeem-offer-request', {
                voucher_id: voucherId,
                voucher_usage_uuid: usageId
            })
            // const data = await response.json();
            console.log("response", response);
            if (response?.data.error) {
                setIfError(response?.data.message)
                console.log("error", response?.data.message);
                setIsSecondModalOpen(true);
            } else {
                setIfError(null);
                setIsSecondModalOpen(true);
            }
        } catch (error) {
            console.error("Error", error);
        }
        setIsSecondModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
        FlutterInterface.goToMessagesChatScreen();
    };
    const closeSecondModal = () => {
        setIsSecondModalOpen(false);
        FlutterInterface.goToMessagesChatScreen();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    const handleAmount = async () => {
        try {
            setAmountLoading(true);
            if (!formFields.amount.length && formFields.amount <= 0) {
                setAmountError("Amount is required!");
                toast.error("Amount is required!")
                setAmountLoading(false);
            } else {
                const response = await axios.post('https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/vouchers/accept-redeem-offer-request', {
                    voucher_id: voucherId,
                    voucher_usage_uuid: usageId,
                    discount_amount: formFields.amount
                })
                console.log("response", response);
                if (response?.data.error) {
                    setIsError(response?.data.message)
                    console.log("error", response?.data.message);
                    setIsModalOpen(true);
                } else {
                    setIsModalOpen(true);
                }
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setAmountLoading(false);
        }
        setIsModalOpen(true);
    }

    if (isLoading) {
        return (<img src={AppImages.loading} alt="loading" className="w-[30px] mx-auto" />)
    }
    if (isApiError) {
        return (
            <div className="bg-red-200 mx-4 flex justify-center p-4 mt-12 font-medium text-[20px]">Error in Fetching Data</div>
        )
    }
    return (
        <div className="min-h-screen flex flex-col mx-6 items-center">
            <div>
                {/* <div style={{objectFit:'cover'}}><img src={apiData?.image_url?.content} alt="" className="mx-auto" style={{ maxHeight: '200px', maxWidth:'100vw', objectFit:'cover' }} /></div> */}
                <div style={{ width: '100vw', maxHeight: '40vh', objectFit: 'contain' }}>
                    <img
                        src={apiData?.image_url?.content}
                        alt=""
                        className="mx-auto"
                        style={{ width: '100%', height: '30vh', objectFit: 'cover' }}
                    />
                </div>
                <p className="text-center pt-0 text-black font-bold mx-4 my-8 text-[25px]">{apiData?.user_name} wants to redeem {apiData?.discount_percentage} discount on "{apiData?.query_title}"</p>
                {isAmountField ? (
                    <div className="mt-12 text-[20px]">
                        {/* <form onSubmit={handleAmount}> */}
                            <div className="mb-3 text-start px-3">
                                <label htmlFor="name" className="form-label text-black font-medium text-left">
                                    Enter Amount for Discount
                                </label>
                                <br />
                                <input
                                    type="number"
                                    id="amount"
                                    name="amount"
                                    value={formFields.amount}
                                    required
                                    onChange={handleChange}
                                    className={`form-control border-2 w-full rounded-md p-2 ${errors.amount ? 'is-invalid' : ''}`}
                                    placeholder="Amount"
                                />
                                {errors.amount && <div style={{
                                    color: "#f00",
                                    fontSize: "13px"
                                }}>{errors.amount}</div>}
                            </div>
                            {/* {amountLoading ? (<div className="flex justify-end mr-8"><img src={AppImages.loading} alt="loading" className="w-[30px]" /></div>) : (<div className="flex justify-end mr-4">
                                <Button className="" variant="contained" onClick={handleAmount}>Submit</Button>
                            </div>)} */}
                        {/* </form> */}
                    </div>
                ) : (null)}
                <div className="flex flex-row gap-5 mt-4 justify-center">
                    {loading ? (<img src={AppImages.loading} alt="loading" className="w-[30px]" />) : (<div>
                        {isAcceptable ? (<Button className="px-4 py-2 mb-6" varient="contained"
                            style={{
                                paddingRight: '20px',
                                paddingLeft: '20px',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                backgroundColor: 'green',
                                color: 'white',
                                marginBottom: '5px'
                            }}
                            onClick={handleClick}
                        >
                            Accept
                        </Button>) : (null)}

                    </div>)}
                    {ifLoading ? (<img src={AppImages.loading} alt="loading" className="w-[30px]" />) : (<div>
                        {isRejectable ? (<Button className="px-4 py-2" varient="contained"
                            style={{
                                paddingRight: '20px',
                                paddingLeft: '20px',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                backgroundColor: 'red',
                                color: 'white'
                            }}
                            onClick={handleReject}
                        >
                            Reject
                        </Button>) : (null)}
                    </div>)}
                </div>
                {isStatus ? (<div className="text-center text-black text-[20px] mx-4 font-medium p-5 bg-red-200">{status}</div>) : (null)}
                {/* {isAmountField ? (
                    <div className="mt-12 text-[20px]">
                        <form onSubmit={handleAmount}>
                            <div className="mb-3 text-start px-3">
                                <label htmlFor="name" className="form-label text-black font-medium text-left">
                                    Enter Amount for Discount
                                </label>
                                <br />
                                <input
                                    type="number"
                                    id="amount"
                                    name="amount"
                                    value={formFields.amount}
                                    required
                                    onChange={handleChange}
                                    className={`form-control border-2 w-full rounded-md p-2 ${errors.amount ? 'is-invalid' : ''}`}
                                    placeholder="Amount"
                                />
                                {errors.amount && <div style={{
                                    color: "#f00",
                                    fontSize: "13px"
                                }}>{errors.amount}</div>}
                            </div>
                            {amountLoading ? (<div className="flex justify-end mr-8"><img src={AppImages.loading} alt="loading" className="w-[30px]" /></div>) : (<div className="flex justify-end mr-4">
                                <Button className="" variant="contained" onClick={handleAmount}>Submit</Button>
                            </div>)}

                        </form>
                    </div>
                ) : (null)} */}
            </div>
            <Modal
                isOpen={isModalOpen}
                style={modalStyle}
                onRequestClose={() => setIsModalOpen(false)}
            >
                {isError ? (<div className="min-h-screen flex flex-col my-4 items-center">
                    <div class="px-6 py-2 text-center ">
                        <div class="font-bold text-[20px] mb-2 text-[red]">Error</div>
                        <p className="font-medium">{isError}</p>
                    </div>
                    <div className="ml-auto"><Button varient="contained" onClick={closeModal}>Close</Button></div>
                </div>) : (<div className="min-h-screen flex flex-col my-4 items-center">
                    <div class="px-6 py-2 text-center ">
                        <div class="font-bold text-[20px] mb-2 text-[green]">Notification</div>
                        <p className="font-medium">You have accepted the redeem request of the customer.</p>
                    </div>
                    <div className="ml-auto"><Button varient="contained" onClick={closeModal}>Close</Button></div>
                </div>)}
            </Modal>
            <Modal
                isOpen={isSecondModalOpen}
                style={modalStyle}
                onRequestClose={() => setIsModalOpen(false)}
            >
                {ifError ? (<div className="min-h-screen flex flex-col my-4 items-center">
                    <div class="px-6 py-2 text-center ">
                        <div class="font-bold text-[20px] mb-2 text-[red]">Error</div>
                        <p className="font-medium">{ifError}</p>
                    </div>
                    <div className="ml-auto"><Button className="bg-blue" varient="contained" onClick={closeSecondModal}>Close</Button></div>
                </div>) : (<div className="min-h-screen flex flex-col my-4 items-center">
                    <div class="px-6 py-2 text-center ">
                        <div class="font-bold text-[20px] mb-2 text-[red]">Notification</div>
                        <p className="font-medium">You have Rejected the redeem request of the customer.</p>
                    </div>
                    <div className="ml-auto"><Button className="bg-blue" varient="contained" onClick={closeSecondModal}>Close</Button></div>
                </div>)}
            </Modal>
            <ToastContainer />
        </div>
    )
}
export default SingleDiscountScreen