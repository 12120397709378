import { useNavigate } from "react-router-dom";
import { formatNumber } from "../../../../../utils/helpers.js";

function ProductCard({ product }) {
  const navigate = useNavigate()

  let price = <div><b>{product.price?.currency} {formatNumber( product.price?.amount )}</b></div>

  if(product.discounted_price) {
    price = <div><s>{product.price?.currency} {formatNumber( product.price?.amount )}</s><b className="pl-2 text-[17px]">{product.discounted_price?.currency} {formatNumber( product.discounted_price?.amount )}</b></div>
  }
  
  return (
    <div
      className="h-[230px] flex flex-col mx-5"
      onClick={(e) => {
        navigate("/app/single-business/product", {
          state: {
            item: product,
          },
        });
      }}
    >
      <div className="flex items-center justify-center rounded-lg">
        <div className="w-full h-[160px] bg-slate-100 rounded-lg">
          {product.images.length > 0 ? (
            <img
              src={product.images[0]}
              alt=""
              className="h-full w-full mx-auto rounded-lg object-cover shadow-md" // Use mx-auto to center horizontally
            />
          ) : (
            <div className="bg-slate-100 text-[22px] rounded-lg font-semibold w-full h-full flex items-center justify-center">
              {product?.name.slice(0, 2)}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col w-full h-[60px] mt-2">
        <div className="flex flex-row justify-between">
          <div className="text-[14px]">{product.name}</div>
          <div className="text-[13px]">
            {/* {
              !product?.discounted_price?.amount ? (product?.price?.amount
              ? `Rs. ${formatNumber(product.price.amount)}`
              : "") : (product?.discounted_price?.amount
              ? `Rs. ${formatNumber(product.discounted_price.amount)}`
              : "")
            } */}
{price}

            
          </div>
        </div>
        <div className="">
          {product.availability === "1" ? (
            <div className="text-[12px] opacity-60">Available</div>
          ) : (
            <div className="text-[12px] opacity-60">Not Available</div>
          )}
        </div>
        {/* <div className="flex flex-row justify-between w-full"> */}

        {/* </div> */}
        {/* <div className="border-b-[1px] border-slate-400 my-1"></div> */}
      </div>
      {/* <div className="w-[5%] text-center text-[16px] my-2">
        <FaArrowRight />
      </div> */}
    </div>
  );
}

export default ProductCard;