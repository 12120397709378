import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppImages } from "../../../../Asset/images/image.js";
import ReactModal from "react-modal";
import {
  LAMBDA_API_BUSINESS_PORTAL_URL,
  MyBusinessContext,
} from "../contexts/MyBusinessContext.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import AppToastContainer from "../../../basic/AppToast.jsx";
import FlutterInterface from "../../../../utils/flutter_interface.js";
import { BiImageAdd } from "react-icons/bi";
import firebase_service1 from "../../../../utils/firebase_service1.js";
import BackArrow from "../BackArrow.jsx";

function AddMenu() {
  const ctx = useContext(MyBusinessContext);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const fields = {
    title:null,
    description: null,
    tags:null,
  };
  
   
   const [selectedImage, setSelectedImage] = useState(null);

  

  const fileInputRef = useRef();

  const [formFields, setFormFields] = useState(fields);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState(null);
  const [menuId, setMenuId] = useState(null);
  
  const [next1] = useState(0);
  const [selectables, setSelectables] = useState([])
  const [productids, setProductIds] = useState([])
 
  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        // const compressedImage = await imageCompression(file, { maxsize: 1 }); // Adjust maxsize as needed
        setSelectedImage(file);
        setImageFiles([file])
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleTagsChange = (e) => {
    const inputValue = e.target.value;

    // Split the input string into an array based on spaces
    const tagsArray = inputValue.split(' ');

    setFormFields({
      ...formFields,
      tags: tagsArray,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setFieldErrors(null);
    console.log('Dataaaa',formFields);
    setIsLoading(true);

    const errors = {};
    if (!formFields.title) {
      errors.title = "Please enter title";
    }
    if (!formFields.description) {
      errors.description = "Please enter description";
    }
   

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      setIsLoading(false);
      return;
    }

    let image_urls = [];
    console.log("ImageFiles",imageFiles)
    if (imageFiles?.length) {
      image_urls = await firebase_service1.uploadManyFilesOnFirebase({
        files: imageFiles.map((f) => f),
        foldername: "business-portal-images",
      });
    }
    console.log("imagesss", image_urls);

    let business_id = ctx.env?.business_id;
    console.log("BusinesssId",business_id)
    if (!business_id) {
      business_id = await FlutterInterface.getUserId();
      if (business_id) {
        ctx.setEnv({ ...ctx.env, business_id: business_id });
      }
    }

    axios.post(LAMBDA_API_BUSINESS_PORTAL_URL + "/app/my-business/products/menu/create", {
        business_id: business_id,
        title: formFields.title,
        description: formFields.description,
        tags: formFields.tags,
        image_url: image_urls?.length ? image_urls[0] : null,
      })
      .then(async (res) => {
        console.log('res',res.data, res.data.data.menu._id);
        setMenuId(res.data.data.menu._id)
        if (res.data?.status !== 200) {
          throw res.data;
        } else {
         
          // setShowSuccessModal(true);
          setShow(true)
          // navigate("/app/my-business/stats");
        }
      })
      .catch((e) => {
        console.log(e);
        if (e?.error) {
          toast.error(
            e?.isOperationalError ? "Something went wrong" : e?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  
  const [data ,setData] = useState([])
  
  const [show , setShow] = useState(false)
  
useEffect(() => {
  async function fetchData() {
    try {
      let business_id = ctx.business?.id || ctx.env?.business_id;

      if (!business_id) {
        throw new Error("No business id");
      }

     //  const response = await axios.get(
     //    LAMBDA_API_BUSINESS_PORTAL_URL +
     //      "/app/my-business/products/get?business_id=" +
     //      business_id
     //  );
     const response = await axios.get(
       `${LAMBDA_API_BUSINESS_PORTAL_URL}/app/my-business/products/get?business_id=${business_id}&fetch_all=1`
     );


      console.log("Dataaa", response);
      const newData = response.data.data.products; // Use response.data directly

      if (newData.length > 0) {
        // Append the new data to the existing data
        setData([...data, ...newData]);
        setSelectables([...data, ...newData])
        // setCurrentPage(currentPage + 1);
      } else {
        // No more data available
        // setHasMore(false);
      }
     //  setData(response.data.data.products);

      if (response.data?.status !== 200) {
        throw response.data;
      } else {
        // Your other logic here
      }

      // Set isFetchingStats to false when the response is received
      // setIsFetchingStats(false);
    } catch (error) {
      console.error(error);

      if (error?.error) {
        toast.error(
          error?.isOperationalError ? "Something went wrong" : error?.message,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }

      // Set isFetchingStats to false when there is an error
      // setIsFetchingStats(false);
    }
  }
  fetchData(); // Call the async function inside useEffect
}, [ctx.business?.id, ctx.env?.business_id, data]);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelect = (e) => {
    console.log(e.target.value)
    let item = selectables.find(item => item._id === e.target.value)
    // Check if the item is already selected
    console.log("Itemmmmmmmmmmmmmm",item)
    if (item) {
      setSelectables(selectables.map(j => {
        if(j._id === item._id) {
          j.disabled = true 
        }
        return j  
      }))
      const newItems = [...selectedItems, item]
      setSelectedItems(newItems);
      setProductIds(newItems?.map(k => k._id))
    }
  };
  console.log("SelectedItemsss",selectedItems)
  const handleRemove = (itemId) => {
    // Remove the selected item with the given itemId
    const updatedItems = selectedItems.filter(item => item._id !== itemId);
    setSelectedItems(updatedItems);
  };
 const onSubmit1 = async (e) => {
    // e.preventDefault();
    // setFieldErrors(null);
    console.log('MenuId',menuId);
    setIsLoading(true);

    axios.post(LAMBDA_API_BUSINESS_PORTAL_URL + "/app/my-business/products/menu/add-items", {
        menu_id: menuId,
        product_ids: productids,
  
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data?.status !== 200) {
          throw res.data;
        } else {
         
          setShowSuccessModal(true);
          // setShow(true)
          // navigate("/app/my-business/stats");
        }
      })
      .catch((e) => {
        console.log(e);
        if (e?.error) {
          toast.error(
            e?.isOperationalError ? "Something went wrong" : e?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <style>
          {`
          .PhoneInputInput {
            background-color: rgb(243 244 246 / var(--tw-bg-opacity));
            padding: 0.75rem;
            outline-color: #2196F3;
          }
          `}
        </style>
      </Helmet>
      <AppToastContainer />
      <div>
        <BackArrow/>
      </div>
      <div className="flex flex-col mt-[10px] items-center w-[full]">
        <div className="flex items-center justify-center text-[#2B2B2B] text-[19px] font-semibold">
          Add Menu
        </div>
        <div className="border-[1px] w-full border-[#E8E8E9] my-3"></div>
        {
          show === false ?  (  
            <div className="w-full">
              <form
                onSubmit={onSubmit}
                className="text-[#2B2B2B]  mt-[10px] text-[20px] w-[80%] md:w-[60%] mx-auto"
              >
                <div className={next1 === 0 ? "" : "hidden"}>
                  <div className="">
                    <div className=" ">
                        {/* <MBUploadPhotos2
                          onChange={(files) => {
                            if (files?.length > 0) {
                              setImageFiles(files);
                              // compressImages(files);
                            }
                          }}
                        /> */}
                  <div
              style={{
                height: "130px",
                position: "relative",
              }}
              className="w-full"
            >
              {selectedImage ? (
                <div style={{ position: "relative" }}>
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                    className="w-full h-[130px] object-contain"
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                    onClick={handleRemoveImage}
                  >
                    <span role="img" aria-label="Remove Image" style={{ fontSize: "20px" }}>
                      ❌
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  className="bg-[#E8E8E9] flex-col"
                  onClick={handleDivClick}
                >
                  <div>
                    <BiImageAdd className="mx-auto" size={"20px"} />
                  </div>
                  <div>
                    <span className="text-[13px]">Upload a cover photo (optional)</span>
                  </div>
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
                ref={fileInputRef}
              />
            </div>
      
      
                  
                      <TextField
                        label={"Menu Title"}
                        placeholder="Main Dish"
                        value={formFields?.title ?? ""}
                        error={fieldErrors?.title}
                        onChange={(e) =>
                          setFormFields({ ...formFields, title: e.target.value })
                        }
                      />
                      <TextField
                        label={"Description"}
                        placeholder="e.g traditional"
                        value={formFields?.description ?? ""}
                        error={fieldErrors?.description}
                        onChange={(e) =>
                          setFormFields({
                            ...formFields,
                            description: e.target.value,
                          })
                        }
                      />
      
                     <TextField
                      label={"Add tags"}
                      placeholder="e.g Desi, dinner"
                      error={fieldErrors?.tags}
                      onChange={handleTagsChange}
                    />
      
      
                      {/* <div className="mt-4">
                        <TextFieldLabel label="Upload Images" />
                        {fieldErrors?.images ? (
                          <TextFieldError text={fieldErrors?.images} />
                        ) : (
                          <></>
                        )}
      
                      </div> */}
                    </div>
                  </div>
                  <div className="">
                     <button
                      className="rounded-md py-2 mb-5 text-[15px] mt-6 w-full bg-[#009bfb] hover:bg-lightBlue-600 text-white disabled:bg-slate-200"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <img src={AppImages.loading} className="w-[20px] mx-auto" alt="" />
                      ) : (
                        <span>Continue</span>
                      )}
                    </button>
                  </div>
                </div>
      
              </form>
              </div>) : (   
          <div className="w-full px-3">
          
          {
            selectables.length > 0 ? (
              <div className="w-full"> 
              <div className="text-[13px] text-[#181D27]">Add items</div>
         
      
            <select onChange={handleSelect} style={{ border: '1px solid #E8E8E9' }} className="w-full h-[40px] text-[#95979C] mt-2">
                              <option value="">Add items from your Item list</option>
                              {selectables.map((item, index) => (
                                <option key={item?._id} value={item?._id} disabled={!item.disabled ? false : true}>
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                            {
                            selectedItems.length > 0 ? (   <div className="">
                     <button
                      className="rounded-md py-2 mb-5 text-[15px] mt-6 w-full bg-[#009bfb] hover:bg-lightBlue-600 text-white disabled:bg-slate-200"
                      onClick={onSubmit1}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <img src={AppImages.loading} className="w-[20px] mx-auto" alt="" />
                      ) : (
                        <span>Continue</span>
                      )}
                    </button>
                  </div>) : (
                    <div></div>
                  )
                            }
    
                            <ul>
              {selectedItems.map(item => (
                <li key={item?._id}>
                   <div className="flex flex-row justify-between border-b-[1px] border-[#E8E8E9] my-2">
                <div className="text-[18px] text-[#464A52]">{item?.name}</div>
                <button onClick={() => handleRemove(item?._id)} className="text-red-500 text-[13px]">Remove</button>
                </div>
              </li>
              ))}
            </ul>
            </div>
            ) : (
              <div className="text-center">
        <img src={AppImages.itemImage} alt="" className="" />
        <div className="text-[#464A52] text-[16px] mt-[28px]">
          <div>Items are products. Add a title and price for users to order, or use them to build a menu.</div>
          <div className="mt-[1px]">to order, or use them to build a menu.</div>
        </div>
          <Link to="/app/my-business/product">
                           <button
            className="bg-[#2196F3] rounded-md p-2 mb-2 text-white font-normal text-[16px] w-full mt-[37px]"
          >
            Add a new item
          </button>
                          </Link>
    
      </div>
            ) 
          }
       

        </div>)
        }
      
     
        
        {showSuccessModal && (
          <ReactModal
            isOpen={true}
            className="Modal Success-Modal"
            overlayClassName="Modal-Overlay"
          >
            <div className="p-4">
              <div className="flex items-center justify-center">
                <img
                  src={AppImages.checkmark}
                  alt="checkmark"
                  className="w-[60px] svg-green"
                />
              </div>
              <p className="text-center my-4 text-green-600 font-medium">
                Your Item has been added successfully!
              </p>
              <div
                className="text-center p-2 cursor-pointer"
                onClick={(e) => {
                  navigate("/app/my-business/stats");
                  // window.location.reload();
                }}
              >
                <p className=" font-bold text-[14px] text-green-600 border-dotted border-b-[1px] inline">
                  Click to view
                </p>
              </div>
            </div>
          </ReactModal>
        )}
      </div>
    </>
  );
}

function TextFieldLabel({ label, htmlFor }) {
  return (
<label htmlFor={htmlFor} className="text-[13px] my-1 text-[#181D27] font-semibold">
      {label}
    </label>
  );
}

function TextField({ label, id, placeholder = "", onChange, error = null }) {
  return (
    <div className="mt-[16px]">
      <TextFieldLabel label={label} htmlFor={id} />
      <input
        id={id}
        type="text"
        placeholder={placeholder ?? label}
        className=" px-3 py-3 border-[#E8E8E9] h-[38px] border-[1px] rounded-md focus:outline-[#2196F3] w-full text-[15px] text-[#95979C];"
        onChange={onChange}
      />
      {error ? <TextFieldError text={error} /> : <></>}
    </div>
  );
}

function TextFieldError({ text }) {
  return <div className="text-red-500 text-[13px] my-1">{text}</div>;
}

export default AddMenu;
