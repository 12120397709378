import { AppImages } from "../../../../Asset/images/image.js";

function HKHeader() {
  return (  
    <>
    <div className="bg-[#D60E1B]">
      <div className='flex items-center'>
        <div>
          <img src={AppImages.hq} alt="haqooq-e-khalq" srcset="" className="w-[100px] mx-auto my-2 rounded-sm" />
        </div>
        <div className="text-left">
          <p className='text-white text-[18px]'>Haqooq-e-Khalq Party</p>
          <p className="text-[12px] text-white font-light">Rights. Representation. Redistribution </p>
        </div>
      </div>
      <div className='my-2 relative h-0 pb-[56%]'>
        <iframe className='w-full h-full absolute top-0 left-0' width="560" height="315" src="https://www.youtube.com/embed/csN9V5S-B5s?si=leS3i3QyQPnb363e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <div className="text-center text-white">
        <p dir="rtl">خدا نے آج تک اس قوم کی حالت نہیں بدلی</p>
        <p dir="rtl">نہ ہو جس کو خیال آپ اپنی حالت کے بدلنے کا</p>
      </div>
    </div>
    </>
  );
}

export default HKHeader;