import React from 'react'
import { TiTick } from "react-icons/ti";
import { Link } from 'react-router-dom';
const GoLive = () => {
  return (
     <div className="flex flex-col items-center justify-center text-[#464A52] mx-2">
      {/* <div></div> */}
      
        <div className='font-bold text-[19px] mt-[50px]'>GoLive</div>
        <div className='border-[1px] border-[#E8E8E9] w-full my-5'></div>
     <div className='text-[13px]'>Initiate your store's live presence, making it accessible </div>
     <div className='text-[13px]'> to users and kickstart your business earnings.</div>
     <div className='my-4 font-semibold text-[19px]'>
        Choose your plan
     </div>
     
     <div className='p-3 border-[1px] border-[#E8E8E9] w-full rounded-xl'>
        <div className='flex flex-row justify-between text-[16px]'>
            <div>
             Popular Plan
            </div>
            <div>
             01
            </div>
        </div>
        <div className='text-[23px] mt-3'>Rs. 1900/month</div>
        <div className='flex flex-row items-center mt-3'>
            <div><TiTick className='text-[#2196F3] text-[24px]'/></div>
            <div className='text-[13px] ml-2 mt-1'>456 Leads per month</div>
        </div>
        <div className='flex flex-row items-center mt-3'>
            <div><TiTick className='text-[#2196F3] text-[24px]'/></div>
            <div className='text-[13px] ml-2 mt-1'>7 featured posts / week</div>
        </div>
        <div className='flex flex-row items-center mt-3'>
            <div><TiTick className='text-[#2196F3] text-[24px]'/></div>
            <div className='text-[13px] ml-2 mt-1'>Unique selling points of this offer</div>
        </div>
        <div className='flex justify-center'>
           <Link
                      to={{
                    pathname: `/app/subscription`,
                         }}
                  // state={{ item: item }}
                       >
          <button
            className="bg-[#2196F3] rounded-md p-2 mb-2 text-white font-normal text-[16px] w-[193px] mt-[37px]"
          >
            Select
          </button>
          </Link>
        </div>
      
     </div>
     </div>
     
  )
}

export default GoLive