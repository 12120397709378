import { useRef, useState } from "react";
import { AppImages } from "../../../../Asset/images/image.js";
import { BiImageAdd } from "react-icons/bi";
import AppToastContainer from "../../../basic/AppToast.jsx";
import { toast } from "react-toastify";
import ModalImage from "react-modal-image";

function MBUploadReceipt({ onChange }) {
  //  console.log("Onchangeee",onChange)
  const ref = useRef(null);
  const [files, setFiles] = useState([]);

  function handleClickOnDeleteBtn(file) {
    setFiles(files.filter((item) => item !== file));
  }

  function handleOnChange(e) {
    let newFiles = [];
    if (e.target.files) {
      const allowedImageTypes = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/svg",
      ];
      for (let file of Array.from(e.target.files)) {
        if (!allowedImageTypes.includes(file.type)) {
          toast.error(
            "Invalid image format. Allowed formats: jpg,jpeg,png,svg"
          );
          return;
        }
        if (file.size > 10e6) {
          // 10mb
          toast.error("Image size should not be larger than 10mb");
          return;
        }
      }

      newFiles = Array.from(e.target.files).map((file) => ({
        file: file,
        status: "uploaded",
      }));
      setFiles([...files, ...newFiles]);
    }
    if (typeof onChange === "function") {
      onChange(newFiles);
    }
  }
  return (
    <div className="mr-2 my-1">
      {
        // isUploading ? <img src={AppImages.loading} alt="loading" className="w-[25px] h-[25px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
        // :
        // <AppToastContainer/>
        <div className="my-4">
          <div className="flex items-start overflow-x-auto">
            <div
              className="w-[100px] h-[100px] mr-2 border-[1px] rounded border-black relative  cursor-pointer"
              onClick={(e) => ref?.current.click()}
            >
              <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[10px]  text-center">
                <BiImageAdd className="mx-auto" size={"20px"} />
                <span>Upload</span>
              </div>
            </div>

            <div className="relative mr-2 ">
              {files && (
                <div className="absolute w-[100px] h-[100px]">
                  <div className="flex items-center justify-start ">
                    {Array.from(files).map((file, index) => (
                      <div
                        key={index}
                        className="relative w-[100px] h-[100px] mx-2"
                      >
                        <div
                          className="relative w-[100px] h-[100px] cursor-pointer"
                          title="View image"
                        >
                          <ModalImage
                            small={URL.createObjectURL(file.file)}
                            large={URL.createObjectURL(file.file)}
                            alt="Image"
                            className="w-[100px] h-[100px] mr-2"
                          />
                          {/* <img
                            src={URL.createObjectURL(file.file)}
                            className="w-[100px] h-[100px] mr-2"
                          /> */}
                        </div>
                        {file.status === "uploading" && (
                          <div className="absolute top-0 w-[100px] h-[100px] bg-[#000000aa] opacity-[0.9]">
                            <img
                              src={AppImages.loading}
                              alt="loading"
                              className="w-[25px] h-[25px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] svg-white"
                            />
                          </div>
                        )}
                        {file.status !== "uploading" && (
                          <div
                            className="absolute top-[0px] right-[0px] bg-white border-[1px] rounded-full cursor-pointer"
                            title="Delete"
                            onClick={(e) => handleClickOnDeleteBtn(file)}
                          >
                            <img
                              src={AppImages.close}
                              alt="delete"
                              className="w-[15px] h-[15px]"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <input
            type="file"
            name="category_ads_images"
            id="category_ads_images"
            multiple
            accept="image/png,image/jpg,image/jpeg,image/gif"
            className="hidden"
            ref={ref}
            onChange={handleOnChange}
          />
        </div>
      }
      <AppToastContainer />
    </div>
  );
}

export default MBUploadReceipt;
