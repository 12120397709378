// import React, { useState } from 'react';
// // import { db } from '../firebase';
// import firebase_service from '../../../../utils/firebase_service';
// import { collection, addDoc } from 'firebase/firestore';
// import MBUploadPhotos1 from "./MBUploadPhotos1.jsx";
// import { toast, ToastContainer } from 'react-toastify';
// import { AppImages } from '../../../../Asset/images/image.js';
// import { useNavigate } from 'react-router-dom';
// const AuthenticationForm = ({ initialName = "Your Name", initialLogo = "default-logo.png" }) => {
//     const [formFields, setFormFields] = useState({ code: '', name: initialName, logo: initialLogo });
//     const [message, setMessage] = useState("");
//     const [imageFiles, setImageFiles] = useState();
//     const [fbImage, setFbImage] = useState(null);
//     const [fieldErrors, setFieldErrors] = useState(null);
//     const navigate = useNavigate();
//     const getCurrentTimeCode = () => {
//         const now = new Date();
//         const day = now.getDate().toString().padStart(2, '0');
//         const hours = now.getHours().toString().padStart(2, '0');
//         const minutes = now.getMinutes().toString().padStart(2, '0');
//         return `${day}${hours}${minutes}`;
//     };

//     const handleChange = (e) => {
//         setFormFields({ ...formFields, [e.target.name]: e.target.value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const currentTimeCode = getCurrentTimeCode();
//         console.log("currnt time", currentTimeCode)
//         const errors = {};
//         if (!formFields.code) {
//             errors.code = "Please enter code";
//         }
//         let image_urls = [];
//         console.log(imageFiles)
//         if (imageFiles?.length) {
//             image_urls = await firebase_service.uploadManyFilesOnFirebase({
//                 files: imageFiles.map((f) => f),
//                 foldername: "business-portal-images",
//             });
//         }
//         setFbImage(image_urls)
//         console.log(fbImage)

//         if (formFields.code === currentTimeCode) {
//             setMessage("Success: You have successfully completed your purchase.");
//             toast.success("Success: You have successfully completed your purchase.")
//             await saveAttempt(formFields.code, true);
//         } else {
//             setMessage("Failure: Your Purchase was not completed!.");
//             toast.error("Failure: Your Purchase was not completed!.")
//             await saveAttempt(formFields.code, false);
//         }
//     };

//     //   const saveAttempt = async (code, success) => {
//     //     try {
//     //       await addDoc(collection(db, 'attempts'), {
//     //         code,
//     //         success,
//     //         timestamp: new Date()
//     //       });
//     //     } catch (e) {
//     //       console.error("Error adding document: ", e);
//     //     }
//     //   };

//     const saveAttempt = async (code, success) => {
//         console.log("ok", fbImage, code, success)
//     };
// const handleClick = () =>{
//     navigate('/hwm-logo')
// }
//     return (
//         <div className="flex flex-col items-center  min-h-screen">
//             <div className="bg-white p-8 py-12  w-full max-w-md">
//                 <form onSubmit={handleSubmit} className="space-y-4">
//                     <div className="flex flex-col items-center">
                        
//                         <img src={AppImages.hwmLogo} alt="" style={{height:'200px'}}
//                         // onClick={handleClick}
//                         />
//                         {/* <input
//                             type="text"
//                             name="name"
//                             // value={formFields.name} 
//                             onChange={handleChange}
//                             placeholder="Enter name"
//                             className="text-2xl font-bold mb-6 text-center w-full p-2 border border-gray-300 rounded"
//                         /> */}
//                         {/* <div className="mt-4">
//                             <TextFieldLabel label="Upload Logo" />
//                             {fieldErrors?.images ? (
//                                 <TextFieldError text={fieldErrors?.images} />
//                             ) : (
//                                 <></>
//                             )}

//                             <MBUploadPhotos1
//                                 onChange={(files) => {
//                                     if (files?.length > 0) {
//                                         setImageFiles(files);
//                                         // compressImages(files);
//                                     }
//                                 }}
//                             />
//                         </div> */}

//                     </div>
//                     <input
//                         type="text"
//                         name="code"
//                         value={formFields.code}
//                         onChange={handleChange}
//                         maxLength="6"
//                         required
//                         placeholder="Enter promo code"
//                         className="w-full p-2 border border-gray-300 rounded"
//                     />
//                     <button type="submit" className="w-full py-2 px-4 bg-blue-500 text-white rounded bg-green-500">
//                         Submit
//                     </button>
//                 </form>
//                 {/* {message && <p className="mt-4 text-center">{message}</p>} */}
//             </div>
//             <ToastContainer />
//         </div>
//     );
// };
// function TextFieldLabel({ label, htmlFor }) {
//     return (
//         <label htmlFor={htmlFor} className="text-[13px] my-1">
//             {label}
//         </label>
//     );
// }
// function TextField({ label, id, placeholder = "", onChange, error = null }) {
//     return (
//         <div className="mt-4">
//             <TextFieldLabel label={label} htmlFor={id} />
//             <input
//                 id={id}
//                 type="text"
//                 placeholder={placeholder ?? label}
//                 className="bg-gray-100 px-3 py-3 border-[#5e5954] rounded-md focus:outline-[#2196F3] w-full text-[13px]"
//                 onChange={onChange}
//             />
//             {error ? <TextFieldError text={error} /> : <></>}
//         </div>
//     );
// }

// function TextFieldError({ text }) {
//     return <div className="text-red-500 text-[13px] my-1">{text}</div>;
// }
// export default AuthenticationForm;

import React, { useState, useEffect } from 'react';
import firebase_service from '../../../../utils/firebase_service';
import { toast, ToastContainer } from 'react-toastify';
import { AppImages } from '../../../../Asset/images/image.js';
import { useNavigate } from 'react-router-dom';

const AuthenticationForm = ({ initialName = "Your Name", initialLogo = "default-logo.png" }) => {
    const [formFields, setFormFields] = useState({ code: '', name: initialName, logo: initialLogo });
    const [message, setMessage] = useState("");
    const [imageFiles, setImageFiles] = useState([]);
    const [fbImage, setFbImage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedTimestamp = parseInt(localStorage.getItem('promoCodeTimestamp'), 10);
        if (storedTimestamp) {
            const currentTime = new Date().getTime();
            const timeElapsed = currentTime - storedTimestamp;
            if (timeElapsed > 5 * 60 * 1000) {
                localStorage.removeItem('promoCode');
                localStorage.removeItem('promoCodeTimestamp');
            } else {
                const timeRemaining = 5 * 60 * 1000 - timeElapsed;
                setTimeout(() => {
                    localStorage.removeItem('promoCode');
                    localStorage.removeItem('promoCodeTimestamp');
                }, timeRemaining);
            }
        }
    }, []);

    const generateCodeAndTimestamp = () => {
        const now = new Date();
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const code = `${day}${hours}${minutes}`;
        const timestamp = now.getTime();
        localStorage.setItem('promoCode', code);
        localStorage.setItem('promoCodeTimestamp', timestamp);
        setTimeout(() => {
            localStorage.removeItem('promoCode');
            localStorage.removeItem('promoCodeTimestamp');
        }, 5 * 60 * 1000);
    };

    const handleChange = (e) => {
        setFormFields({ ...formFields, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let storedCode = localStorage.getItem('promoCode');
        let storedTimestamp = parseInt(localStorage.getItem('promoCodeTimestamp'), 10);
        const currentTime = new Date().getTime();

        if (!storedCode || !storedTimestamp || (currentTime - storedTimestamp > 5 * 60 * 1000)) {
            generateCodeAndTimestamp();
            storedCode = localStorage.getItem('promoCode');
            storedTimestamp = parseInt(localStorage.getItem('promoCodeTimestamp'), 10);
        }

        const errors = {};
        if (!formFields.code) {
            errors.code = "Please enter code";
        }

        let image_urls = [];
        if (imageFiles.length > 0) {
            image_urls = await firebase_service.uploadManyFilesOnFirebase({
                files: imageFiles.map((f) => f),
                foldername: "business-portal-images",
            });
        }
        setFbImage(image_urls);

        if (storedCode && storedTimestamp && formFields.code === storedCode && (currentTime - storedTimestamp <= 5 * 60 * 1000)) {
            setMessage("Success: You have successfully completed your purchase.");
            toast.success("Success: You have successfully completed your purchase.");
            await saveAttempt(formFields.code, true);
        } else {
            setMessage("Failure: Your Purchase was not completed!.");
            toast.error("Failure: Your Purchase was not completed!.");
            await saveAttempt(formFields.code, false);
        }
    };

    const saveAttempt = async (code, success) => {
        console.log("ok", fbImage, code, success);
    };

    const handleClick = () => {
        navigate('/hwm-logo');
    };

    return (
        <div className="flex flex-col items-center min-h-screen">
            <div className="bg-white p-8 py-12 w-full max-w-md">
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="flex flex-col items-center">
                        <img src={AppImages.hwmLogo} alt="" style={{ height: '200px' }} />
                    </div>
                    <input
                        type="text"
                        name="code"
                        value={formFields.code}
                        onChange={handleChange}
                        maxLength="6"
                        required
                        placeholder="Enter promo code"
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                    <button type="submit" className="w-full py-2 px-4 bg-blue-500 text-white rounded bg-green-500">
                        Submit
                    </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default AuthenticationForm;

