import { useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import AppToastContainer from "../../../basic/AppToast.jsx";
import ProductTabContent from "./Products/ProductTabContent.jsx";
import { SingleBusinessContext } from "./contexts/SingleBusinessContext.jsx";
import BusinessHeader from "./BusinessHeader.jsx";
import AppSkeleton from "../../../basic/AppSkeleton.jsx";
import InfiniteScroll from "react-infinite-scroll-component";
import BusinessOffers from "../MyBusiness/BusinessOffers.jsx";
import HKMainScreen from "../HaqooqeKhalaq/HKMainScreen.jsx";

function SingleBusinessScreen() {
  const ctx = useContext(SingleBusinessContext);
  const isHaqooqeKhalq = ctx.marker_id === '65e9d59a07346e96241b4c16'
  const tab_classnames = "border-0 bg-transparent cursor-pointer outline-none py-2 px-2";
  
  if(isHaqooqeKhalq) {
    return <HKMainScreen />
  }
  return (
    <>
      <AppToastContainer />
      <div className="py-4">
        <div>
          {ctx.marker?.name ? (
            <BusinessHeader
              name={ctx.marker?.name}
              phone={ctx.marker?.phone}
              address={ctx.marker?.address}
              category={ctx.marker?.type}
              serviceCode={ctx.marker?.serviceCodes}
            />
          ) : (
            <AppSkeleton count={4} />
          )}
          <div className="border-b-[1px] border-slate-200 my-4"></div>

          <div>
            <Tabs selectedTabClassName="border-b-2 border-[#24ACE3] text-black font-semibold">
              <TabList className={"flex items-center gap-6 border-b-2 mb-4"}>
                <Tab className={tab_classnames}>
                  {ctx.marker?.type === "food" ||
                  ctx.marker?.type === "fast food"
                    ? "Menu"
                    : "Products"}
                  {/* {ctx.marker?.type === "food" ? "Menu" : "Products"} */}
                </Tab>
                <Tab className={tab_classnames}>Offers</Tab>
                {/* <Tab className={tab_classnames}>Offers</Tab> */}
              </TabList>

              <TabPanel>
                <InfiniteScroll
                  dataLength={ctx.products?.length ?? 0}
                  hasMore={ctx.productsAPIOptions.hasMore}
                  loader={<AppSkeleton count={2} />}
                  next={() => ctx.populateProducts()}
                >
                  <ProductTabContent products={ctx.products} />
                </InfiniteScroll>
                {ctx.isFetchingProducts && ctx.products?.length === 0 ? (
                  <AppSkeleton count={2} />
                ) : (
                  <></>
                )}
              </TabPanel>
              <TabPanel>
                <BusinessOffers/>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleBusinessScreen;

