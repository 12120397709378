import { useContext } from "react";
import { SingleBusinessContext } from "../SingleBusiness/contexts/SingleBusinessContext.jsx";

function BawaMain() {
  const ctx = useContext(SingleBusinessContext);
  console.log(ctx.marker)
  return (  
    <div></div>
  );
}

export default BawaMain;