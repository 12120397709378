import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactModal from 'react-modal';
import "react-phone-number-input/style.css";
import GlobalContextProvider from './component/GlobalContext.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactModal.setAppElement('#root')
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <App />
    </GlobalContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
