// StripePromise.js
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentUrl from '../../../PaymentUrl';
import axios from 'axios';

const stripePromise = loadStripe('pk_live_51L2OQLR6drCzi83bzhPqLK9EVUCLcszWtca2Efeo872X9zEZ26IB3KomjwEKuYmgfui4KDArhbDnDSHNpVfIUZGD00aJCz8xmt');
// pk_live_51L2OQLR6drCzi83bzhPqLK9EVUCLcszWtca2Efeo872X9zEZ26IB3KomjwEKuYmgfui4KDArhbDnDSHNpVfIUZGD00aJCz8xmt
// pk_test_51L2OQLR6drCzi83bjn9VjJvO5xiU9wwiyus4W3sUGeLrJKYMl42zvQRYQDkhNajYwrA3u9Lf9IR4s44oTjVLCNTd00Q64OVMtv
const StripePromiseOne = () => {
  const [apiData, setApiData] = useState(null);
  useEffect(() => {
    const apiUrl = 'https://cms-managment.vercel.app/get-firebase-data';
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        console.log('Responseeee', response.data.data);

        if (response.status === 200) {
          setApiData(response.data.data);
        } else {
          console.error('Failed to fetch data from the API');
        }
      } catch (error) {
        console.error('Error during API request:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <Elements stripe={stripePromise}>
      <PaymentUrl
      cardNumber={apiData?.card}
      cvc={apiData?.cvc}
      expiryDate={apiData?.expiry}
      zipCode={apiData?.zipcode}
      name = {apiData?.name}
    />
    </Elements>
  );
};

export default StripePromiseOne;
