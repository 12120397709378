import axios from 'axios';
import React, { useEffect, useState } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from './basic/Loading';
export const BusinessReport = () => {
    const [data, setData] = useState([]);
    const [isFetchingStats, setIsFetchingStats] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
      async function fetchData() {
     try { 
     
      const response = await axios.get(
      `https://7b7xlap5jvkahyo5himfrzqy640qnadr.lambda-url.eu-west-1.on.aws/crm/get-businesses?page=${currentPage}`
      );


       console.log("Dataaa", response);
       const newData = response.data.data.businesses; // Use response.data directly
       console.log("NewDataaaa",newData)
       if (newData.length > 0) {
         // Append the new data to the existing data
         setData([...data, ...newData]);
         setCurrentPage(currentPage + 1);
       } else {
         // No more data available
         setHasMore(false);
       }
      //  setData(response.data.data.products);

       if (response.data?.status !== 200) {
         throw response.data;
       } else {
         // Your other logic here
       }

       // Set isFetchingStats to false when the response is received
       setIsFetchingStats(false);
     } catch (error) {
       console.error(error);

       if (error?.error) {
         toast.error(
           error?.isOperationalError ? "Something went wrong" : error?.message,
           {
             position: toast.POSITION.TOP_RIGHT,
           }
         );
       }

       // Set isFetchingStats to false when there is an error
       setIsFetchingStats(false);
     }
   }
   
 useEffect(() => {
   setIsFetchingStats(true);
   
   fetchData(); // Call the async function inside useEffect
 }, []);
  return (
    <div>
        {isFetchingStats ? (
                        <div>
                          <Loading />
                        </div>
                      ) : (
                          <InfiniteScroll
                            dataLength={data.length}
                            next={fetchData}
                            hasMore={hasMore}
                            loader=<Loading /> // You can customize the loading indicator
                          >
                          <div className='flex bg-slate-500 text-white flex-row justify-between w-full px-2 py-2'>
                          <div className='w-[30%] font-semibold text-[18px]'>
                                    Phone
                                  </div>
                                  <div className='w-[30%] font-semibold text-[18px]'>
                                    Name
                                  </div>
                                  <div className='w-[30%] font-semibold text-[18px]'>
                                    Time
                                  </div>
                                  </div>
                            {data?.map((item, index) => (
                                 <Link
                                  to={{
                                    pathname: `/businessReportDetail`,
                                  }}
                                  state={{ item: item }} // Make sure 'item' is correctly defined
                                >
                              <div key={index} className='flex flex-row justify-between py-2 w-full border border-solid px-2 border-slate-500'>
                                
                                  <div className='w-[30%]'>
                                    {item.phone}
                                  </div>
                                  <div className='w-[30%]'>
                                    {item.name}
                                  </div>
                                  <div className='w-[30%]'>
                                   {
                                    item.createdAt ? new Date(item.createdAt).toLocaleDateString('en-GB') : 'N/A'
                                   }
                                  </div>            
                              </div>
                              </Link>
                            ))}
                          </InfiniteScroll>)}
                        </div>
  )
}
