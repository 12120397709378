import { PiMapPin, PiPhone } from "react-icons/pi"
function MBHome() {
  return (  
    <>
      <div className="flex justify-between items-start px-6 py-4 gap-2">
        <div>
          <div>
            <p className="text-[24px] font-bold">Waldo</p>
            <p className="text-[12px] text-gray-400">705 eat 75th street KC MO Kanses city Mozuri. 64131</p>
          </div>
          <div className="flex justify-between items-center">
            <div>
              <p>2117</p>
              <p>Service Code</p>
            </div>
            <div>
              <p>1.2 KM</p>
            </div>
          </div>
        </div>
        <div>
          <div className="border-[1px] rounded-full border-black p-2">
            <PiMapPin size={25} />
          </div>
          <div className="h-2"></div>
          <div className="border-[1px] rounded-full border-black p-2">
            <PiPhone size={25} />
          </div>
        </div>
      </div>
    </>
  );
}

export default MBHome;