import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import imageCompression from 'browser-image-compression';
import { AppImages } from "../../../../Asset/images/image";
import { FiUpload } from "react-icons/fi";

function MBUploadPhotos1({ onChange }) {
  const ref = useRef(null);
  const [files, setFiles] = useState([]);

  function handleClickOnDeleteBtn(file) {
    setFiles(files.filter((item) => item !== file));
  }
  async function compressImages(inputFiles) {
    console.log('hello')
    function convertBlobToFile(blob) {
      return new File([blob], blob.name)
    }
    const compressedImages = [];

    for (let file of inputFiles) {
      try {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 2, // Set your desired maximum file size in MB
          maxWidthOrHeight: 800, // Set your desired maximum width or height
        });

        compressedImages.push(convertBlobToFile(compressedFile));
      } catch (error) {
        console.error("Error compressing image: ", error);
      }
    }

    return compressedImages;
  }

  async function handleOnChange(e) {
    if (e.target.files) {
      const allowedImageTypes = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/gif",
      ];
      const inputFiles = Array.from(e.target.files);

      for (let file of inputFiles) {
        if (!allowedImageTypes.includes(file.type)) {
          toast.error("Invalid image format. Allowed formats: jpg, jpeg, png, gif");
          return;
        }
        if (file.size > 10e6) {
          // 10mb
          toast.error("Image size should not be larger than 10mb");
          return;
        }
      }

      const compressedImages = await compressImages(inputFiles); 
      const newFiles = [...files, ...compressedImages]
      setFiles(newFiles);

      if (typeof onChange === "function") {
        onChange(newFiles);
      }
    }
  }

  return (
    <div className="mr-2 my-1">
      <div className="my-4">
        <div className="flex items-start overflow-x-auto">
          <div
            className="w-[100px] h-[60px] mr-2 border-[1px] rounded border-[#E8E8E9] relative  cursor-pointer bg-gray-50"
            onClick={(e) => ref?.current.click()}
          >
            <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[10px]  text-center">
              <FiUpload className="mx-auto text-[#2196F3] mb-2" size={"17px"} />
              <span className="text-gray-600">Upload Image</span>
            </div>
          </div>

          <div className="relative mr-2 ">
            {files && (
              <div className="absolute w-[100px] h-[60px]">
                <div className="flex items-center justify-start ">
                  {Array.from(files).map((file, index) => (
                    <div
                      key={index}
                      className="relative w-[100px] h-[60px] mx-2"
                    >
                      <div
                        className="relative w-[100px] h-[60px] cursor-pointer"
                        title="View image"
                      >
                        <img
                          src={URL.createObjectURL(file)}
                          className="w-[100px] h-[60px] mr-2"
                          alt={`${index}`}
                        />
                      </div>
                      {file.status === "uploading" && (
                        <div className="absolute top-0 w-[100px] h-[60px] bg-[#000000aa] opacity-[0.9]">
                          <img
                            src={AppImages.loading}
                            alt="loading"
                            className="w-[25px] h-[25px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] svg-white"
                          />
                        </div>
                      )}
                      {file.status !== "uploading" && (
                        <div
                          className="absolute top-[0px] right-[0px] bg-white border-[1px] rounded-full cursor-pointer"
                          title="Delete"
                          onClick={(e) => handleClickOnDeleteBtn(file)}
                        >
                          <img
                            src={AppImages.close}
                            alt="delete"
                            className="w-[15px] h-[15px]"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <input
          type="file"
          name="category_ads_images"
          id="category_ads_images"
          multiple
          accept="image/png,image/jpg,image/jpeg,image/gif"
          className="hidden"
          ref={ref}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
}

export default MBUploadPhotos1;
