import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { AppImages } from "../../../../../Asset/images/image.js";

function AddPeopleForm({ onSubmit, onClose }) {
  const [isLoading, setIsLoading] = useState(false)
  const defaultErrors = {
    name: null, 
    cnic: null,
    address: null,
    phone: null,
    _form: null 
  }
  const [errors, setErrors] = useState(defaultErrors)
  async function handleSubmit(e) {
    e.preventDefault()
    setErrors(defaultErrors)
    const fd = new FormData(e.target)
    const name = fd.get('name')
    let phone = fd.get('phone')
    const cnic = fd.get('cnic')
    const address = fd.get('address')
    const area = fd.get('area')
    const city = fd.get('city')

    const errs = {}
    if(!name) {
      errs.name = "Name is required"
    }
    if(!phone) {
      errs.phone = "Phone is required"
    }
    if(!area) {
      errs.area = 'Area is required'
    }
    if(!city) {
      errs.city = 'City is required'
    }
    if(phone && phone.trim().replace(/[^0-9]/gi, "").length < 10) {
      errs.phone = "Invalid phone number"
    }
    phone = phone.trim().replace(/[^0-9]/gi, "").slice(-10)
    if(phone.length === 10 && phone[0] === "0") {
      phone = phone.slice(1)
    }
    
    if(Object.keys(errs).length) {
      setErrors(errs)
      return;
    }
    
    if(typeof onSubmit === 'function') {
      onSubmit({ name, phone, cnic, address, area, city, setErrors, setIsLoading })
    }
  }
  return (  
    <>
      <h1 className="font-semibold my-2 text-center">Add People</h1>
      <form onSubmit={handleSubmit}>
        {errors?._form && <div className="mt-2 mb-4">
          <div className="text-red-500 bg-red-100 p-2 rounded-md border-[1px] border-red-500">
            <p><span className="font-semibold">Error:</span> {errors?._form}</p>
          </div>
        </div>}
        <div className="my-2">
          <TextField label="Name" name="name" className='w-full box-border' error={errors?.name} />
          {errors?.name && <p className="text-red-500 text-[12px]">{errors.name}</p>}
        </div>
        <div className="my-2">
          <TextField label="Phone" name="phone" className='w-full box-border' error={errors?.phone} />
          {errors?.phone && <p className="text-red-500 text-[12px]">{errors.phone}</p>}
        </div>
        <div className="my-2">
          <TextField label="CNIC" name="cnic" className='w-full box-border' error={errors?.cnic} />
          {errors?.cnic && <p className="text-red-500 text-[12px]">{errors.cnic}</p>}
        </div>
        <div className='m-2'>
          <TextField label="Area (علاقہ)" name="area" className='w-full box-border' error={errors?.area != null} />
          {errors?.area && <p className='text-red-500 text-[12px] font-semibold'>{errors.area}</p>}
        </div>
        <div className='m-2'>
          <TextField label="City" name="city" className='w-full box-border' error={errors?.city != null} />
          {errors?.city && <p className='text-red-500 text-[12px] font-semibold'>{errors.city}</p>}
        </div>
        <div className="my-2">
          <TextField label="Address" name="address" className='w-full box-border' error={errors?.address} />
          {errors?.address && <p className="text-red-500 text-[12px]">{errors.address}</p>}
        </div>
        <div className="flex justify-between">
          <Button type="button" onClick={onClose}>
            <span className="text-gray-500">Close</span>
          </Button>

          <Button type="submit" disabled={isLoading}>
            <span className={isLoading ? "" : "hidden"}><img src={AppImages.loading} className="w-[20px]" alt="" /></span>
            <span>Submit</span>
          </Button>
        </div>
      </form>
    </>
  );
}

export default AddPeopleForm;