import { useContext } from "react";
import { SingleBusinessContext } from "./contexts/SingleBusinessContext.jsx";
import HKMainScreen from "../HaqooqeKhalaq/HKMainScreen.jsx";
import SingleBusinessScreen2 from "./SingleBusinessScreen2.jsx";

function SingleBusinessMain() {
  const ctx = useContext(SingleBusinessContext);
  const redirectRules = {
    "65e9d59a07346e96241b4c16": <HKMainScreen />
  }
  if(ctx?.marker_id?.toString().length && Object.keys(redirectRules).findIndex(item => item === ctx.marker_id) !== -1) {
    return redirectRules[ctx.marker_id]
  }
  return <SingleBusinessScreen2 />;
}

export default SingleBusinessMain;