import React, { useContext } from "react";
import { SingleBusinessContext } from "../contexts/SingleBusinessContext.jsx";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { formatNumber } from "../../../../../utils/helpers.js";
import { RiDeleteBin3Line } from "react-icons/ri";

function Cart() {
  const ctx = useContext(SingleBusinessContext)
  return (
    <div>
      <h2>Shopping Cart</h2>
      {!ctx.cart.items?.length ? <div className="text-center my-6 text-gray-400">Your cart is empty</div> : <></>}
      <ul>
        {ctx.cart?.items?.map((item,index) => (
          <li key={'cart-item-' + item?.product?._id + index}>
            <div className="flex items-center">
              <div className="flex items-center flex-[0_0_80%]">
                <div className="flex items-center">
                  {item.quantity === 0 ? 
                    <button onClick={() => ctx.removeItemFromCart(item.id)} className="">
                      <RiDeleteBin3Line color="#f00" />
                    </button>
                   : <></>
                  }
                  <button 
                    className=""
                    disabled={item.quantity === 0}
                    onClick={() => {
                      if(item.quantity === 1) {
                        ctx.removeItemFromCart(item.id)
                      } else {
                        ctx.updateCartItemQuantity(item.id, item.quantity - 1)
                      }
                    }}
                  >
                    <FiMinusCircle />
                  </button>
                  {item?.quantity}
                  <button 
                    className=""
                    onClick={() => {
                      ctx.updateCartItemQuantity(item.id, item.quantity + 1)
                    }}
                  >
                    <FiPlusCircle />
                  </button>
                </div>
                <div className="mx-1">
                  <img src={item?.product?.images[0]} className="w-[40px] h-[40px] object-cover" alt="" />
                </div>
                <div className="">{item?.product?.name}</div>
              </div>
              <div className="flex-[1_0_30%]">
                {item.price?.currency} {formatNumber( item?.price?.amount )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Cart;
