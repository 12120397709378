import axios from 'axios';
import React, { useState } from 'react';
import Modal from "react-modal";
import { AppImages } from '../Asset/images/image';

const WebPaymentform = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const countries = [ 'United States', 'United Kingdom','Pakistan','United Arab Emirates','India','China','France','Canada']; // Add other countries as needed
  const [formData, setFormData] = useState({
    name: '',
    card: '',
    cvc: '',
    zipcode: '',
    amount: '',
    // currency: '',
    email:'',
    expiry: '',
    country: 'United States',
  });

  const [errors, setErrors] = useState({
    name: '',
    card: '',
    cvc: '',
    zipcode: '',
    amount: '',
    // currency: '',
    email:'',
    expiry: '',
  });
  


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };
 

const handleSubmit = async (e) => {
  e.preventDefault();

  // Check for empty fields
  const newErrors = {};
  Object.keys(formData).forEach((key) => {
    if (!formData[key]) {
      newErrors[key] = 'This field is required';
    }
  });

  if (Object.keys(newErrors).length > 0) {
    setErrors(newErrors);
    return;
  }
  console.log("Dataaa",formData)
  setModalMessage(`We are processing your payment we will let you know shortly at ${formData?.email}`);
    setShowModal(true);
  // If all fields are filled, proceed with Axios API call
  try {
    const apiUrl = 'https://cms-managment.vercel.app/submit-payment';

    const response = await axios.post(apiUrl, {
      name: formData.name,
      card: formData.card,
      cvc: formData.cvc,
      expiry: formData.expiry,
      zipcode: formData.zipcode,
      amount: formData.amount,
      email: formData.email,
      country: formData.country,
      currency: 'usd', // optional with default value
    });

    console.log('API response:', response.data);
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error accordingly
  }
};

const closeModal = () => {
  window.location.reload();
    setShowModal(false);
    setModalMessage('');
  };
  return (<div className='bg-white'>
   <div className="max-w-md mx-auto">
      <form onSubmit={handleSubmit} className='px-2 bg-white'>
        <div className='my-[20px] '>
               <img src={AppImages.leather} alt="" srcset="" />
        </div>
        <div className='my-[40px]'>
          <div className='text-[24px] font-bold'>
            Custom Order
          </div>
          <div className='text-[18px] text-slate-600'>
            Get your custom Product made, please make the payment below in order to proceed.
          </div>
        </div>
            <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder='Email address'
            value={formData.email}
            onChange={handleInputChange}
            className={`w-full p-2 border ${errors.email && 'border-red-500'}`}
          />
          {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
        </div>
          <div className="mb-4">
          <label htmlFor="amount" className="block text-gray-700 text-sm font-bold mb-2">
           Custom Amount
          </label>
          <input
            type="text"
            id="amount"
            placeholder='5.00 $'
            name="amount"
            value={formData.amount}
            onChange={handleInputChange}
            className={`w-[50%] p-2 border ${errors.amount && 'border-red-500'}`}
          />
          {errors.amount && <p className="text-red-500 text-xs mt-1">{errors.amount}</p>}
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder='Name'
            value={formData.name}
            onChange={handleInputChange}
            className={`w-full p-2 border ${errors.name && 'border-red-500'}`}
          />
          {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
        </div>
        <div className="mb-4">
          <label htmlFor="card" className="block text-gray-700 text-sm font-bold mb-2">
            Card Number
          </label>
          <input
            type="number"
            id="card"
            name="card"
            placeholder='1234 1234 1234 1234'
            value={formData.card}
            maxLength={16}
            onChange={handleInputChange}
            className={`w-full p-2 border ${errors.card && 'border-red-500'}`}
          />
          {errors.card && <p className="text-red-500 text-xs mt-1">{errors.card}</p>}
        </div>
       <div className='w-full flex flex-row justify-between'>
           <div className="mb-4 w-[49%]">
          <label htmlFor="expiry" className="block text-gray-700 text-sm font-bold mb-2">
            Expiry
          </label>
          <input
            type="text"
            id="expiry"
            name="expiry"
            placeholder='MM/YY'
            value={formData.expiry}
            onChange={handleInputChange}
            className={`w-full p-2 border ${errors.expiry && 'border-red-500'}`}
          />
          {errors.expiry && <p className="text-red-500 text-xs mt-1">{errors.expiry}</p>}
        </div>
           <div className="mb-4 w-[49%]">
          <label htmlFor="cvc" className="block text-gray-700 text-sm font-bold mb-2">
            CVC
          </label>
          <input
            type="text"
            id="cvc"
            name="cvc"
            placeholder='CVC'
            value={formData.cvc}
            onChange={handleInputChange}
            className={`w-full p-2 border ${errors.cvc && 'border-red-500'}`}
          />
          {errors.cvc && <p className="text-red-500 text-xs mt-1">{errors.cvc}</p>}
        </div>
       </div>
     

        <div className="mb-4">
          <label htmlFor="zipcode" className="block text-gray-700 text-sm font-bold mb-2">
            ZipCode
          </label>
          <input
            type="text"
            id="zipcode"
            name="zipcode"
            placeholder='1234'
            value={formData.zipcode}
            onChange={handleInputChange}
            className={`w-full p-2 border ${errors.zipcode && 'border-red-500'}`}
          />
          {errors.zipcode && <p className="text-red-500 text-xs mt-1">{errors.zipcode}</p>}
        </div>

      

        <div className="mb-4">
        <label htmlFor="country" className="block text-gray-700 text-sm font-bold mb-2">
          Country
        </label>
        <select
          id="country"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          className={`w-full p-2 border ${errors.country && 'border-red-500'}`}
        >
          {countries.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </select>
        {errors.country && <p className="text-red-500 text-xs mt-1">{errors.country}</p>}
          </div>

     

        {/* Repeat the above structure for other fields */}

        <div className="mb-4">
          <button type="submit" className="bg-[#0f8569] text-white px-4 py-2 rounded w-full hover:bg-[#4d786e]">
            Pay
          </button>
        </div>
      </form>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Payment S"
        // className="modal"
        // overlayClassName="overlay"
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '400px',
            width: '100%',
            transform: 'none',
          },
        }}
      >
        <div className="text-center">
          <div className='font-semibold text-slate-600 text-[18px]'>{modalMessage}</div>
          <button onClick={closeModal} className="bg-blue text-white px-4 py-2 rounded mt-4">
            Close
          </button>
        </div>
      </Modal>
    </div></div>
   
  );
};

export default WebPaymentform;
