import { useEffect, useState } from "react"
import { AppImages } from "../../../../Asset/images/image"
import { useSearchParams } from "react-router-dom";
import { LAMBDA_API_BUSINESS_PORTAL_URL } from '../contexts/MyBusinessContext';
import axios from "axios";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
function Center({ children, height = 'h-screen' }) {
    return (
        <div className={`grid ${height} place-items-center`}>
            {children}
        </div>
    );
}
function Loading({ height = 'h-screen' }) {
    return (
        <Center height={height}>
            <div className='text-center'>
                <img src={AppImages.loading} className='w-[50px] block mx-auto my-0' alt="" />
            </div>
        </Center>
    )
}
function DownloadScreen() {
    const [searchParams] = useSearchParams()
    const businessId = searchParams.get('business_id')
    const [isFetchingBusiness, setIsFetchingBusiness] = useState(false)
    const [qrCode, setQrCode] = useState(null)
    const [isFetchingQrCode, setIsFetchingQrCode] = useState(false)
    const [businessData, setBusinessData] = useState(null);
    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }
    useEffect(() => {
        if (businessId) {
            setIsFetchingBusiness(true)
            axios.get(LAMBDA_API_BUSINESS_PORTAL_URL + "/app/my-business/get-business?business_id=" + businessId).then(res => {
                console.log(res?.data)
                if (res?.data?.status === 200 && res?.data?.data?.business) {
                    const b = res?.data?.data?.business
                    setBusinessData(b)
                    setIsFetchingQrCode(true)
                    const url = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${b?.marker?.dynamic_link}`
                    fetch(url).then(res => res.blob()).then(res => blobToBase64(res)).then(res => setQrCode(res)).finally(() => setIsFetchingQrCode(false))
                }
            }).catch(e => {
                console.log(e)
            }).finally(() => {
                setIsFetchingBusiness(false)
            })
        }
    }, [businessId]);
    console.log("business data", businessData);
    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const downloadImage = () => {
        // Get the element by its id or tag name
        const elementToCapture = document.getElementById('capture-element'); // Replace 'capture-element' with the actual id or tag name
      
        // Create a screenshot of the element using html2canvas
        html2canvas(elementToCapture, { useCORS: true, background: true }).then(function (canvas) {
            const imgData = canvas.toDataURL('image/jpeg');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'onecall_my_business.jpeg'; // You can change the filename and extension here
            link.click();
        });
      };
      
      const downloadPDF = () => {
        // Create a PDF of the element using jsPDF
        const elementToCapture = document.getElementById('capture-element'); // Replace 'capture-element' with the actual id or tag name
      
        html2canvas(elementToCapture, { useCORS: true, background: true }).then(function (canvas) {
            const imgData = canvas.toDataURL('image/png');
      
            // Calculate the dimensions of the PDF based on the element's dimensions
            const pdf = new jsPDF('p', 'px', [canvas.width, canvas.height]);
      
            // Add the image to the PDF
            pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
      
            // Save the PDF
            pdf.save('onecall_my_business.pdf');
        });
      };
      if(!businessId) {
        return (
          <Center>
            <div className='bg-red-200 p-3 px-4 rounded'>
              <p><span className='text-red-500 font-semibold'>Error:</span> <span>No business id is found</span></p>
            </div>
          </Center>
        )
      }
    
      if(isFetchingBusiness) {
        return (
          <Loading />
        )
      }
    return (
        <div className="">
            <div className='bg-white'>
                <div className='text-center text-[22px] bg-white font-bold mt-5'>Download for Your Business</div>
                <div className='w-full flex flex-row justify-between bg-white px-2 py-2'>
                    <button
                        className="rounded-md py-2  mb-2 text-[13px] border border-[#022f5c] w-[33%] text-black  hover:bg-[#022f5c] hover:text-white"
                        onClick={downloadImage}> Download Image  </button>

                    <div className='text-black font-semibold text-[20px]'>OR</div>
                    <button
                        onClick={downloadPDF}
                        className="rounded-md py-2  mb-2 text-[13px] border border-[#022f5c] w-[33%] text-black hover:bg-[#022f5c] hover:text-white"
                    >
                        Download PDF</button>
                </div>
            </div>
            <div  id="capture-element">

            <div className="bg-[#231f20] h-[15vh] px-3 py-3">
                <div className="flex flex-row justify-between">
                    <div>
                        <div className="py-1 px-1"><img src={businessData?.avatar_url} alt="" style={{ width: '80px', height: '80px', border: '1px solid white', borderRadius: '100%', objectFit: 'cover' }} /></div>
                    </div>
                    <div className="text-white">
                        <div className="text-[20px] text-bold" style={{ fontWeight: 'bold' }}>{businessData?.marker?.name}</div>
                        {/* <p>{businessData?.marker?.type}</p> */}
                        <p>{businessData?.marker?.type && capitalizeFirstLetter(businessData.marker.type)}</p>
                        <p className="text-[12px]">⭐⭐⭐⭐⭐ 5 Star Rating</p>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="bg-gray-800 text-white text-center h-[50vh] justify-center py-12" style={{ backgroundImage: `url(${businessData?.avatar_url})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <div className="text-[20px] " style={{ fontWeight: '500' }}>SCAN NOW</div>
                <div className="">GET {businessData?.voucher_meta?.amount} {businessData?.voucher_meta?.currency} DISCOUNT VOUCHER</div>
                <div>
                    {isFetchingQrCode ? <Loading height='h-[100px]' /> : qrCode != null ? <img
                        src={qrCode}
                        alt=""
                        srcSet=""
                        className="w-[43%] my-3 mx-auto"
                        /> : <></>}
                </div>

                <div>Get Discounts Offers and Much More</div>
                <p className="text-left px-3 mt-6">* Only on applicable offers</p>
            </div>
            <div className="flex my-3 flex-row justify-between">
                <div className="flex flex-row">
                    <img src={AppImages.onecall} alt="" />
                    <div>
                        <p className="text-[20px]" style={{ fontWeight: '700' }}>One Call</p>
                        <p className="text-[10px]" style={{ fontWeight: '500' }}>Find Everything Near You</p>
                    </div>
                </div>
                <div className="text-[10px] mt-6 text-right mr-2">
                    <p>*Terms and condition apply</p>
                    <p className="text-[10px]">www.onecallapp.com/voucher-policy</p>
                </div>
            </div>
            {/* <div><img src={AppImages.qrfooter} alt="" /></div> */}
        </div>
                        </div>
    )
}
export default DownloadScreen