import axios from "axios";
import { createContext, useState } from "react";
import { LAMBDA_API_BASE_URL, LAMBDA_API_BUSINESS_PORTAL_URL } from "../contexts/MyBusinessContext.jsx";
import { getOperatingSystem } from "../../../../utils/helpers.js";

export const DeleteAccountContext = createContext({
  activeStep: 1,
  setActiveStep: (step) => {},
  phone: null,
  setPhone: (phone) => {},
  country: { code: 'PK', telCountryCode: '92' },
  setCountry: (country) => {},
  sendPinCode: async (isDevTest) => {},
  pinCode: null,
  setPinCode: (code) => {},
  isPinCodeVerified: false,
  setIsPinCodeVerified: (answer) => {},
  userId: null,
  setUserId: (id) => {},
  deleteAPI: async () => {}
})

function DeleteAccountProvider({ children }) {
  const [activeStep, setActiveStep] = useState(1)
  const [phone, setPhone] = useState(null)
  const [country, setCountry] = useState({ code: 'PK', telCountryCode: '92' })
  const [pinCode, setPinCode] = useState(null)
  const [isPinCodeVerified, setIsPinCodeVerified] = useState(false)
  const [userId, setUserId] = useState(null)

  async function sendPinCode(isDevTest = false) {
    let url = new URL(LAMBDA_API_BUSINESS_PORTAL_URL + "/app/send-pin-code")
    url.searchParams.set('dev_test', isDevTest ? "1" : "0")
    return axios.post(url, {
      phone: phone,
      tel_country_code: country?.telCountryCode,
      ref: 'oc92',
      src: window.location.href,
      os: getOperatingSystem(window) 
    })
  }

  async function deleteAPI() {
    let url = new URL( LAMBDA_API_BASE_URL + "/auth/delete")
    return axios.post(url, {
      user_id: userId
    })
  }

  const value = {
    activeStep,
    setActiveStep,
    phone,
    setPhone,
    country, 
    setCountry,
    sendPinCode,
    pinCode,
    setPinCode,
    isPinCodeVerified,
    setIsPinCodeVerified,
    userId,
    setUserId,
    deleteAPI
  }
  return (  
    <DeleteAccountContext.Provider value={value}>
      {children}
    </DeleteAccountContext.Provider>
  );
}

export default DeleteAccountProvider;