import { useContext, useEffect, useState } from "react";
import {
    LAMBDA_API_BUSINESS_PORTAL_URL,
    MyBusinessContext,
} from "../contexts/MyBusinessContext.jsx";
import { CiImageOn } from "react-icons/ci";
import "react-tabs/style/react-tabs.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../../basic/Loading.jsx";
import { Button } from "@mui/material";
import { PiCaretRight, PiMapPin, PiPhone } from "react-icons/pi"
import { VscEdit } from "react-icons/vsc";
import { LuCopy } from "react-icons/lu";
import { FaRegShareFromSquare } from "react-icons/fa6";
import FlutterInterface from '../../../../utils/flutter_interface.js';
import ModalImageee from "./ModalImage.jsx";
function BusinessScreen() {
    const navigate = useNavigate()
    const ctx = useContext(MyBusinessContext);
    const [isFetchingStats, setIsFetchingStats] = useState(false);
    const [data, setData] = useState([]);
    const [activeTab, setActiveTab] = useState('offers');
    const [distance, setDistance] = useState(null)
    const [isOfferLoading, setIsOfferLoading] = useState(false)
    const [offers, setOffers] = useState([]);
    const [dynamicLink, setDynamicLink] = useState('');
    const [stats, setStats] = useState([]);
    const [code, setCode] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const onClickOfferCard = offer => {
        FlutterInterface.createActivityLog('tap_offer_card', offer._id.toString())
        navigate('/app/single-business/offer-detail', { state: { offer } })
    }
    const [business, setBusiness] = useState('');
    // console.log("business", business)
    const [viewAll, setViewAll] = useState(false);
    const imageUrl = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${ctx.business?.marker?.dynamic_link}`;

    const openModal = (imageUrl) => {
        // createActivityLog('wv_mb_clicked_on_zoom_qr_code', ctx?.business?._id)
        setSelectedImage(imageUrl);
        setShowModal(true);
    };
    const closeModal = () => {
        setSelectedImage(null);
        setShowModal(false);
    };

    useEffect(() => {
        async function fetchData() {
            setIsOfferLoading(true)
            try {
                let user_id = ctx.business?.user_id || ctx.env?.user_id;
                const link = ctx.business?.marker?.dynamic_link
                const business_data = ctx.business;
                setBusiness(business_data);
                setDynamicLink(link);
                setCode(ctx.business.marker);
                // let user_id = '6414624dd37934518eefc4c1'
                if (!user_id) {
                    throw new Error("No User id");
                }
                const response = await axios.get(
                    `${LAMBDA_API_BUSINESS_PORTAL_URL}/app/my-business/get-offers?user_id=${user_id}`
                );


                console.log("Dataaa", response);
                const newData = response.data.data.offers;
                setOffers(newData);
                if (newData.length > 0) {
                    setData(newData);
                    setIsOfferLoading(false)
                    //  setCurrentPage(currentPage + 1);
                } else {
                    setIsOfferLoading(false)

                }
                //  setData(response.data.data.products);

                if (response.data?.status !== 200) {
                    throw response.data;
                } else {
                    // Your other logic here
                }
                setIsFetchingStats(false);
            } catch (error) {
                console.error(error);

                if (error?.error) {
                    toast.error(
                        error?.isOperationalError ? "Something went wrong" : error?.message,
                        {
                            position: toast.POSITION.TOP_RIGHT,
                        }
                    );
                }
                // Set isFetchingStats to false when there is an error
                setIsFetchingStats(false);
            }
        }
        setIsFetchingStats(true);
        fetchData(); // Call the async function inside useEffect
    }, [ctx.business?.user_id, ctx.env?.user_id]);
    const displayedOffers = viewAll ? offers : offers.slice(0, 3);

    useEffect(() => {
        async function getStats() {
            try {
                let business_id = ctx.business?.id || ctx.env?.business_id;
                // let business_id ="6453a5f9501d3f275574ecaf"
                if (!business_id) {
                    business_id = await FlutterInterface.getBusinessId();
                    if (business_id) {
                        ctx.setEnv({ ...ctx.env, business_id });
                    }
                }

                if (!business_id) {
                    throw new Error("No business id");
                }

                const res = await axios.get(
                    LAMBDA_API_BUSINESS_PORTAL_URL +
                    "/app/my-business/stats?business_id=" +
                    business_id + "&v=2"
                );

                if (res.data?.status === 200 && res.data?.data) {
                    return res.data?.data;
                }
                throw res?.data;
            } catch (e) {

                return null;
            }
        }
        if (!ctx.businessStats || ctx.businessStats) {
            // setIsFetchingStats(true)
            getStats().then(res => {
                if (res) {
                    console.log("res...", res)
                    if (res.audience) {
                        let total = 0;
                        for (const item of res.audience) {
                            if (item[0] !== "Days " && item[1] !== "") {
                                total += parseInt(item[1], 10);
                            }
                        }
                        // setAudienceSum(total);
                        // setAudienceData(res.audience)
                    }
                    if (res.stats) {
                        setStats(res.stats.values)
                    }
                    if (res.leads) {
                        // setLeadsData(res.leads)
                    }
                    ctx.setBusinessStats(res)
                }
            }).catch(e => {

            }).finally(() => {
                setIsFetchingStats(false)
            })
        }
    }, [ctx.business])
    // console.log("data!!!", stats)

    const copyToClipboard = () => {
        // Create a new text area element
        //  createActivityLog('wv_mb_clicked_on_copy_url_btn', ctx?.business?._id)
        // const link = `https://onecallapp.com/${ctx.business?.marker?.slug}`;
        const link = ctx.business?.marker?.dynamic_link
        const textArea = document.createElement("textarea");
        textArea.value = link;

        // Append the text area to the document
        document.body.appendChild(textArea);

        // Select the text within the text area
        textArea.select();

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Remove the text area from the document
        document.body.removeChild(textArea);

        toast.success("Copied", { className: "inline-block" })
    };
    const share = () => {
        // createActivityLog('wv_mb_clicked_on_share_url_btn', ctx?.business?._id)
        // const link = `https://onecallapp.com/${ctx.business?.marker?.slug}`;
        const link = ctx.business?.marker?.dynamic_link
        FlutterInterface.share(link);
    };

    const handleButtonClick1 = () => {
        // console.log("Download Butoon clicked!")
        // createActivityLog('wv_mb_clicked_on_btn_print_qr_code', ctx?.business?._id)
        let url = `https://oc92.com/app/my-business/qrcode?business_id=${ctx?.business?._id ?? ctx?.business?.id}`
        FlutterInterface.openBrowser(url)
    };

    const postOffer = () => {
        // navigate('/app/my-business/offers/create')
        navigate('/app/my-business/post-add')
    }

    function calculateFontSize(text) {
        const maxLength = 10;
        const baseFontSize = 20;
        const maxLengthFontSize = 10;

        // Calculate font size based on text length
        const fontSize = baseFontSize - (text?.length - maxLength) * ((baseFontSize - maxLengthFontSize) / maxLength);

        return Math.max(fontSize, maxLengthFontSize); // Ensure font size doesn't go below maxLengthFontSize
    }


    return (
        <>
            <div className="text-[#232323] w-[100vw]">
                <div className={`pb-4 pt-4 bg-[#F7F7F7]`}>
                    <div className="flex flex-row justify-between items-center ">
                        <p className="text-xs mx-6 font-medium">
                            <strong className="text-[24px]">{business?.name}</strong>
                            <p className='text-[12px]'>{business?.address}</p>
                        </p>
                    </div>
                    <div className="flex justify-between items-start mt-4 mx-6">
                        {code?.serviceCodes?.length &&
                            <div className="font-light mr-4 ">
                                <p className="text-[18px] font-medium">{code?.serviceCodes[0]}</p>
                                <p className="text-[12px]">SERVICE CODE</p>
                            </div>
                        }
                        <div className="ml-auto border border-gray-500 rounded-full div-3" onClick={() => FlutterInterface.openGoogleMap(
                            data?.business?.marker?.location?.coordinates[1],
                            data?.business?.marker?.location?.coordinates[0],

                        )}>
                            <PiMapPin className="  text-gray-500 m-3" size={25} />
                        </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 mx-6">
                        {/* {data?.phone?.length && data?.phone !== 'NA' && */}
                        <div className="font-light mr-4 ">
                            <p className="text-[18px] font-medium">{business?.phone}</p>
                            <p className="text-[12px]">BUSINESS PHONE</p>
                        </div>
                        {/* } */}
                        {/* {distance && <p className="text-[22px] font-semibold">{distance}</p>} */}
                        {/* {(data?.phone?.length && data?.phone !== 'NA') && ( */}
                        <div className="ml-auto border border-gray-500 rounded-full div-3" onClick={() => navigate("/app/my-business/profile/update")}>
                            <VscEdit class="my-3 text-gray-500 mx-3" size={25} />
                        </div>
                        {/* )} */}
                    </div>
                    {/* {data?.photo_urls?.length &&
                        <div className="mt-4">
                            <img
                                src={data?.photo_urls}
                                alt=""
                                className="block w-full h-24 object-cover"
                            />
                        </div>
                    } */}
                    <div className='bg-[#232323] py-3 px-2 mt-2 text-white'>
                        <div className="flex justify-between">
                            <strong className='text-[17px]'>DISCOUNT VOUCHERS</strong>
                            <div>
                                <span>ACTIVE</span> <span className="inline-block w-4 h-4 bg-[#00B71D] rounded-full"></span>
                            </div>

                        </div>
                        <div className='flex flex-row justify-between'>
                            <div>
                                {isFetchingStats ? (<Loading />) : (
                                    <div className='flex flex-row justify-between'>
                                        <div className='justify-between mr-3'>
                                            <strong className='text-[18px]'> {stats.totalVoucherCount ? stats.totalVoucherCount : 0}</strong>
                                            <p>TOTAL</p>
                                        </div>
                                        <div className='mr-3'>
                                            <strong className='text-[18px]'>{stats.usedVoucherCount ? stats.usedVoucherCount : 0}</strong>
                                            <p>USED</p>
                                        </div>
                                        <div>
                                            <strong className='text-[18px]'>{stats.totalVoucherCount - stats.usedVoucherCount ? stats.totalVoucherCount - stats.usedVoucherCount : 0}</strong>
                                            <p>REMAINING</p>
                                        </div>
                                    </div>
                                )}

                                <div className='flex flex-row justify-between text-[18px] py-2 my-4'>
                                    {/* <p className='bg-gray-400 py-1 px-1 w-[60%] text-[14px]'>/{code?.slug}</p> */}
                                    {/* <p
                                        className='bg-gray-400 py-1 px-1 w-[60%]'
                                        style={{
                                            // fontSize: `${calculateFontSize(code?.slug)}px`,
                                            fontSize: `${calculateFontSize(code?.slug)}px`,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        /{code?.slug}

                                    </p> */}
                                    <p
                                        className='bg-gray-400 py-1 px-1 w-[60%]'
                                        style={{
                                            fontSize: `${calculateFontSize(code?.slug)}px`,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            width: '100%' // Ensures the width allows for truncation
                                        }}
                                    >
                                        {code?.slug?.length > 10 ? `/${code.slug.slice(0, 10)}...` : code?.slug}
                                    </p>


                                    <div className='rounded-full mx-1 bg-white'>
                                        <LuCopy className=' text-black rounded-full mx-2 my-2' size={25} onClick={copyToClipboard} />
                                    </div>
                                    <div className='rounded-full bg-white'>
                                        <FaRegShareFromSquare className=' text-black rounded-full mx-2 my-2' size={25} onClick={share} />
                                    </div>
                                </div>
                                <div>
                                    <p className='text-[18px]'>Get more vouchers to boost your sales and revenue</p>
                                </div>


                            </div>
                            <div className='ml-10'>
                                <div className=''>
                                    <img src={imageUrl} alt="" style={{ width: 'auto' }} onClick={() => openModal(imageUrl)} />
                                </div>
                                {showModal && (
                                    <ModalImageee
                                        imageUrl={selectedImage}
                                        closeModal={closeModal}
                                        tagLine={<h1 className="text-[26px] text-left font-medium my-4 mx-4" style={{ color: 'black' }}>{ctx.business?.name}</h1>}
                                        footer={
                                            <div className="my-4 bg-gray-100 p-4" style={{ color: 'black' }}>
                                                <h1 className="text-[24px] mb-4">Build Your Audience</h1>
                                                <p>Get your customers to scan your QR Code and build audience to Grow Your Business</p>
                                            </div>
                                        }
                                    />
                                )}
                                <div className='bg-gray-300 text-black text-center text-[12px] rounded-md px-1 py-1 mt-3'>
                                    <button onClick={handleButtonClick1} style={{ textAlign: 'center' }}>Download</button>
                                </div>
                            </div>

                        </div>

                        {/* <div className="flex justify-between">
                            <div className=''>
                                <button className="bg-[#00B71D] text-white rounded-md px-4 py-2 mt-3">UPGRADE NOW</button>
                            </div>
                            <div>
                                <button className='bg-[#F9FF00] text-black rounded-md px-4 py-2 mt-3'>UPLOAD</button>
                            </div>
                            <div></div>
                            <div></div>
                        </div> */}
                        {/* <p className="text-right mr-3">Upload QR Code print Photo</p> */}
                    </div>
                    <div>
                        <div className='bg-[#2196F3] pb-3'>
                            <div className="flex flex-row justify-between pl-6 mb-4 ">
                                {/* <p
                                     className={`text-[28px] mt-5 pr-4 cursor-pointer ${activeTab === "offers" ? "font-medium text-white" : "text-gray-400 font-light"
                                         }`}
                                     onClick={() => handleTabClick("offers")}
                                 > */}
                                <p className='text-[28px] mt-5 pr-4 cursor-pointer font-medium text-white'>
                                    AD(s)
                                </p>
                                <div className='bg-[#fff] text-[#2196F3] w-[40%] text-center mt-5 rounded-md px-6 py-2 mr-6'>
                                    <button className='mx-auto mt-1' onClick={postOffer}>POST AD</button>
                                </div>
                            </div>
                            <div className='text-white text-[17px] ml-6'>Latest AD(s)</div>
                        </div>
                        {/* Offers content */}
                        <div className={activeTab === 'offers' ? "" : "hidden"}>
                            {!isOfferLoading && !offers?.length && <div className='text-center mx-2 my-12 text-gray-500'>
                                <p>Not available now</p>
                            </div>}
                            <div className=''>
                                {displayedOffers?.map((offer, index) => {
                                    const imageMsg = offer.query_messages?.find(item => item.type === 'image')
                                    const textMsg = offer.query_messages?.find(item => item.type === 'text')
                                    const otherTextMsgs = offer.query_messages?.filter(item => item.type === 'text').slice(1)
                                    const description = offer.meta?.description?.toString().trim()
                                    return (
                                        <div key={'offer-' + index} onClick={e => onClickOfferCard(offer)} className='relative'>
                                            <div className='bg-gray-50 flex gap-2 rounded-md m-1 p-2 min-h-[80px]'>
                                                <div className='flex-[0_1_25%]'>
                                                    {imageMsg && <img src={imageMsg.content} className='object-cover rounded-md w-[80px] max-h-[80px]' alt="" />}
                                                </div>
                                                <div className='flex-[0_1_70%]'>
                                                    <div className='text-[20px] font-semibold'>{textMsg.content}</div>
                                                    <div>
                                                        {otherTextMsgs?.length ? otherTextMsgs?.map(msg => (
                                                            <p key={msg._id}>{msg.content}</p>
                                                        )) : description?.length ? <p>{description}</p> : <></>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='absolute bottom-2 right-4'>
                                                {/* <PiCaretRight /> */}
                                                <VscEdit class="my-3 text-gray-500 mx-3" size={25} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            {isOfferLoading ? <div>
                                <Loading />
                            </div> : <></>}
                        </div>
                        {!isOfferLoading && !offers?.length ? (
                            null
                        ) : (<div className="bg-[#2196F3] h-[50px] py-2">
                            <p className="text-white border-b-2 float-right mr-4" onClick={() => setViewAll(true)}>VIEW ALL</p>
                        </div>)}

                        {/* Products content */}
                        <div className={'text-center mx-2 my-12 text-gray-500 ' + (activeTab === 'products' ? "" : "hidden")}>
                            <p>Not available now</p>
                        </div>
                    </div>
                    <div className="bg-[#F7F7F7] pb-3">
                        <div className=" mx-4 my-4 border-b-2 border-gray-400 ">
                            <strong className="text-[25px] mt-4">ANALYTICS</strong>
                            <p className="text-[13px]">Know how are you doing on One Call</p>
                        </div>
                        <div className="flex flex-row mx-4 justify-between">
                            <div>
                                <strong className="text-[20px]">3,158</strong>
                                <p className="text-[13px]">AUDIENCE</p>
                            </div>
                            <div className="bg-gray-200 rounded-md px-3 pr-[50px] pb-[50px]">
                                <p className="text-[#232323]">How to grow your audience?</p>
                            </div>
                        </div>
                        <div>
                            {isFetchingStats ? (<Loading />) : (
                                <div className="flex flex-row justify-between mx-4 mt-4">
                                    <div>
                                        <strong className="text-[20px]">{stats?.viewCount}</strong>
                                        <p className="text-[#232323]">Views</p>
                                    </div>
                                    <div>
                                        <strong className="text-[20px]">{stats?.callCount}</strong>
                                        <p className="text-[#232323]">Calls</p>
                                    </div>
                                    <div>
                                        <strong className="text-[20px]">{stats?.locationViewCount}</strong>
                                        <p className="text-[#232323]">Location Views</p>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default BusinessScreen;

