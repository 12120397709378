import { AppImages } from "../Asset/images/image";
const images = AppImages;
const Rent = [
  {
    title: "10 Marla House for Rent, required in DHA",
    // title1: "Budget 70,000/-",
    time: "11:23 PM 15 July, 2023",
    phone: "0330 5345346",
    id: "r1",
  },
  // {
  //   title: "5 Marla House required on rent in DHA 1, 2 or 3",
  //   time: "2:22 PM 11 July, 2023",
  //   phone: "0330 5345346",
  //   id: "r2",
  // },
  // {
  //   title: "4 Marla commercial required on rent in DHA 6 or 8",
  //   time: "2:29 PM 11 July, 2023",
  //   phone: "0330 5345346",
  //   id: "r3",
  // },
  // {
  //   title: "1 Kanal house required on rent in DHA Raya",
  //   time: "2:42 PM 11 July, 2023",
  //   phone: "0330 5345346",
  //   id: "r4",
  // },
];

export default Rent;
