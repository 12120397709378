import { BsBriefcase } from "react-icons/bs";
import { MdOutlinePhone } from "react-icons/md";
import { RiMapPin2Line } from "react-icons/ri";

function BusinessHeader({ name, category, phone, address, serviceCode }) {
  return (
    <div className="px-4">
      <div className="flex justify-between items-center">
        <div className="font-semibold text-lg">{name}</div>
      </div>

      <div className="flex items-center my-1">
        <div className="flex-[0_0_10%]">
          <BsBriefcase />
        </div>
        <div>{category ?? "--"}</div>
      </div>

      <div className="flex items-center my-1">
        <div className="flex-[0_0_10%]">
          <MdOutlinePhone />
        </div>
        <div>{phone ?? "--"}</div>
      </div>

      <div className="flex items-start my-1">
        <div className="flex-[0_0_10%]">
          <RiMapPin2Line />
        </div>
        <div>{address ?? "--"}</div>
      </div>
      {!serviceCode?.length ? (
        ""
      ) : (
        <div className="flex flex-row my-1">
          <div className="font-semibold">Service Code</div>
          <div className="ml-1">{serviceCode[0]}</div>
        </div>
      )}
    </div>
  );
}

export default BusinessHeader;