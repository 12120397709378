import React from 'react'
import { TiTick } from 'react-icons/ti'
import { useNavigate } from 'react-router-dom';

const Subscription = () => {
    const navigate = useNavigate();
  return (
    <div className='text-[#464A52] mx-2'>
        <div className="flex flex-col items-center justify-center text-[#464A52] mx-2">
      <div className='font-bold text-[19px] mt-[50px]'>Subscription</div>
        <div className='border-[1px] border-[#E8E8E9] w-full my-5'></div></div>
        <div className='text-[23px] '>
            Popular plan
        </div>
        <div className='my-2 text-[14px] mr-4'>
          Introducing our highly acclaimed business subscription plan, meticulously curated to cater to the dynamic needs of modern enterprises. This sought-after subscription seamlessly combines convenience, efficiency, and cost-effectiveness, making it the go-to choice for businesses looking to thrive in today's competitive landscape.
        </div>
        <div className='text-[14px] mt-4'>
         Subscription fee
        </div>
        <div className='text-[23px] '>Rs. 1900/month</div>
        <div className='flex flex-row items-center mt-3'>
            <div><TiTick className='text-[#2196F3] text-[24px]'/></div>
            <div className='text-[13px] ml-2 mt-1'>456 Leads per month</div>
        </div>
        <div className='flex flex-row items-center mt-3'>
            <div><TiTick className='text-[#2196F3] text-[24px]'/></div>
            <div className='text-[13px] ml-2 mt-1'>7 featured posts / week</div>
        </div>
        <div className='flex flex-row items-center mt-3'>
            <div><TiTick className='text-[#2196F3] text-[24px]'/></div>
            <div className='text-[13px] ml-2 mt-1'>Unique selling points of this offer</div>
        </div>
          <div className='flex justify-center mt-[34px]'>
           
          <button
            className="bg-[#2196F3] rounded-md p-2 mb-2 text-white font-normal text-[16px] w-full mt-[37px]"
             onClick={() => navigate("/app/payment")}
          >
            Continue
          </button>
          
        </div>

        </div>
  )
}

export default Subscription