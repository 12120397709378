import { useContext, useState } from "react";
import { DeleteAccountContext } from "./DeleteAccountContext.jsx";
import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input";
import { AppImages } from "../../../../Asset/images/image.js";
import AppToastContainer from "../../../basic/AppToast.jsx";
import { toast } from "react-toastify";

function DeleteMyAccount() {
  const ctx = useContext(DeleteAccountContext)
  return (  
    <>
      <AppToastContainer />
      <div className="relative w-full h-screen bg-gray-200">
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80vw] md:w-[40vw] lg:w-[30vw] h-[50vh] p-2 bg-white rounded-lg">
          <div className="text-center font-semibold my-2 border-b-2 border-slate-100">Delete My Account</div>
          <div className="flex items-center justify-center gap-10 my-4">
            <Step number={1} isActive={ctx.activeStep == 1} />
            <Step number={2} isActive={ctx.activeStep == 2} />
            <Step number={3} isActive={ctx.activeStep == 3} />
            <Step number={4} isActive={ctx.activeStep == 4} />
          </div>
          <div className="my-4">
            { ctx.activeStep == 1 && <StepOneContent /> }
            { ctx.activeStep == 2 && <StepTwoContent /> }
            { ctx.activeStep == 3 && <StepThreeContent /> }
            { ctx.activeStep == 4 && <StepFourContent /> }
            { ctx.activeStep == 5 && <StepFiveContent /> }
          </div>
        </div>
      </div>
    </>
  );
}

function Step({ number, isActive = false }) {
  return <div className={ (isActive ? "bg-blue text-white cursor-pointer" : "bg-slate-200 text-gray-400") + " w-[20px] h-[20px] text-center text-[10px] rounded-full relative"}><span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">{number}</span></div>
}

function StepOneContent({ children }) {
  const ctx = useContext(DeleteAccountContext)
  return (
    <>
      <p className="text-[13px]">This dialog will help you delete your user profile, business profile from One Call App. Please click on 'Continue' to proceed further, or click on 'No' otherwise.</p>
      <div className="flex justify-center flex-col items-center">
        <button 
          className="px-6 py-2 my-2 bg-blue text-center text-white rounded-full inline-block min-w-[150px] text-[14px]"
          onClick={() => ctx.setActiveStep(2)}
        >Continue</button>
        <button className="px-6 py-2 my-2 bg-gray-600 text-center text-white rounded-full inline-block min-w-[150px] text-[14px]">No</button>
      </div>
    </>
  )
}

function StepTwoContent() {
  const ctx = useContext(DeleteAccountContext)
  const [isLoading, setIsLoading] = useState(false)
  return (
    <>
      <div>
        <div>
          <label className="text-[13px]">
            Enter your mobile number registered on One Call App. We will send 4 digit pin code on your entered mobile number, if it is registered with One Call App, for verification.
          </label>
            <PhoneInput 
              defaultCountry="PK"
              value={ctx.phone ?? ""}
              className="border-[1px] my-4 py-1 px-3 border-gray-400 rounded-lg outline-none"  
              onChange={(val) => {
                ctx.setPhone(val)
              }}
              onCountryChange={(country) => {
                ctx.setCountry({ code: country, telCountryCode: getCountryCallingCode(country) })
              }}
            />
        </div>

        <div className="flex justify-center flex-col items-center">
          <button 
            className="px-6 py-2 my-2 bg-blue disabled:bg-gray-300 text-center text-white rounded-full inline-block min-w-[150px] text-[14px]"
            onClick={() => {
              if(!ctx.country) {
                toast.error('Please choose a country')
                return;
              }
              if(!ctx.phone) {
                toast.error('Invalid phone')
                return;
              }
              const telCountryCode = ctx.country?.telCountryCode
              let phoneWithoutCountryCode = ctx.phone.toString().replace(/[^0-9]/gi, "").slice(telCountryCode.length)
              if(!phoneWithoutCountryCode || phoneWithoutCountryCode.length !== 10) {
                toast.error('Invalid phone')
                return;
              }
              setIsLoading(true)
              ctx.sendPinCode(true).then(res => {
                if(res?.data?.data?.is_sent && res?.data?.data?.pin_code) {
                  console.log('code is sent')
                  if(res?.data?.data?.user_id) {
                    ctx.setUserId(res?.data?.data?.user_id)
                  }
                  ctx.setPinCode(res.data?.data?.pin_code)
                  ctx.setActiveStep(3)
                } else {
                  throw res?.data 
                }
              }).catch(e => {
                toast.error(
                  e?.error ? e?.isOperationalError ? "Something went wrong" : e?.message ? e?.message : "Something went wrong" : "Something went wrong" 
                )
              }).finally(() => {  
                setIsLoading(false)
              })
            }}
            disabled={isLoading}
          >
            <div><img src={AppImages.loading} className={ (isLoading ? "block" : "hidden") + " w-[20px] mx-auto"} /></div>
            <div className={(isLoading ? "hidden" : "block")}>Continue</div>
          </button>
          <button 
            className="px-6 py-2 my-2 bg-gray-600 disabled:bg-gray-300 text-center text-white rounded-full inline-block min-w-[150px] text-[14px]"
            onClick={() => ctx.setActiveStep(1)}
            disabled={isLoading}
          >Go Back</button>
        </div>
      </div>
    </>
  )
}

function StepThreeContent() {
  const ctx = useContext(DeleteAccountContext)
  const [enteredPinCode, setEnteredPinCode] = useState(null)
  return (
    <>
      <div>
        <div>
          <label className="text-[13px]">
            Enter 4-digit one-time pin code we have sent on your entered mobile number:
          </label>
          <input 
            type="text" 
            className="border-[1px] border-gray-400 block w-full box-border px-4 py-1 rounded-lg my-4 outline-blue text-[13px]" 
            placeholder="Enter pin code"
            onChange={e => setEnteredPinCode(e.target.value)}
          />
        </div>

        <div className="flex justify-center flex-col items-center">
          <button 
            className="px-6 py-2 my-2 bg-blue text-center text-white rounded-full inline-block min-w-[150px] text-[14px]"
            onClick={() => {
              if(ctx.pinCode) {
                const pin_code = ctx.pinCode.toString()
                if(!enteredPinCode) {
                  toast.error('Please enter code')
                  return;
                }
                if(enteredPinCode !== pin_code) {
                  toast.error("Wrong/Invalid code")
                  return;
                }
                if(!ctx.userId) {
                  toast.error("Error finding such user")
                  return;
                }
                ctx.setIsPinCodeVerified(true)
                ctx.setActiveStep(4)
              }
            }}
          >Verify</button>
          <button 
            className="px-6 py-2 my-2 bg-gray-600 text-center text-white rounded-full inline-block min-w-[150px] text-[14px]"
            onClick={() => ctx.setActiveStep(2)}
          >Go Back</button>
        </div>
      </div>
    </>
  )
}

function StepFourContent() {
  const ctx = useContext(DeleteAccountContext)
  const [isLoading, setIsLoading] = useState(false)
  return (
    <>
      <div>
        <div className="flex justify-center flex-col items-center">
          <button 
            className="px-6 py-2 my-2 bg-red-500 disabled:bg-gray-300 text-center text-white rounded-full inline-block min-w-[150px] text-[14px]"
            onClick={() => {
              setIsLoading(true)
              ctx.deleteAPI().then(res => {
                ctx.setPinCode(null)
                ctx.setIsPinCodeVerified(false)
                ctx.setUserId(null)
                ctx.setActiveStep(5)
                ctx.setPhone(null)
                toast.success('Your account has been deleted', {
                  autoClose: false,
                })
              }).catch(e => {
                toast.error('Something went wrong!')
              }).finally(() => {
                setIsLoading(false)
              })
            }}
            disabled={isLoading}
          >
            <div><img src={AppImages.loading} className={ (isLoading ? "block" : "hidden") + " w-[20px] mx-auto"} /></div>
            <div className={(isLoading ? "hidden" : "block")}>Delete My Account</div>
          </button>
          <button 
            className="px-6 py-2 my-2 bg-gray-600 disabled:bg-gray-300 text-center text-white rounded-full inline-block min-w-[150px] text-[14px]"
            onClick={() => {
              ctx.setUserId(null)
              ctx.setIsPinCodeVerified(false)
              ctx.setActiveStep(1)
              ctx.setPhone(null)
            }}
            disabled={isLoading}
          >No, I changed my mind</button>
        </div>
      </div>
    </>
  )
}

function StepFiveContent() {
  return (
    <>
      <div>
        <p>Your account has been deleted successfully!</p>
      </div>
    </>
  )
}

export default DeleteMyAccount;