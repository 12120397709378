import { useCallback, useContext, useEffect, useState } from "react";
import {
  LAMBDA_API_BUSINESS_PORTAL_URL,
  MyBusinessContext,
} from "../contexts/MyBusinessContext.jsx";
import { MdOutlineEdit, MdOutlinePhone } from "react-icons/md";
import { BsBriefcase } from "react-icons/bs";
import { RiMapPin2Line } from "react-icons/ri";
import "react-tabs/style/react-tabs.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import AppToastContainer from "../../../basic/AppToast.jsx";
import Loading from "../../../basic/Loading.jsx";

function Orders() {
  const ctx = useContext(MyBusinessContext);
  const navigate = useNavigate();
  const [isFetchingStats, setIsFetchingStats] = useState(false);
  const [order, setOrder] = useState(null);

  const fetchOrder = useCallback(() => async function () {
    try {
      let business_id = ctx.business?.id || ctx.env?.business_id;

      if (!business_id) {
        throw new Error("No business id");
      }

      //  const response = await axios.get(
      //    LAMBDA_API_BUSINESS_PORTAL_URL +
      //      "/app/my-business/products/get?business_id=" +
      //      business_id
      //  );
      const response = await axios.get(
        `${LAMBDA_API_BUSINESS_PORTAL_URL}/app/orders/get-for-user?user_id=6414624dd37934518eefc4c1`
      );

      console.log("OrderrrDataaaa", response);
      const newData = response.data.data.orders; // Use response.data directly

      if (newData.length > 0) {
        // Append the new data to the existing data
        setOrder(newData);
        // setCurrentPage(currentPage + 1);
      } else {
        // No more data available
        
      }
      //  setData(response.data.data.products);

      if (response.data?.status !== 200) {
        throw response.data;
      } else {
        // Your other logic here
      }

      // Set isFetchingStats to false when the response is received
      setIsFetchingStats(false);
    } catch (error) {
      console.error(error);

      if (error?.error) {
        toast.error(
          error?.isOperationalError ? "Something went wrong" : error?.message,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }

      // Set isFetchingStats to false when there is an error
      setIsFetchingStats(false);
    }
  }, [ctx.business?.id, ctx.env?.business_id])
 
  useEffect(() => {
    setIsFetchingStats(true);
    fetchOrder();
  }, [fetchOrder]);

  //   if (isFetchingBusiness) {
  //     return (
  //       <>
  //         <div className="relative h-screen">
  //           <img
  //             src={AppImages.loading}
  //             className="w-[50px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
  //           />
  //         </div>
  //       </>
  //     );
  //   }

  return (
    <>
      <AppToastContainer />
      <div className="py-4">
        <div>
          <div className="px-4">
            <div className="flex justify-between items-center">
              <div className="font-semibold text-lg">{ctx.business?.name}</div>
              <div
                className="bg-[#D6F3FFAA] rounded-full p-2"
                onClick={() => navigate("/app/my-business/profile/update")}
              >
                <MdOutlineEdit color="#000" />
              </div>
            </div>

            <div className="flex items-start my-1">
              <div className="flex-[0_0_10%]  my-1">
                <BsBriefcase />
              </div>
              <div>{ctx.business?.category ?? "--"}</div>
            </div>

            <div className="flex items-start my-1">
              <div className="flex-[0_0_10%]  my-1">
                <MdOutlinePhone />
              </div>
              <div>{ctx.business?.phone ?? "--"}</div>
            </div>

            <div className="flex items-start my-1">
              <div className="flex-[0_0_10%]  my-1">
                <RiMapPin2Line />
              </div>
              <div>{ctx.business?.address ?? "--"}</div>
            </div>
            {!ctx.business?.marker?.serviceCodes.length ? (
              ""
            ) : (
              <div className="flex flex-row my-1">
                <div className="font-semibold">Service Code </div>
                <div className="ml-1">
                  {ctx.business?.marker?.serviceCodes[0] ?? "--"}
                </div>
              </div>
            )}
            {/* <div className="flex items-start my-1">
              <div className="flex-[0_0_10%] my-1">
                <AiOutlineStar />
              </div>
              <div>
                <span className="font-bold mr-3">4.1</span>1000+ratings
              </div>
            </div>
            <div className="flex items-start my-1">
              <div className="flex-[0_0_10%] my-1">
                <AiOutlineClockCircle />
              </div>
              <div>Delivery 35 min</div>
            </div> */}
          </div>

          {/* <div className="my-2">
            <ul className="flex items-center gap-2 overflow-x-auto">
              {business?.photo_urls ? (
                [business?.photo_urls].map((img, ind) => (
                  <li
                    key={"bi-ind-" + ind}
                    onClick={() => {
                      setSelectedImage(img);
                    }}
                  >
                    <img
                      src={img}
                      className="min-w-[100px] h-[70px] object-cover rounded-lg"
                    />
                  </li>
                ))
              ) : (
                <></>
              )}
            </ul>
          </div> */}

          <div className="border-b-[1px] border-slate-200 my-4"></div>

          <div>
           
                {isFetchingStats ? (
                  <div>
                    <Loading />
                  </div>
                ) : (
                  <div className="">
                    {/* <InfiniteScroll
                      dataLength={data.length}
                      next={fetchData}
                      hasMore={hasMore}
                      loader=<Loading /> // You can customize the loading indicator
                    > */}
                    {order?.map((item, index) => (
                      <div
                        key={index}
                        className=" w-full border border-slate-200"
                      >
                        {item.cart.map((itemm, index) => (
                          <div key={index} className="h-[100px]">
                            <div className="flex flex-row h-full w-full my-2 ">
                              <div className="w-[30%] px-1 h-full">
                                <img
                                  src={itemm.line_item.image_url}
                                  alt=""
                                  srcset=""
                                  className="h-full"
                                />
                              </div>
                              <div className="w-[70%] mx-1">
                                <div className="font-semibold">
                                  {itemm.line_item.name}
                                </div>
                                <div className="flex flex-row">
                                  <div className="font-semibold">
                                    Quantity :
                                  </div>
                                  <div className="ml-1">{itemm.quantity}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="flex flex-row">
                          <div className="font-semibold">PaymentMethod :</div>
                          <div className="ml-1 text-green-500">
                            {item.payment_method}
                          </div>
                          {/* <div className=""></div> */}
                        </div>
                        <div className="flex flex-row justify-between">
                          <div className="flex flex-row">
                            <div>Status : </div>
                            <div className="ml-1 text-red-500">
                              {item.status}
                            </div>
                          </div>
                          <div className="mr-2">
                            <div>
                              {item.price.amount}
                              {item.price.currency}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* </InfiniteScroll> */}
                  </div>
                )}
         
          </div>
        </div>
      </div>
      {/* <SimpleModal 
        isOpen={selectedImage != null}
        onClose={() => setSelectedImage(null)}
      >
        <div className="max-h-[80vh] max-w-[80vw]">
          <div>
            <img src={selectedImage} />
          </div>
          <div className="flex items-center justify-between my-2">
            <div onClick={() => {
              setImages(images => (images.filter(img => img !== selectedImage)))
              setSelectedImage(null)
            }}>Delete</div>
            <div>Set as cover</div>
          </div>
        </div>
      </SimpleModal> */}
    </>
  );
}

export default Orders;
