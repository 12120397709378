import React, { useEffect } from 'react';
import { AppImages } from '../../../../Asset/images/image';
import axios from 'axios';
import FlutterInterface from '../../../../utils/flutter_interface.js';
import HKHeader from './HKHeader.jsx';

const HKMember = () => {
  useEffect(() => {
    FlutterInterface.getUUID().then(uuid => {
      axios.get(`https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/hkp/get-member-dashboard-data?uuid=${uuid}`).then(res => {
        console.log(res)
      })
    })
  }, [])
  return (
    <div>
      <HKHeader />
      <div>
        <div className='flex justify-between items-center mx-4'>
          <p className='font-semibold'>Today's Mission</p>
          <p dir="rtl" className='font-bold'>آج کا مشن</p>
        </div>
        <div className='my-4'>
          <p className='text-center text-[14px] text-gray-400'>Will be updated soon.</p>
        </div>
      </div>
    </div>
  )
};

export default HKMember;
