import './App.css';
import 'react-loading-skeleton/dist/skeleton.css'
import LeadPage from './component/LeadPage';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import InfoPage from './component/pages/Info';
import UserRequestPage from './component/pages/UserRequestPage';
import HomePage from './component/HomePage';
import BusinessPage from './component/BusinessPage';
import InterviewForm from './component/InterviewForm';
import RadioButtons from './component/RadioButton';
import Form from './component/form';
import EmployerForm from './component/EmployerForm';
import SubmittedForm from './component/SubmittedForm';
import CreatePostScreen from './component/modules/Post/CreatePostScreen';
import ChatScreen from './component/modules/Chat/ChatScreen.jsx';
import PostListScreen from './component/modules/Post/PostListScreen.jsx';
import PostPublishScreen from './component/modules/Post/PostPublishScreen.jsx';
import HelpScreen from './component/modules/OneCall/Help/HelpScreen.jsx';
import ShortUrlScreen from './component/modules/ShortUrl/ShortUrlScreen.jsx';
import NoMatch from './component/pages/NoMatch.jsx';
import ReferralScreen from './component/modules/Referral/ReferralScreen.jsx';
import TestPage from './component/modules/Test/TestPage.jsx';
import BusinessRequest from './component/modules/DropDown/BusinessRequest.jsx';
import BankAccountDropdown from './component/modules/DropDown/BankAccountDropDown.jsx';
import BusinessLeadScreen from './component/modules/BusinessLead/BusinessLeadScreen.jsx';
import GalleriaProperties from './component/modules/DropDown/GalleriaProperties';
import GalleriaForm from './component/GalleriaForm';
import DPage from './component/DPage';
import PPage from './component/PPage';
import MPage from './component/MPage';
import GalleriaPropertyTable from './component/GalleriaPropertyTable';
import CPage from './component/CPage';
import CustomerForm from './component/CustomerForm';
import Acceptance from './component/Acceptance';
import GalleriaProperties1 from './component/modules/DropDown/GalleriaProperties1';
import ImageUploader from './component/ImageUploader';
import GalleriaLeadsListing from './component/modules/DropDown/GalleriaLeadsListing';
import ImageUploaderr from './ImageUploader';
import ExcelToJsonConverter from './ExcelToJsonConverter';
import PreFabricated from './component/PreFabricated';
import OnecallLeads from './component/modules/DropDown/OnecallLeads';
import PartnerForm from './component/modules/Partner/PartnerForm.jsx';
import ConfirmSubmission from './component/modules/Partner/ConfirmSubmission.jsx';
import PartnerFormProvider from './component/modules/Partner/PartnerFormProvider.jsx';
import Mobileshop from './component/Mobileshop';
import OnecallOffer from './component/modules/DropDown/OnecallOffer';
import OnecallYt from './component/modules/DropDown/OnecallYt';
import GalleriaRequestForm from './component/modules/Galleria/RequestForm.jsx';
import AppOnlyRoute from './component/modules/OneCall/AppOnlyRoute.jsx';
import MyBusinessContextProvider from './component/modules/OneCall/contexts/MyBusinessContext.jsx';
import MBWelcomeScreen from './component/modules/OneCall/MyBusiness/MBWelcomeScreen.jsx';
import MBLogin from './component/modules/OneCall/MyBusiness/MBLogin.jsx';
import MBSignup from './component/modules/OneCall/MyBusiness/MBSignup.jsx';
import SingleBusinessScreen from './component/modules/OneCall/SingleBusiness/SingleBusinessScreen.jsx';
import MBPrivateRouteWrapper from './component/modules/OneCall/MyBusiness/MBPrivateRouteWrapper.jsx';
import MBUpdateProfileScreen from './component/modules/OneCall/MyBusiness/MBUpdateProfile.jsx';
import MBHome from './component/modules/OneCall/MyBusiness/MBHome.jsx';
import MBStatsScreen from './component/modules/OneCall/MyBusiness/MBStatsScreen.jsx';
import NearByBusinesses from './component/modules/OneCall/NearbyBusinesses.jsx';
import PostOfferScreen from './component/modules/OneCall/Offer/PostOfferScreen.jsx';
import CreateOfferContextProvider from './component/modules/OneCall/Offer/CreateOfferContext.jsx';
import DeleteMyAccount from './component/modules/OneCall/DeleteAccount/DeleteMyAccount';
import DeleteAccountProvider from './component/modules/OneCall/DeleteAccount/DeleteAccountContext';
import Product from './component/modules/OneCall/MyBusiness/Product';
import AddProduct from './component/modules/OneCall/MyBusiness/AddProduct';
import EditProduct from './component/modules/OneCall/SingleBusiness/Product/UpdateProduct';
import SingleBusinessScreen1 from './component/modules/OneCall/SingleBusiness/SingleBusinessScreen1';
import SingleProductView from './component/modules/OneCall/SingleBusiness/SingleProductView';
import SingleBusinessContextProvider from './component/modules/OneCall/SingleBusiness/contexts/SingleBusinessContext.jsx';
import CartScreen from './component/modules/OneCall/SingleBusiness/Cart/CartScreen';
import CartScreen1 from './component/modules/OneCall/SingleBusiness/Cart/CartScreen1';
import Checkout from './component/modules/OneCall/SingleBusiness/Cart/Checkout';
import SingleProductViewBusiness from './component/modules/OneCall/SingleBusiness/SingleProductViewBusiness';
import Cart from './component/modules/OneCall/SingleBusiness/Cart/Cart';
import BPage from './component/modules/OneCall/BPage';
import Orders from './component/modules/OneCall/SingleBusiness/Orders';
import Onecallkey from './component/modules/OneCall/MyBusiness/Onecallkey';
import QRScreen from './component/modules/OneCall/MyBusiness/QRScreen';
import OfferView from './component/modules/OneCall/SingleBusiness/OfferView.jsx';
import { BusinessReport } from './component/BusinessReport.jsx';
import BusinessReportDetail from './component/BusinessReportDetail.jsx';
import AddMenu from './component/modules/OneCall/MyBusiness/AddMenu.jsx';
import AddItem from './component/modules/OneCall/MyBusiness/AddItem.jsx';
import BusinessScreen from './component/modules/OneCall/MyBusiness/BusinessScreen.jsx';
import PostAddScreen from './component/modules/OneCall/MyBusiness/PostAddScreen.jsx';
import DownloadScreen from './component/modules/OneCall/MyBusiness/DownloadScreen.jsx';
import AuthenticationForm from './component/modules/OneCall/MyBusiness/AuthenticationForm.jsx';
import HwmLogo from './component/modules/OneCall/MyBusiness/hwmLogo.jsx';
import Menu from './component/modules/OneCall/MyBusiness/Menu.jsx';
import MenuScreen from './component/modules/OneCall/MyBusiness/MenuScreen.jsx';
import GoLive from './component/modules/OneCall/MyBusiness/GoLive.jsx';
import Subscription from './component/modules/OneCall/MyBusiness/Subscription.jsx';
import Payment from './component/modules/OneCall/MyBusiness/Payment.jsx';
import Stripe from './component/modules/OneCall/MyBusiness/Stripe.jsx';
import StripePromise from './component/modules/OneCall/MyBusiness/StripePromise.jsx';
import StripeFirstForm from './component/StipeFirstForm.jsx';
import CheckoutForm from './component/modules/OneCall/MyBusiness/CheckoutForm.jsx';
import PlanSelectionForm from './component/modules/OneCall/MyBusiness/PlanSelectionForm.jsx';
import Electionform from './component/modules/OneCall/ElectionCommision/Electionform.jsx';
import PaymentUrl from './component/PaymentUrl.jsx';
import StripePromiseOne from './component/modules/OneCall/MyBusiness/StripePromiseOne.jsx';
import PaymentForm from './component/modules/OneCall/MyBusiness/Paymentfom.jsx';
import WebPaymentform from './component/WebPaymentform.jsx';
import OfferWebviewScreen from './component/modules/OneCall/OfferWebviewScreen.jsx';
import HKMember from './component/modules/OneCall/HaqooqeKhalaq/HKMember.jsx';
import TeacherNotification from './component/TeacherNotification.jsx';
import HKWorker from './component/modules/OneCall/HaqooqeKhalaq/HKWorker.jsx';
import SingleBusinessScreen2 from './component/modules/OneCall/SingleBusiness/SingleBusinessScreen2.jsx';
import SingleOfferScreen from './component/modules/OneCall/SingleBusiness/SingleOfferScreen.jsx';
import SingleDiscountScreen from './component/modules/OneCall/SingleBusiness/SingleDiscountScreen.jsx';
import DiscountNotificationScreen from './component/modules/OneCall/SingleBusiness/DiscountNotificationScreen.jsx';
import BawaMain from './component/modules/OneCall/BawaOrg/BawaMain.jsx';
import BawaMember from './component/modules/OneCall/BawaOrg/BawaMember.jsx';
import BawaWorker from './component/modules/OneCall/BawaOrg/BawaWorker.jsx';
import CreateOfferScreen from './component/modules/OneCall/MyBusiness/CreateOfferScreen.jsx';
import SingleBusinessMain from './component/modules/OneCall/SingleBusiness/SingleBusinessMain.jsx';
import SingleMessageNewCustomerScreen from './component/modules/Landing/SingleMessageNewCustomerScreen.jsx';
// import SplitForm from './component/SplitForm.jsx';
// import StripeSecondForm from './component/StripeSecondForm.jsx';
// import Main from './component/modules/OneCall/MyBusiness/Main.jsx';

// import BusinessPortalRoutes from '../business-portal/src/main.jsx'
// import AuthContextProvider from '../business-portal/src/contexts/AuthContext.jsx';

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route exact path="/s/:id" element={<ShortUrlScreen />}></Route>
          <Route exact path="/payment/:cardNumber/:cvc/:expiryDate/:zipCode/:name" element={<PaymentUrl />}></Route>
          <Route exact path="/q" element={<LeadPage />}></Route>
          <Route exact path={"/messages/new-customer/:messageId"} element={<SingleMessageNewCustomerScreen />}></Route>
          <Route exact path="/q/info" element={<InfoPage />}></Route>
          <Route exact path="/u" element={<UserRequestPage />}></Route>
          {/* <Route exact path="/ip" element={<IntroPage />}></Route> */}
          <Route exact path="/businessReport" element={<BusinessReport />}></Route>
          <Route exact path="/businessReportDetail" element={<BusinessReportDetail />}></Route>
          <Route exact path="/search" element={<BusinessPage />}></Route>
          <Route exact path="/" element={<HomePage />}></Route>
          <Route exact path="/submitted" element={<SubmittedForm />}></Route>
          <Route exact path="/button" element={<RadioButtons />}></Route>
          <Route exact path="/jobs" element={<InterviewForm />}></Route>
          <Route exact path="/employerForm" element={<EmployerForm />}></Route>
          <Route exact path="/electionForm" element={<Electionform />}></Route>
          <Route exact path="/converter" element={<ExcelToJsonConverter />}></Route>
          <Route exact path="/become-partner" element={<CustomerForm />}></Route>
          <Route exact path="/stripe" element={<Stripe />}></Route>
          <Route exact path="/become-partner/confirm" element={<Acceptance />}></Route>
          <Route exact path="/yt" element={<OnecallYt />}></Route>
          <Route exact path="/post/create" element={<CreatePostScreen />}></Route>
          <Route exact path="/chat/:chat_ref" element={<ChatScreen />}></Route>
          <Route exact path="/posts/:user_id" element={<PostListScreen />}></Route>
          <Route exact path="/pd" element={<PostPublishScreen />}></Route>
          <Route exact path="/onecall/web/help" element={<HelpScreen />}></Route>
          <Route exact path="/spf" element={<StripePromise />}></Route>
          <Route exact path="/paymenturl" element={<PaymentUrl />}></Route>
          <Route exact path="/paymentform" element={<WebPaymentform />}></Route>
          <Route exact path="/psf" element={<PlanSelectionForm />}></Route>
          <Route exact path="/cof" element={<CheckoutForm />}></Route>
          <Route exact path="/business-leads" element={<BusinessLeadScreen />}></Route>
          {/* <Route exact path='/test' element={<TestPage />}></Route> */}
          <Route exact path="/d" element={<DPage />}></Route>
          <Route exact path="/p" element={<PPage />}></Route>
          <Route exact path="/m" element={<MPage />}></Route>
          <Route exact path="/c" element={<CPage />}></Route>
          {/* <Route exact path="/galleria/form" element={<GalleriaForm />}></Route> */}
          <Route exact path="/rf" element={<GalleriaForm />}></Route>
          <Route exact path="/pf" element={<PreFabricated />}></Route>
          <Route exact path="/galleria/t" element={<GalleriaPropertyTable />}></Route>
          <Route exact path="/gp" element={<GalleriaProperties />}></Route>
          <Route exact path="/gp/lead" element={<GalleriaProperties1 />}></Route>
          <Route exact path="/onecall/lead" element={<OnecallLeads />}></Route>
          <Route exact path="/onecall/offer" element={<OnecallOffer />}></Route>
          <Route exact path="/gp/leads/listing" element={<GalleriaLeadsListing />}></Route>
          <Route exact path="/uploader" element={<ImageUploaderr />}></Route>
          <Route exact path="/image" element={<ImageUploader />}></Route>
          <Route exact path="/mobileshop" element={<Mobileshop />}></Route>
          <Route exact path="/notification" element={<TeacherNotification />}></Route>
          <Route exact path="/businessRequest/:userId" element={<BusinessRequest />}></Route>

          <Route
            exact
            path="/partner"
            element={
              <PartnerFormProvider>
                <PartnerForm />
              </PartnerFormProvider>
            }
          ></Route>
          <Route
            exact
            path="/acceptance"
            element={
              <PartnerFormProvider>
                <ConfirmSubmission />
              </PartnerFormProvider>
            }
          ></Route>
          <Route path="/galleria/request" element={<GalleriaRequestForm />}></Route>
          <Route path="/app/user/orders/single/:orderId" element={<BPage />}></Route>

          <Route element={<AppOnlyRoute />}>
            <Route element={<CreateOfferContextProvider />}>
              <Route path="/app/post-offer" element={<PostOfferScreen />}></Route>
            </Route>

            <Route element={<MyBusinessContextProvider />}>
              <Route path="/app/key" element={<Onecallkey />}></Route>
              <Route element={<MBPrivateRouteWrapper />}>
                <Route path="/app/my-business/home" element={<MBHome />}></Route>
                {/* <Route path="/app/my-business/stats" element={<MBStatsScreen />}></Route> */}
                <Route path="/app/my-business/profile/update" element={<MBUpdateProfileScreen />}></Route>
                <Route path="/app/my-business/product" element={<Product />}></Route>
                <Route path="/app/my-business/add-product" element={<AddProduct />}></Route>
                <Route path="/app/my-business/edit-product" element={<EditProduct />}></Route>
                <Route path="/app/business" element={<SingleBusinessScreen1 />}></Route>
                {/* <Route path="/app/bus" element={<SingleBusinessScreen />}></Route> */}
                <Route path="/app/single-business/businessproduct" element={<SingleProductViewBusiness />}></Route>
                <Route path="/app/view/offer" element={<OfferView />}></Route>
                <Route path="/app/my-business/stats" element={<BusinessScreen />}></Route>
                <Route path="/app/my-business/post-add" element={<PostAddScreen />}></Route>
                <Route path="/app/my-business/offers/create" element={<CreateOfferScreen />}></Route>
                {/* <Route path="/app/my-business/download" element={<DownloadScreen />}></Route> */}

                {/* Haqooq e Khalq worker routes */}
                {/* <Route path="/app/my-business/haqooqekhalq/worker" element={<HKWorker />}></Route> */}
              </Route>
              <Route path="/app/my-business/welcome" element={<MBWelcomeScreen />}></Route>
              <Route path="/app/my-business/login" element={<MBLogin />}></Route>
              <Route path="/app/my-business/signup" element={<MBSignup />}></Route>
            </Route>
            {/* My Business Provider Routes ends here */}
                <Route path="/hwm-logo" element={<HwmLogo />}></Route>
                <Route path="/app/hwm/authentication-form" element={<AuthenticationForm />}></Route>

            <Route element={<SingleBusinessContextProvider />}>
              <Route path="/app/order" element={<Orders />}></Route>
              <Route path="/app/golive" element={<GoLive />}></Route>
              <Route path="/app/subscription" element={<Subscription />}></Route>
              <Route path="/app/payment" element={<Payment />}></Route>
              <Route path="/app/menu" element={<Menu />}></Route>
              <Route path="/app/menuscreen" element={<MenuScreen />}></Route>
              <Route path="/app/checkout" element={<Checkout />}></Route>
              <Route path="/app/cart" element={<CartScreen1 />}></Route>
              <Route path="/app/offer/cart" element={<CartScreen1 />}></Route>
              <Route path="/app/single-business-old" element={<SingleBusinessScreen />}></Route>
              <Route path="/app/single-business" element={<SingleBusinessMain />}></Route>
              <Route path="/app/single-business/product" element={<SingleProductView />}></Route>
              <Route path="/app/single-business/item" element={<AddItem />}></Route>
              <Route path="/app/single-business/menu" element={<AddMenu />}></Route>
              <Route path="/app/single-business/offer-detail" element={<SingleOfferScreen />}></Route>
              <Route path="/messages/business/verify-redeem-offer/:messageId" element={<SingleDiscountScreen />}></Route>
              <Route path="/app/single-business/notification" element={<DiscountNotificationScreen />}></Route>

              {/* Haqooq e Khalq Routes */}
              <Route path="/app/single-business/hkp/worker" element={<HKWorker />}></Route>
              <Route path="/app/single-business/hkp/member" element={<HKMember />}></Route>

              {/* BAWA Organization Routes */}
              <Route path="/app/single-business/bawa/main" element={<BawaMain />}></Route>
              <Route path="/app/single-business/bawa/member" element={<BawaMember />}></Route>
              <Route path="/app/single-business/bawa/worker" element={<BawaWorker />}></Route>
            </Route>

            <Route path="/app/nearby" element={<NearByBusinesses />}></Route>
            {/* Haqooq e khalq member route */}
            <Route path="/app/business/haqooqekhalq/member" element={<HKMember />}></Route>
          </Route>
          <Route
            path="delete-my-account"
            element={
              <DeleteAccountProvider>
                <DeleteMyAccount />
              </DeleteAccountProvider>
            }
          ></Route>
          {/* <Route path="/app/my-business/qrcode" element={<QRScreen />}></Route> */}
          <Route path="/app/my-business/qrcode" element={<DownloadScreen />}></Route>
          <Route path="/app/get-nearby-businesses-of-offer" element={<OfferWebviewScreen />}></Route>
          <Route path="*" element={<NoMatch />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
