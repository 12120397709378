
import { Fragment, useContext } from "react";
import BankAccountDetail from "./BankAccountDetail.jsx";
import BillingUpload from "./BillingUpload.jsx";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import 'react-accessible-accordion/dist/fancy-example.css';
import { GlobalContext } from "../../../GlobalContext.jsx";

function BillingTabContent() {
    const globalCtx = useContext(GlobalContext)
    const bankAccounts = [
        {
            id: 1,
            country: 'PK',
            title: "NODE0 COMPUTING",
            accNo: "02570104987755",
            bankName: "MEEZAN BANK",
            branchCode: "0257 DHA Phase 5",
            iban: "PK51MEZN0002570104987755",
        },
        {
            id: 2,
            country: 'PK',
            title: "ONE CALL",
            bankName: "Sonehri Bank",
            branchCode: "DHA Phase 5",
            iban: "PK81SONE002522011678794",
        }
    ]
    const countryAccounts = [
        {
            country: 'PK',
            id: 'pk-easypaisa',
            label: 'EasyPaisa',
            details: {
                id: 3,
                title: "Gohar Sultan",
                accNo: "03414151343",
            }
        },
        {
            country: 'PK',
            id: 'pk-jazzcash',
            label: 'JazzCash',
            details: {
                id: 2,
                title: "Gohar Sultan",
                accNo: "03249565957",
            }
        }
    ]
    const activeCountryAccounts = globalCtx.config?.ipMeta?.country ? countryAccounts?.filter(item => item.country === globalCtx.config?.ipMeta?.country) : []
    const activeCountryBankAccounts = globalCtx.config?.ipMeta?.country ? bankAccounts?.filter(item => item.country === globalCtx.config?.ipMeta?.country) : []
    return (  
        <div className="flex flex-col gap-4 py-5 mx-4">
            <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['receipt-uploader']}>
                {activeCountryBankAccounts?.length ? 
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Bank Accounts
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            { activeCountryBankAccounts.map((item,index) => (
                                <div key={'bank-account-item-' + item.id}>
                                    <BankAccountDetail details={item} />
                                    <div className="w-full border-dashed border-t-[1px] my-2"></div>
                                </div>
                            )) }
                        </AccordionItemPanel>
                    </AccordionItem> 
                : <></>
            }
                { activeCountryAccounts?.length ? activeCountryAccounts.map((item,index) => (
                    <Fragment key={'country-account-' + index}>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {item.label}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <BankAccountDetail 
                                    details={item.details}
                                />      
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Fragment>
                )) : <></> }
                <AccordionItem uuid={"receipt-uploader"}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Upload your receipt
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <BillingUpload />
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
}

export default BillingTabContent;