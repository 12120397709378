import React, { useState } from 'react'
import MBUploadPhotos1 from './MBUploadPhotos1';

const Payment = () => {
    
     const [inputValue, setInputValue] = useState('');

  // State to manage the error message
  const [error, setError] = useState('');
  const [checkboxError, setCheckboxError] = useState('');
  // State to track whether the checkbox is checked
  const [isChecked, setIsChecked] = useState(false);


  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the input value is empty
    if (inputValue.trim() === '') {
      setError('Please fill the field');
    } else {
      // If not empty, you can perform further actions or submit the form
      // For now, let's just clear the error message
      setError('');
      // You can add more logic here for form submission or other actions
    }
  };

  const handleSubmit1 =(e) => {
    e.preventDefault();
     if (!isChecked) {
      setCheckboxError('To proceed further, accept terms and conditions');
    } else {
      setCheckboxError('');
    }

    // If both input and checkbox are valid, you can perform further actions or submit the form
    if (isChecked) {
      // You can add more logic here for form submission or other actions
      alert('Form submitted successfully!');
    }
  }

  // Function to handle input value change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    // Clear the error message when the user starts typing
    setError('');
  };

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    // Clear the checkbox error message when the checkbox is checked or unchecked
    setCheckboxError('');
  };

  return (
    <div className='text-[#464A52] mx-2'>
        <div className="flex flex-col items-center justify-center text-[#464A52] mx-2">
      <div className='font-bold text-[19px] mt-[50px]'>Payment</div>
        <div className='border-[1px] border-[#E8E8E9] w-full my-5'></div></div>
        <div className='text-[23px]'>How to pay ?</div>
        <div className='text-[14px] my-2'>
            To complete the payment for this subscription, kindly transfer funds to the provided account number and submit the receipt here for approval.
        </div>
        <div className='text-[16px] my-2'>
            Account Details
        </div>
        <div className='text-[13px]'>
            <div>Account Title : <span>NODEO COMPUTING</span></div>
            <div>Account Number : <span>02570104987755</span></div>
            <div>Bank Name : <span>MEEZAN BANK</span></div>
            <div>Branch Code : <span>0257 DHA Phase 5</span></div>
            <div>IBAN : <span>PK51MEZN0002570104987755</span></div>
        </div>
       
         <div>
             <MBUploadPhotos1
                    onChange={(files) => {
                      if (files?.length > 0) {
                        // compressImages(files);
                      }
                    }}
                  />
                   <div className='my-2 flex items-center justify-center text-[16px] font-semibold'>
                    Or
                   </div>
                   <form onSubmit={handleSubmit}>
                   <div className='my-2'>
                    <div className='text-[13px] font-semibold'>
                        Add promo code
                    </div>
                    <div className='flex flex-row w-full justify-between my-2'>
                        <div>
                         <input
          type="text"
          id="myInput"
          placeholder='e.g GTY5653'
          value={inputValue}
          onChange={handleInputChange}
          className='border-[1px] border-[#E8E8E9] p-2 w-[250px] rounded-md text-[14px]'
        />
        {error && <div style={{ color: 'red' }} className='text-[13px]'>{error}</div>}
        </div>
        <div>  <button
        type='submit'
            className="bg-[#E8E8E9] rounded-md p-2 mb-2 text-white font-normal text-[16px] w-[105px]"
            //  onClick={() => navigate("/app/payment")}
          >
            Apply
          </button></div>
                    </div>
                   </div>
                   </form>

          <form onSubmit={handleSubmit1}>
             <div>
        <input
          type="checkbox"
          id="termsCheckbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="termsCheckbox" className='text-[13px] ml-2'>By checking, you agree to our Terms and Conditions</label>
        {checkboxError && <div style={{ color: 'red' }} className='text-[13px]'>{checkboxError}</div>}
      </div>

       <button
        type='submit'
            className="bg-[#2196F3] rounded-md p-2 my-3 text-white font-normal text-[16px] w-full"
            //  onClick={() => navigate("/app/payment")}
          >
            Continue
          </button>
          </form>

         </div>
                   
                
        </div>
  )
}

export default Payment