import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingModal from "../../basic/LoadingModal.jsx";
import axios from "axios";
import { PiPhoneLight } from "react-icons/pi";
import { createActivityLog } from "../../../utils/helpers.js";
import FlutterInterface from "../../../utils/flutter_interface.js";

function SingleMessageNewCustomerScreen() {
  const { messageId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)

  useEffect(() => {
    createActivityLog('page_load_new_customer_message_webpage', null, {
      messageId
    })
    const url = new URL('https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/super-messages/get-message-detail')
    url.searchParams.set('super_message_id', messageId)
    axios.get(url).then(res => {
      if(res?.data?.status == 200) {
        setData(res?.data?.data)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const handleClickOnCallNow = phone => {
    createActivityLog('clicked_on_call_now_new_customer_message_webpage', null, {
      messageId,
    })
    // window.open(`tel:${phone}`)
    FlutterInterface.openDialer(phone)
  }

  return (  
    <>
      {<LoadingModal isOpen={isLoading} />}

      {
        data?.meta?.customer_name && <div className="px-4 my-4">
          <h1 className="font-medium text-[22px]">Customer Details</h1>
          <div className="bg-gray-50 p-2 rounded-md border-[1px] border-gray-200">
            <div className="flex justify-between items-center">
              <div className="">
                <p className="text-[12px] text-gray-500 font-medium">Name</p>
                <p className="font-medium">{data?.meta?.customer_name}</p>
              </div>
              <div className="border-[1px] rounded-full border-black p-2" onClick={e => handleClickOnCallNow(data?.meta?.customer_phone)}>
                <PiPhoneLight size="20px" />
              </div>
            </div>
          </div>
        </div>
      }

      {data?.query?._id && 
        <div className="px-4 my-4">
          <h1 className="font-medium text-[22px]">Your Offer Details</h1>
          <ul>
            {data?.query?.query_messages?.map((msg,index) => (
              <li key={'query-msg-index-' + index}>
                { msg.type === 'text' && <div className="my-2 bg-slate-100 mx-2 p-2 rounded-md border-slate-400">
                  <p>{msg.content}</p>
                </div> }
                { msg.type === 'image' && <div className="my-2 bg-slate-100 mx-2 p-2 rounded-md border-slate-400">
                  <div>
                    <img src={msg.content} alt="image" className="object-contain max-w-full max-h-[30vh]" />
                  </div>
                </div> }
              </li>
            ))}
          </ul>
        </div>
      }
    </>
  );
}

export default SingleMessageNewCustomerScreen;