// StripePromise.js
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_live_51L2OQLR6drCzi83bzhPqLK9EVUCLcszWtca2Efeo872X9zEZ26IB3KomjwEKuYmgfui4KDArhbDnDSHNpVfIUZGD00aJCz8xmt');
// pk_live_51L2OQLR6drCzi83bzhPqLK9EVUCLcszWtca2Efeo872X9zEZ26IB3KomjwEKuYmgfui4KDArhbDnDSHNpVfIUZGD00aJCz8xmt
// pk_test_51L2OQLR6drCzi83bjn9VjJvO5xiU9wwiyus4W3sUGeLrJKYMl42zvQRYQDkhNajYwrA3u9Lf9IR4s44oTjVLCNTd00Q64OVMtv
const StripePromise = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
      {/* <PaymentForm/> */}
      {/* <StaticPayment/> */}
    </Elements>
  );
};

export default StripePromise;
