import React, { useEffect, useState } from 'react';
import { AppImages } from '../../../../Asset/images/image';
import { LAMBDA_API_BUSINESS_PORTAL_URL } from '../contexts/MyBusinessContext';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

function Center({ children, height = 'h-screen' }) {
  return ( 
    <div className={`grid ${height} place-items-center`}>
      {children}
    </div>
  );
}

function Loading({ height = 'h-screen' }) {
  return (
    <Center height={height}>
      <div className='text-center'>
        <img src={AppImages.loading} className='w-[50px] block mx-auto my-0' alt="" />
      </div>
    </Center>
  )
}

const QRScreen = () => {
//   const ctx = useContext(MyBusinessContext);
  const [searchParams] = useSearchParams()
  const businessId = searchParams.get('business_id')
  const [isFetchingBusiness, setIsFetchingBusiness] = useState(false)
  const [isFetchingQrCode, setIsFetchingQrCode] = useState(false)
  const [qrCode, setQrCode] = useState(null)
  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  const [businessData, setBusinessData] = useState(null);
  // let businessId = "665597951f6269740032fc83"
  useEffect(() => {
    if(businessId) {
      setIsFetchingBusiness(true)
      axios.get(LAMBDA_API_BUSINESS_PORTAL_URL + "/app/my-business/get-business?business_id=" + businessId).then(res => {
        console.log(res?.data)
        if(res?.data?.status === 200 && res?.data?.data?.business) {
          const b = res?.data?.data?.business
          setBusinessData(b)
          setIsFetchingQrCode(true)
          const url = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${b?.marker?.dynamic_link}`
          fetch(url).then(res => res.blob()).then(res => blobToBase64(res)).then(res => setQrCode(res)).finally(() => setIsFetchingQrCode(false))
        }
      }).catch(e => {
        console.log(e)
      }).finally(() => {
        setIsFetchingBusiness(false)
      })
    }
  }, [businessId]);
console.log("business data", businessData);



const downloadImage = () => {
  // Get the element by its id or tag name
  const elementToCapture = document.getElementById('capture-element'); // Replace 'capture-element' with the actual id or tag name

  // Create a screenshot of the element using html2canvas
  html2canvas(elementToCapture, { useCORS: true, background: true }).then(function (canvas) {
      const imgData = canvas.toDataURL('image/jpeg');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'onecall_my_business.jpeg'; // You can change the filename and extension here
      link.click();
  });
};

const downloadPDF = () => {
  // Create a PDF of the element using jsPDF
  const elementToCapture = document.getElementById('capture-element'); // Replace 'capture-element' with the actual id or tag name

  html2canvas(elementToCapture, { useCORS: true, background: true }).then(function (canvas) {
      const imgData = canvas.toDataURL('image/png');

      // Calculate the dimensions of the PDF based on the element's dimensions
      const pdf = new jsPDF('p', 'px', [canvas.width, canvas.height]);

      // Add the image to the PDF
      pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);

      // Save the PDF
      pdf.save('onecall_my_business.pdf');
  });
};


  if(!businessId) {
    return (
      <Center>
        <div className='bg-red-200 p-3 px-4 rounded'>
          <p><span className='text-red-500 font-semibold'>Error:</span> <span>No business id is found</span></p>
        </div>
      </Center>
    )
  }

  if(isFetchingBusiness) {
    return (
      <Loading />
    )
  }

  return (
    <div className='bg-[#022f5c]'>
        <div className='bg-white'>
        <div className='text-center text-[22px] bg-white font-bold mt-5'>Download for Your Business</div>
        <div className='w-full flex flex-row justify-between bg-white px-2 py-2'>
            <button 
             className="rounded-md py-2  mb-2 text-[13px] border border-[#022f5c] w-[33%] text-black  hover:bg-[#022f5c] hover:text-white"
            onClick={downloadImage}> Download Image  </button>
             
            <div className='text-black font-semibold text-[20px]'>OR</div>
      <button 
        onClick={downloadPDF}
        className="rounded-md py-2  mb-2 text-[13px] border border-[#022f5c] w-[33%] text-black hover:bg-[#022f5c] hover:text-white"
      >
        Download PDF</button>
        </div>
        </div>
        <div id="capture-element" className='bg-[#022f5c]' >

        <div className=" pb-[40px] flex items-center justify-center flex-col bg-[#022f5c]" style={{ backgroundImage: `url(${businessData?.avatar_url})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
          <div className='bg-[#1e76b6] rounded-b-3xl mt-0 pt-0'>
          <div className='text-white text-center text-[50px] font-bold mt-2 pb-0 mb-0'>GET {businessData?.voucher_meta?.currency} {businessData?.voucher_meta?.amount}</div>
          <div  className='text-white text-center text-[25px] px-4 mb-1 '>DISCOUNT VOUCHER FREE ON PURCHASE OF 5000</div>
          </div>
          
          {/* <div className='bg-white px-4 text-black font-bold my-2 text-[22px]'>{businessData?.name}</div> */}
          <div className='px-2 text-black my-2 mx-2 bg-white flex items-center'>
            {/* <div className='font-bold text-[22px] pb-2'>{businessData?.name}</div> */}
          </div>
          {isFetchingQrCode ? <Loading height='h-[100px]' /> : qrCode != null ?  <img
            src={qrCode}
            alt=""
            srcSet=""
            className="w-[43%] my-3"
          /> : <></>}
          {/* <div>
            {!businessData?.marker?.serviceCodes.length ? (
              ''
            ) : (
              <div  className="flex flex-col my-1">
                <div  className="text-white text-[10px] text-left">Service Code </div>
                <div className="ml-2 text-white text-[18px] font-bold px-2 my-1">
                  {businessData?.marker?.serviceCodes[0] ?? '--'}
                </div>
              </div>
            )}
          </div> */}
          <div className='text-blue text-[13px] mb-4'>
            {!businessData?.marker?.slug || businessData?.marker?.slug === '' ? (
              ''
            ) : (
              <div className='rounded-full bg-white  px-4 font-bold text-center' >
                <p className='text-center' style={{ fontSize:'17px' }}>{businessData?.name}</p>
                <p style={{fontSize:'10px', paddingBottom:'10px'}}>www.onecallapp.com/{businessData?.marker?.slug}</p>
                
              </div>
            )}
          </div>
          <div>
            {/* <div  className='mt-3 text-white text-[10px]'>WHATS APP</div> */}
            <div className=' text-white text-[12px]'>001 (253) 205-0733</div>
            <div className=' text-white text-[10px] flex flex-row'>Whatsapp Service Code To Download<div className=' ml-5 text-white text-[10px]'>www.onecallapp.com/bussiness</div></div>
          </div>
        </div>
        <div style={{ borderBottom: '1px solid #000' }}>
        <img  
            src={AppImages.qrfooter}
            alt=""
            srcSet=""
            className="w-[100%]"
          />
          </div>
      </div>
    </div>
  );
};

export default QRScreen;
