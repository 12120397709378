import { useContext, useMemo } from "react";
import { AppImages } from "../../../../Asset/images/image.js";
import Iframe from "../../../basic/Iframe.jsx";
import { GlobalContext } from "../../../GlobalContext.jsx";

function AssistanceTabContent() {
  const globalCtx = useContext(GlobalContext)
  const videos = [
    // {
    //   id: 1,
    //   title: "How to use One Call App?",
    //   url: "https://www.youtube.com/embed/YuvbIygReY8",
    //   language: 'ur'
    // },
    // {
    //   id: 2,
    //   title: "How to Create User Profile?",
    //   url: "https://www.youtube.com/embed/Sz_KE_3h6x4",
    //   language: 'ur'
    // },
    // {
    //   id: 3,
    //   title: "How to Create Business Profile?",
    //   url: "https://www.youtube.com/embed/dZVvz_mX_Ro",
    //   language: 'ur'
    // },
    // {
    //   id: 4,
    //   title: "How to Broadcast Your Query?",
    //   url: "https://www.youtube.com/embed/APZAlzlEwcU"
    // },
    // {
    //   id: 5,
    //   title: "How to find businesses near you?",
    //   url: "https://www.youtube.com/embed/nN490wia4v0"
    // },
  ]
  const helplines = useMemo(() => [
    {
      country: 'PK',
      phone: '+92 (304) 1110 201'
    },
    {
      country: '*',
      phone: '+1 (253) 205-0733'
    }
  ], [])
  const activeHelpline = useMemo(() => globalCtx.ipMeta?.country ? helplines.find(i => i.country === globalCtx.ipMeta?.country) : helplines.find(i => i.country === '*'), [globalCtx, helplines])
  
  return (
    <div>
      <div className="flex flex-col gap-4">
        {/* <Iframe videoUrl={"https://be.onecallapp.com/apps/onecall/tpl/help/assets/images/onecall.mp4"} /> */}
        <Iframe
          videoUrl="https://www.youtube.com/embed/VRndr9KrvEU" 
          props={{
            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
            // allowfullscreen: 'allowFullScreen'
          }}
        />
        <div className="flex flex-col px-10">
          <div className="flex flex-col">
            <span className="text-sm">For Assitance</span>
            <span className="text-[12px]">Contact your regional representative for further assistance</span>
          </div>
          <div className="flex gap-2 mx-auto pt-2">
            <div className="rounded-full border border-[#505050] w-[34px] h-[34px] items-center justify-center flex">
              <img src={AppImages.phone2} alt="phone" />
            </div>
            <div className="flex flex-col ">
              <span className="text-sm">One Call Official</span>
              <span className="text-[13px]">{activeHelpline?.phone}</span>
            </div>
          </div>
        </div>
        <hr className="mx-5 bg-[#707070]" />
        {videos.length ? videos.map((video, index) => (
          <div key={index}>
            <div>
              <span className="px-5 text-[15px]">{video.title}</span>
              <Iframe 
                videoUrl={video.url} 
                props={{
                  allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                }}
              />
            </div>
            <hr className="mx-5 bg-[#707070]" />
          </div>
        )) : <></>}
      </div>
    </div>
  );
}

export default AssistanceTabContent;