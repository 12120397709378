import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { titleCase } from "../../../../utils/helpers.js";
import { convertMongoDateToReadable } from "../../../../utils/helpers.js";
import { PiArrowLeftBold, PiDotsThreeVerticalBold, PiTrashBold, } from "react-icons/pi";
import SimpleModal from "../../../basic/SimpleModal.jsx";
import { Button } from "@mui/material";
import axios from "axios";
import { LAMBDA_API_BUSINESS_PORTAL_URL, MyBusinessContext } from "../contexts/MyBusinessContext.jsx";
import LoadingModal from "../../../basic/LoadingModal.jsx"
import FlutterInterface from "../../../../utils/flutter_interface.js";

const OfferView = () => {
  const navigate = useNavigate()
  const ctx = useContext(MyBusinessContext);
  const location = useLocation();
  const { item } = location.state || {};
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [modal, setModal] = useState({
    isOpen: false,
    content: null
  })
  const [isLoading, setIsLoading] = useState(false)
  const divRef = useRef(null);

  const onClickBackBtn = e => {
    navigate(-1)
  }
  const onClickMenuBtn = e => {
    setIsMenuOpen(true)
  }
  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsMenuOpen(false)
    }
  };
  const onClickDeleteOffer = e => {
    setIsMenuOpen(false)
    setModal({isOpen: true,})
  }
  const onClickDeleteOfferYes = e => {
    setModal({isOpen: false})
    setIsLoading(true)
    axios.post(`${LAMBDA_API_BUSINESS_PORTAL_URL}/app/my-business/delete-offer`, {
      business_id : ctx.business?._id,
      offer_id : item._id.toString()
    }).then((res) => {
      
    }).finally(() => {
      FlutterInterface.showToast('Offer is deleted')
      setIsLoading(false)
      navigate(-1)
    })
  }
  const onClickDeleteOfferNo = e => {
    setModal({isOpen: false, content: null})
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    }); 
  };

  useEffect(() => {
    scrollToTop()
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  if (!item) {
    return <div>Item not found</div>;
  }
  
  return (
    <div>
      <div className="flex flex-col  w-[full] ">
        <div className="px-2 py-2">
          <div className="flex justify-between items-center">
            <div className="w-[40px] h-[40px] relative" onClick={onClickBackBtn}>
              <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                <PiArrowLeftBold />
              </div>
            </div>
            <div className="w-[40px] h-[40px] relative" onClick={onClickMenuBtn}>
              <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                <PiDotsThreeVerticalBold size={"25px"} />
              </div>
              <div className={"absolute bottom-[-30px] right-[20px] bg-white shadow-lg border-[1px] border-slate-[100] w-[50vw] rounded-md p-2 " + (isMenuOpen ? "" : "hidden")} ref={divRef}>
                <ul>
                  <li>
                    <div className="flex items-center gap-2" onClick={onClickDeleteOffer}>
                      <div><PiTrashBold color="red" /></div>
                      <p className="font-medium">Delete Offer</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full">
          {item?.query_messages.find(itemm => itemm.type === 'image') ? (
            <img
              src= {item.query_messages.find(itemm => itemm.type === 'image').content}
              alt="offer"
              className=" mx-auto object-contain shadow-md max-w-[100vw] max-h-[60vh]"
            />
          ) :<></>}
        </div>

        <div className="my-4 mx-2">
          {item?.query_messages.find(itemm => itemm.type === 'text') ? (
            <div className=" text-[15px] font-medium pb-2 pl-2 text-[#464A52]">
              {item.query_messages.filter(itemm => itemm.type === 'text').map(j => j.content?.toString().trim()).map((j,index) => (
                <p key={'msg-text-'+index} className="text-[18px] font-medium">{j}</p>
              ))}
            </div>
          ) : <></>}
        </div>

        <div className="mx-4 my-2">
          <div className="flex flex-wrap justify-between items-center">
            {item.category?.toString().length ? <div>
              <p className="text-[13px] text-gray-500 font-medium">Category</p>
              <p>{titleCase(item.category?.toString().trim())}</p>
            </div> : <></>}
            {item.createdAt?.toString().length ? <div>
              <p className="text-[13px] text-gray-500 font-medium">Published on</p>
              <p>{convertMongoDateToReadable(item?.createdAt, { skipTime: true })}</p>
            </div> : <></>}
          </div>
        </div>

        {item?.stats?.table?.length ? <div className="mx-2 my-4">
          <p className="font-semibold text-[18px] px-2">Offer Stats</p>
          <>
            <div className="mb-6">
              {item?.stats?.table.map((stat,index) => (
                <div key={"stats-table-item-" + index} className="flex items-center justify-between my-2 border-b-[1px] border-gray-200 py-2 px-2">
                  <div className="font-medium">{stat.title}</div>
                  <div className="text-[20px]">{stat.value}</div>
                </div>
              ))}
            </div>
          </>
        </div> : <></>}
        <SimpleModal isOpen={modal.isOpen}>
          <div>
            <p className="font-medium">Are you sure you want to delete this offer?</p>
            <div className="flex items-center justify-end mt-4">
              <Button onClick={onClickDeleteOfferYes}>Yes</Button>
              <Button onClick={onClickDeleteOfferNo}>No</Button>
            </div>
          </div>
        </SimpleModal>
        <LoadingModal isOpen={isLoading}></LoadingModal>
      </div>
    </div>
  );
};

export default OfferView;
