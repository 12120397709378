// CheckoutForm.js
import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from "react-toastify";
import AppToastContainer from "../../../basic/AppToast.jsx";
import axios from 'axios';
import { AppImages } from '../../../../Asset/images/image';
import ReactModal from 'react-modal';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [paymentError, setPaymentError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const Plans = [
    { name: 'listing', package:'7$' },
    { name: 'business', package:'34$' },
    { name: 'active', package:'76$' },
    { name: 'partner', package:'447$'},
  ];
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [State, setState] = useState(1);

  const handlePlanChange = (e) => {
    setSelectedPlan(e.target.value);
  };

  const handleContinuePayment = () => {
    // Perform any logic you need when the user continues with payment
    console.log('Selected Plan:', selectedPlan);
    setState(2)
  };

  const [formFields, setFormFields] = useState({
    name: '',
    email: '',
    // password: '',
    phone: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    // password: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const userId = '6414624dd37934518eefc4c1';

    if (!stripe || !elements) {
      console.log('2')
      return;
    }

    console.log('3')

    // Validate form fields
    const newErrors = {};
    Object.keys(formFields).forEach((key) => {
      if (!formFields[key].trim().length) {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {

    //   // Perform form submission logic here
    //   console.log('Form submitted:', formFields);
    setIsLoading(true);
    let customerApiRes = await axios.post('https://7b7xlap5jvkahyo5himfrzqy640qnadr.lambda-url.eu-west-1.on.aws/stripe/create-customer', {
      name: formFields.name,
      email: formFields.email,
      phone: formFields.phone,
      user_id: userId
    })
      .catch((e) => {
        console.log(e);
        if (e?.error) {
          toast.error(
            e?.isOperationalError ? "Something went wrong" : e?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
    if(customerApiRes?.data?.status === 200 && customerApiRes?.data?.data?.customer_id) {
      const cardElement = elements.getElement(CardElement);
      const { error } = await stripe.createToken(cardElement);
      if(error) {
        setPaymentError(error)
        setIsLoading(false);
        toast.error(error);
      } else {
        const paymentMethodResult = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        })
        if(paymentMethodResult?.error) {
          setIsLoading(false);
          setPaymentError(error)
          toast.error(error);
        } else {
           let attachPMApiRes = await axios.post('https://7b7xlap5jvkahyo5himfrzqy640qnadr.lambda-url.eu-west-1.on.aws/stripe/attach-payment-method', {
            user_id: userId,
            payment_method_id: paymentMethodResult.paymentMethod?.id
          }).catch(e => {
              if (e?.error) {
          toast.error(
            e?.isOperationalError ? "Something went wrong" : e?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
          })
           console.log("🚀 ~ file: CheckoutForm.jsx:103 ~ handleSubmit ~ attachPMApiRes:", attachPMApiRes)
        if(attachPMApiRes.data.status !== 200){
          toast.error(attachPMApiRes.data.message);
          setIsLoading(false);
        } else{
            let intentApiRes = await axios.post(`https://7b7xlap5jvkahyo5himfrzqy640qnadr.lambda-url.eu-west-1.on.aws/stripe/create-payment-intent`, {
            package_name: selectedPlan,
            user_id: userId,
            payment_method_id: paymentMethodResult.paymentMethod?.id
          })
           .catch((e) => {
        console.log(e);
        if (e?.error) {
          toast.error(
            e?.isOperationalError ? "Something went wrong" : e?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
          console.log("🚀 ~ file: CheckoutForm.jsx:107 ~ handleSubmit ~ intentApiRes:", intentApiRes)
          setIsLoading(false);
          if(intentApiRes.data.status === 200){
            setShowSuccessModal(true);
          }
          else{
            toast.error(intentApiRes.data.message);
            setIsLoading(false);
          }
        }
         
        }
      }

    }
    else{
      toast.error(customerApiRes.data.message)
      setIsLoading(false);
    }
    
    }

  };

  return (
    <div className="max-w-md mx-auto mt-8 p-4  rounded-md">
      <AppToastContainer />
      {
            State === 1 ? (
               <form>
        <div className="mb-4">
          <label className="block text-[22px] font-bold text-center text-gray-600">Select a Plan</label>
          <div className="mt-2">
            {Plans.map((plan) => (
              <div key={plan.name} className='my-2 bg-gray-100 rounded-md p-2'>
               
                {/* <label htmlFor={plan.name} className="text-gray-700"> */}
                  <div className='flex flex-row justify-between'>
                    <div className='flex flex-row'>
                         <input
                  type="radio"
                  id={plan.name}
                  name="plan"
                  value={plan.name}
                  checked={selectedPlan === plan.name}
                  onChange={handlePlanChange}
                  className="mr-2"
                />
                        <div className='text-[19px] font-semibold'>{plan.name.charAt(0).toUpperCase() + plan.name.slice(1)}</div></div>
                    <div className='text-[19px] font-semibold'>{plan.package}</div>
                    </div>
                {/* </label> */}
              </div>
            ))}
          </div>
        </div>

        <button
          type="button"
          onClick={handleContinuePayment}
          className={`bg-blue text-white py-2 px-4 rounded-md ${
            selectedPlan ? 'hover:bg-blue-600' : 'cursor-not-allowed opacity-50'
          }`}
          disabled={!selectedPlan}
        >
          Continue Payment
        </button>
      </form>
            ) :(
      <form onSubmit={handleSubmit}>
         <div className='text-center text-gray-600 font-bold text-[22px]'>Create An Account</div>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-600">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formFields.name}
            onChange={handleChange}
            className={`mt-1 p-2 w-full border ${
              errors.name ? 'border-red-500' : 'border-gray-300'
            } rounded-md`}
          />
          {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-600">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formFields.email}
            onChange={handleChange}
            className={`mt-1 p-2 w-full border ${
              errors.email ? 'border-red-500' : 'border-gray-300'
            } rounded-md`}
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </div>

        {/* <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-600">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formFields.password}
            onChange={handleChange}
            className={`mt-1 p-2 w-full border ${
              errors.password ? 'border-red-500' : 'border-gray-300'
            } rounded-md`}
          />
          {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
        </div> */}

        <div className="mb-4">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-600">
            Phone
          </label>
          <input
            type="number"
            id="phone"
            name="phone"
            value={formFields.phone}
            onChange={handleChange}
            className={`mt-1 p-2 w-full border ${
              errors.phone ? 'border-red-500' : 'border-gray-300'
            } rounded-md`}
          />
          {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
        </div>
         
         <div className="mb-4">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-600">
            Card Details
          </label>
        <CardElement className='mt-1 p-2 text-[18px] w-full bg-gray-300 rounded-md'/>
        {paymentError && <div className="text-red-500 text-sm mt-1">{paymentError}</div>}
        </div>
         <button
                className="rounded-md py-2 mb-5 text-[15px] mt-4 w-full bg-[#009bfb] hover:bg-lightBlue-600 text-white disabled:bg-slate-200"
                disabled={isLoading}
              >
                {isLoading ? (
                  <img src={AppImages.loading} className="w-[20px] mx-auto" alt="" />
                ) : (
                  <span>Create Account</span>
                )}
              </button>

        {/* <button
          type="submit"
          className="bg-blue text-white py-2 px-4 rounded-md hover:bg-blue-600 mt-3"
        >
          
        </button> */}
      </form>
      )
      }
       {showSuccessModal && (
          <ReactModal
            isOpen={true}
            className="Modal Success-Modal"
            overlayClassName="Modal-Overlay"
          >
            <div className="p-4">
              <div className="flex items-center justify-center">
                <img
                  src={AppImages.checkmark}
                  alt="checkmark"
                  className="w-[60px] svg-green"
                />
              </div>
              <p className="text-center my-4 text-green-600 font-medium">
                Your Payment has been done successfully!
              </p>
              <div
                className="text-center p-2 cursor-pointer"
                onClick={(e) => {
                  navigate("/onecall/web/help");
                  // window.location.reload();
                }}
              >
                <p className=" font-bold text-[14px] text-green-600 border-dotted border-b-[1px] inline">
                  OK
                </p>
              </div>
            </div>
          </ReactModal>
        )}
    </div>
    // <form onSubmit={handleSubmit}>
    //   <label>
    //     Card details
    //     <CardElement />
    //   </label>
    //   {paymentError && <div style={{ color: 'red' }}>{paymentError}</div>}
    //   <button type="submit" disabled={!stripe}>
    //     Pay
    //   </button>
    // </form>
  );
};

export default CheckoutForm;
