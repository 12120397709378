import { useContext, useState } from "react";
import { MdOutlinePhone } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import "react-tabs/style/react-tabs.css";
import { Link, useNavigate } from "react-router-dom";
import AppToastContainer from "../../../../basic/AppToast.jsx";
import Loading from "../../../../basic/Loading.jsx";
import { SingleBusinessContext } from "../contexts/SingleBusinessContext.jsx";
import { formatNumber } from "../../../../../utils/helpers.js";
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { FaAngleDown } from "react-icons/fa";
import { FaShippingFast } from "react-icons/fa";
import { AppImages } from "../../../../../Asset/images/image.js";
function CartScreen1() {
  const navigate = useNavigate();
  const dataa = useContext(SingleBusinessContext);
  const [data, setData] = useState(dataa.cart.items);
  const [isFetchingStats] = useState(false);
  const totalAmount = data?.reduce((total, item) => {
    if (item.discounted_price?.amount) {
      return total + item.discounted_price.amount
    }
    return total + item?.price?.amount;
  }, 0);

  const handleDeleteItem = (itemId) => {
    const updatedData = data.filter((item) => item.id !== itemId);
    setData(updatedData);
    dataa.removeItemFromCart(itemId);
  };
  console.log("data", data);
  function formatProductPrice(product) {
    let price = <div><b>{product?.price?.currency} {formatNumber(product?.price?.amount)}</b></div>
    if (product?.discounted_price) {
      price = <div><span>{product?.discounted_price?.currency} {formatNumber(product?.discounted_price?.amount)}</span></div>
    }
    return price
  }


  const [selectedOption, setSelectedOption] = useState('Dine-In'); // State to track selected option

  // Function to handle click on an option
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };


  // return (
  // <>
  //   <AppToastContainer />
  //   <div className="p-4">
  //     <div>
  //       {/* {
  //         dataa?.marker ? (  */}
  //       <div>
  //         <div className="flex items-center">
  //           <div className="font-semibold text-lg">{dataa?.marker?.name}</div>
  //         </div>
  //         {/* <div className="flex items-center">
  //           <div className="font-semibold text-lg">{dataa?.marker_id}</div>
  //         </div> */}

  //         <div className="flex items-start my-1">
  //           <div className="flex-[0_0_10%]  my-1">
  //             <MdOutlinePhone />
  //           </div>
  //           <div>{dataa?.marker?.phone ?? "--"}</div>
  //         </div>

  //         {/* <div className="flex items-start my-1">
  //           <div className="flex-[0_0_10%] my-1">
  //             <AiOutlineClockCircle />
  //           </div>
  //           <div>Delivery 35 min</div>
  //         </div> */}
  //       </div>
  //       {/* ):(<></>)
  //       } */}

  //       <div className="border-b-[1px] border-slate-200 my-4"></div>

  //       <div>
  //         <div>
  //           {isFetchingStats ? (
  //             <div>
  //               <Loading />
  //             </div>
  //           ) : (
  //             <div>
  //               {data?.map((item, index) => (
  //                 <div key={index}>
  //                   {/* {console.log("Item", item)} */}
  //                   {/* Add this line for debugging */}

  //                   <div className="h-[48px] flex flex-row mx-2 my-3">
  //                     <div className="w-[15%] my-2">
  //                       <select
  //                         className=""
  //                         value={item.quantity}
  //                         onChange={(e) =>
  //                           dataa.updateCartItemQuantity(
  //                             item.id,
  //                             parseInt(e.target.value)
  //                           )
  //                         }
  //                       >
  //                         {Array.from({ length: 50 }, (_, i) => i + 1).map(
  //                           (value) => (
  //                             <option key={value} value={value}>
  //                               {value}
  //                             </option>
  //                           )
  //                         )}
  //                       </select>
  //                     </div>

  //                     <div className="w-[25%] h-[48px] rounded-md">
  //                       {item.product.images?.length > 0 ? (
  //                         <img
  //                           src={item.product.images[0]}
  //                           alt=""
  //                           className="rounded-md w-full h-full"
  //                         />
  //                       ) : (
  //                         <div className="bg-slate-100 rounded-lg text-[22px] font-semibold w-full h-full flex items-center justify-center">
  //                           {item?.product?.name.slice(0, 2)}
  //                         </div>
  //                       )}
  //                     </div>
  //                     <div className=" w-[30%] my-2">
  //                       <div className="text-[14px] pl-[20px]">
  //                         {item.product?.name}
  //                       </div>
  //                     </div>
  //                     <div className="w-[15%] my-2">
  //                       <RiDeleteBin5Line
  //                         className="text-[18px]"
  //                         onClick={() => handleDeleteItem(item.id)}
  //                       />
  //                     </div>
  //                     <div className="w-[15%] my-2">
  //                       <div className="text-[13px]">
  //                         {formatProductPrice(item)}
  //                         {/* Rs.{item.price.amount} */}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               ))}
  //               <div className="flex flex-row justify-between mx-2 font-bold my-2">
  //                 <div>Total Amount:</div>
  //                 <div> Rs.{totalAmount}</div>
  //               </div>
  //               {!dataa?.cart.items.length ? (
  //                 <div className="flex items-center justify-center flex-col mt-[20%]">
  //                   <div className="text-blue font-semibold text-[18px]">
  //                     There are no items in this cart
  //                   </div>
  //                   <div
  //                     className="text-center p-2 cursor-pointer mt-[50px] border border-orange-600"
  //                     onClick={(e) => {
  //                       // setShowSuccessModal(false);
  //                       navigate(
  //                         `/app/single-business?marker_id=${dataa?.marker_id}`
  //                       );
  //                       // window.location reload();
  //                     }}
  //                   >
  //                     <p className="font-bold text-[14px] text-orange-600 border-dotted border-b-[1px] inline">
  //                       Continue Order
  //                     </p>
  //                   </div>
  //                 </div>
  //               ) : (
  //                 <div className="flex items-center justify-center ">
  //                   <div>
  //                     <Link to={`/app/checkout`}>
  //                       <button
  //                         className="w-full items-center h-[35px] my-2 px-2 text-[13px] rounded-md bg-[#2196F3] hover:bg-[#abd3f4] text-white"
  //                         // onClick={handleCheckout}
  //                       >
  //                         Confirm payment and address
  //                       </button>
  //                     </Link>
  //                   </div>
  //                 </div>
  //               )}
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </>
  // );

  return (
    <div>
      <>
        <AppToastContainer />
        <div className="bg-gray-100 py-3 px-5 flex flex-row justify-between">
          <div>
            <div>CART</div>
            <p className="text-[12px] flex flex-row"><AiOutlineShoppingCart className="mt-1" />&nbsp;<span>Shopping Cart - {data?.length} Items Added (${totalAmount})</span></p>
          </div>
          <div></div>
          <div className="mt-4"><FaAngleDown /></div>
        </div>
        <div className="p-4">
          <div>
            <div>
              <div className="flex items-start my-1 border-[1px] border-slate-200 rounded-md">
                <div style={{ fontSize: '2em', marginTop: '8px', marginRight: '10px', marginLeft: '10px' }}><FaShippingFast /></div>
                <div>
                  <p className="text-[12px] pt-2">Estimated Delivery</p>
                  <p className="text-[12px] pb-2 text-bold" style={{ fontWeight: '600' }}>Now (35 min)</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                {isFetchingStats ? (
                  <div>
                    <Loading />
                  </div>
                ) : (
                  <div>
                    {data?.map((item, index) => (
                      <div key={index}>

                        <div className="h-[48px] flex flex-row mx-2 my-3">
                          <div className="my-2 border p-1">
                            <select
                              className=""
                              value={item?.quantity}
                              onChange={(e) =>
                                dataa.updateCartItemQuantity(
                                  item?.id,
                                  parseInt(e.target.value)
                                )
                              }
                            >
                              {Array.from({ length: 50 }, (_, i) => i + 1).map(
                                (value) => (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <div className=" w-[70%] my-2 font-bold">
                            <div className="text-[14px] pl-[20px]">
                              {item.product?.name}
                              <p className="text-[10px]">{item?.product?.description}</p>
                            </div>
                          </div>
                          <div className="w-[15%] my-2">
                            <div className="text-[13px]">
                              {formatProductPrice(item)}
                              {/* Rs.{item.price.amount} //this line was commented */}
                            </div>
                          </div>
                        </div>
                        {item?.product.images?.length > 0 ? (
                          <img
                            src={item?.product.images[0]}
                            alt=""
                            className="rounded-md w-full h-full"
                          />
                        ) : (
                          <div className="bg-slate-100 rounded-lg text-[22px] font-semibold w-full h-full flex items-center justify-center">
                            {item?.product?.name.slice(0, 2)}
                          </div>
                        )}
                        <div className="border-b-[1px] border-slate-200 my-4"></div>
                    <div>
                      <p>POPULAR WITH YOUR ORDER</p>
                      <div className="flex flex-row justify-between">
                        <div className="rounded-md"><img src={AppImages.drink} alt="" style={{ width: '90px', height: '90px', objectFit: 'cover', borderRadius: '15px' }} /></div>
                        <div className="rounded-md"><img src={AppImages.drink} alt="" style={{ width: '90px', height: '90px', objectFit: 'cover', borderRadius: '15px' }} /></div>
                        <div className="rounded-md"><img src={AppImages.drink} alt="" style={{ width: '90px', height: '90px', objectFit: 'cover', borderRadius: '15px' }} /></div>
                      </div>
                      <div className="border-b-[1px] border-slate-200 my-4"></div>
                    </div>

                    <div>
                      <div className="flex flex-row justify-between">
                        <div>
                          <div className="font-bold">TOTAL</div>
                          <p className="text-[14px]">See price breakdown</p>
                        </div>
                        <div style={{ fontWeight: '600', fontSize: '19px' }}>
                          {totalAmount}
                        </div>
                      </div>

                      <div className="flex flex-row justify-between">
                        <div
                          className={`border rounded-full px-2 text-[13px] ${selectedOption === 'Dine-In' ? 'bg-gray-300' : ''}`}
                          onClick={() => handleOptionClick('Dine-In')}
                        >
                          Dine-In Restaurant
                        </div>
                        <div
                          className={`border rounded-full px-2 text-[13px] ${selectedOption === 'Take Away' ? 'bg-gray-300' : ''}`}
                          onClick={() => handleOptionClick('Take Away')}
                        >
                          Take Away
                        </div>
                        <div
                          className={`border rounded-full px-2 text-[13px] ${selectedOption === 'Delivery' ? 'bg-gray-300' : ''}`}
                          onClick={() => handleOptionClick('Delivery')}
                        >
                          Delivery
                        </div>
                      </div>
                      <div className="border-b-[1px] border-slate-200 my-4"></div>
                    </div>
                    <div>
                      <div className="flex items-center justify-center ">
                        <div className="mr-3">
                          <Link to={`/app/checkout`}>
                            <button
                              className="w-full items-center h-[35px] my-2 px-6 text-[13px] rounded-md bg-[#2196F3] hover:bg-[#abd3f4] text-white"
                            // onClick={handleCheckout}
                            >
                              ADD MORE
                            </button>
                          </Link>
                        </div>
                        <div>
                          <Link to={`/app/checkout`}>
                            <button
                              className="w-full items-center h-[35px] my-2 px-6 text-[13px] rounded-md bg-[#09bd30] hover:bg-[#abd3f4] text-white"
                            // onClick={handleCheckout}
                            >
                              CONFIRM
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                      </div>
                    ))}

                    
                    {!dataa?.cart.items.length ? (
                      <div className="flex items-center justify-center flex-col mt-[20%]">
                        <div className="text-blue font-semibold text-[18px]">
                          There are no items in this cart
                        </div>
                        <div
                          className="text-center p-2 cursor-pointer mt-[50px] border border-orange-600"
                          onClick={(e) => {
                            // setShowSuccessModal(false);
                            navigate(
                              `/app/single-business?marker_id=${dataa?.marker_id}`
                            );
                            // window.location reload();
                          }}
                        >
                          <p className="font-bold text-[14px] text-orange-600 border-dotted border-b-[1px] inline">
                            Continue Order
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center ">
                        <div>
                          <Link to={`/app/checkout`}>
                            <button
                              className="w-full items-center h-[35px] my-2 px-2 text-[13px] rounded-md bg-[#2196F3] hover:bg-[#abd3f4] text-white"
                            // onClick={handleCheckout}
                            >
                              CONFIRM
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default CartScreen1;
