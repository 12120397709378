import React, { useContext, useEffect } from 'react';
import { PiCaretRight, PiMapPin, PiPhone } from "react-icons/pi"
import { useState } from 'react';
import { SingleBusinessContext } from './contexts/SingleBusinessContext.jsx';
import FlutterInterface from '../../../../utils/flutter_interface.js';
import { calculateDistance } from '../../../../utils/helpers.js';
import axios from 'axios';
import { LAMBDA_API_BUSINESS_PORTAL_URL } from '../contexts/MyBusinessContext.jsx';
import { useNavigate } from 'react-router-dom';
import HKMainScreen from '../HaqooqeKhalaq/HKMainScreen.jsx';
import Loading from "../../../../component/basic/Loading.jsx"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function SingleBusinessScreen2(){
  const navigate = useNavigate()
  const ctx = useContext(SingleBusinessContext);
  const data = ctx?.marker 
  const [activeTab, setActiveTab] = useState('offers');
  const [distance, setDistance] = useState(null)
  const [isOfferLoading, setIsOfferLoading] = useState(true)
  const [offers, setOffers] = useState(null)
  
  const isHaqooqeKhalq = ctx.marker_id === '65e9d59a07346e96241b4c16'

  const handleTabClick = (tab) => {
    setActiveTab(tab); 
  };
  const onLocationBtnClick = e => {
    let location = data?.location?.coordinates?.length === 2 ? ({lat: data?.location?.coordinates[1], lng: data?.location?.coordinates[0]}) : null 
    if(location) {
      FlutterInterface.createActivityLog('tap_on_business_location', data?._id.toString())
      FlutterInterface.openGoogleMap(location.lat, location.lng)
    }
  }
  const onPhoneBtnClick = e => {
    let phone = data?.phone?.length && data?.phone?.toString().replace(/[^0-9+ ]/gi, "").length ? data?.phone : null 
    if(phone) {
      FlutterInterface.createActivityLog('tap_on_business_phone', data?._id.toString())
      FlutterInterface.openDialer(phone)
    }
  }
  const onClickOfferCard = offer => {
    FlutterInterface.createActivityLog('tap_offer_card', offer._id.toString())
    navigate('/app/single-business/offer-detail', { state: { offer } })
  }
  useEffect(() => {
    if(data?.distance && data?.distanceUnit) {
      setDistance(`${data.distance} ${data.distanceUnit}`)
    } else if(data?.location?.coordinates?.length === 2) {
      FlutterInterface.getLocation().then(res => {
        if(res?.lat && res?.lng) {
          setDistance(calculateDistance(res?.lat, res?.lng, data?.location?.coordinates[1], data?.location?.coordinates[0]) + " KM")
        }
      })
    }
  }, [data?.location, data?.distance, data?.distanceUnit])
  useEffect(() => {
    if(data?._id) {
      setIsOfferLoading(true)
      axios.get(
        `${LAMBDA_API_BUSINESS_PORTAL_URL}/app/single-business/get-offers?marker_id=${data?._id.toString()}`
      ).then(res => {
        if(res?.data?.status === 200) {
          setOffers(res?.data?.data?.offers ?? [])
        }
      }).finally(() => {
        setIsOfferLoading(false)
      })
    }
  }, [data?._id])
  console.log("offersss", offers)
  if(isHaqooqeKhalq) {
    return <HKMainScreen />
  }
  
  return(
    <>
    <div className="text-gray-800">
      <div className={`pb-4 pt-4 container`}>
        <div className="flex flex-row justify-between items-center ">
          <p className="text-xs mx-6 font-medium">
            <strong className="text-[24px]">{data?.name}</strong>
            <p className='text-[12px]'>{data?.address}</p>
          </p>
          <div className="border border-gray-500 rounded-full mr-6" onClick={onLocationBtnClick}>
            <PiMapPin class="my-3 text-gray-500 mx-3" size={25} />
          </div>
        </div>
        <div className="flex justify-between items-start mt-4 mx-6">
          {data?.serviceCodes?.length &&
            <div className="font-light mr-4 ">
              <p className="text-[18px] font-medium">{data?.serviceCodes[0]}</p>
              <p className="text-[12px]">SERVICE CODE</p>
            </div>
          }
          {distance && <p className="text-[22px] font-semibold">{distance}</p>}
          {(data?.phone?.length && data?.phone !== 'NA') && (<div className="ml-auto border border-gray-500 rounded-full div-3" onClick={onPhoneBtnClick}>
            <PiPhone className="  text-gray-500 m-3" size={25} />
          </div>)}
        </div>
        {data?.photo_urls?.length && 
          <div className="mt-4">
            <img
              src={data?.photo_urls}
              alt=""
              className="block w-full h-24 object-cover"
            />
          </div>
        }
        <div>
          <div className="flex flex-row pl-6 mb-4">
            <p
              className={`text-[28px] mt-5 pr-4 cursor-pointer ${
                activeTab === "offers" ? "font-medium text-black" : "text-gray-400 font-light"
              }`}
              onClick={() => handleTabClick("offers")}
            >
              Offers
            </p>
            {/* <p
              className={`text-[28px] mt-5 pl-4 cursor-pointer ${
                activeTab === "products" ? "font-medium text-black" : "text-gray-400 font-light"
              }`}
              onClick={() => handleTabClick("products")}
            >
              Products
            </p> */}
          </div>
          
          {/* Offers content */}
          <div className={activeTab === 'offers' ? "" : "hidden"}>
            { !isOfferLoading && !offers?.length && <div className='text-center mx-2 my-12 text-gray-500'>
              <p>Not available now</p>
            </div>}
            {/* <Swiper modules={[Navigation, A11y, Pagination]} navigation pagination spaceBetween={50} slidesPerView={1} style={{
              "--swiper-navigation-size": "40",
              "--swiper-navigation-color": "#555"
            }}> */}
              {/* {offers?.map((offer,index) => {
                const imageMsg = offer.query_messages?.find(item => item.type == 'image')
                const textMsg = offer.query_messages?.find(item => item.type == 'text')
                const otherTextMsgs = offer.query_messages?.filter(item => item.type === 'text').slice(1)

                return (
                  <SwiperSlide key={'offer-slide-' + index}>
                    <div className='w-[80vw] border-[1px] border-gray-200 p-[1px] rounded-md mx-auto' onClick={e => onClickOfferCard(offer)}>
                      <div className=' flex flex-col gap-2 min-h-[140px] rounded-md'>
                        <div className='bg-white'>
                          {imageMsg && <img src={imageMsg.content} className='object-contain rounded-md w-full max-h-[140px] mx-auto' />}
                        </div>
                        <div className='bg-gray-50 p-2'>
                          <div className='text-[20px] font-semibold'>{textMsg.content}</div>
                          <div>
                            {otherTextMsgs && otherTextMsgs?.map(msg => (
                              <p key={msg._id}>{msg.content}</p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })} */}
              {/* { isOfferLoading && <SwiperSlide>
                <div><Loading /></div>
              </SwiperSlide> } */}
            {/* </Swiper> */}
            <div className=''>
              { offers?.map((offer, index) => {
                  const imageMsg = offer.query_messages?.find(item => item.type === 'image')
                  const textMsg = offer.query_messages?.find(item => item.type === 'text')
                  const otherTextMsgs = offer.query_messages?.filter(item => item.type === 'text').slice(1)
                  const description = offer.meta?.description?.toString().trim()
                  return (
                    <div key={'offer-' + index} onClick={e => onClickOfferCard(offer)} className='relative'>
                      <div className='bg-gray-50 flex gap-2 rounded-md m-1 p-2 min-h-[80px]'>
                        <div className='flex-[0_1_25%]'>
                          {imageMsg && <img src={imageMsg.content} className='object-cover rounded-md w-[80px] max-h-[80px]' alt="" />}
                        </div>
                        <div className='flex-[0_1_70%]'>
                          <div className='text-[20px] font-semibold'>{textMsg.content}</div>
                          <div>
                            {otherTextMsgs?.length ? otherTextMsgs?.map(msg => (
                              <p key={msg._id}>{msg.content}</p>
                            )) : description?.length ? <p>{description}</p> : <></>}
                          </div>
                        </div>
                      </div>
                      <div className='absolute bottom-2 right-4'>
                        <PiCaretRight />
                      </div>
                    </div>
                  )
                }) }
            </div>
            {isOfferLoading ? <div>
              <Loading />
            </div> : <></>}
          </div>
          {/* Products content */}
          <div className={'text-center mx-2 my-12 text-gray-500 ' + (activeTab === 'products' ? "" : "hidden")}>
            <p>Not available now</p>
          </div>
        </div>
        {/* <Tabs 
          tabs={[
            {
              title: 'Offers',
              content: <h1>offers</h1>
            },
            {
              title: 'Products',
              content: <h1>products</h1>
            },
            {
              title: 'Offers',
              content: <h1>offers</h1>
            },
            {
              title: 'Products',
              content: <h1>products</h1>
            },
            {
              title: 'Offers',
              content: <h1>offers</h1>
            },
            {
              title: 'Products',
              content: <h1>products</h1>
            },
          ]}
        /> */}
      </div>
    </div>
    </>

  );
}
export default SingleBusinessScreen2;

// function Tabs({ tabs = [], onTabChange = null }) {
//   const [activeTabIndex, setActiveTabIndex] = useState(0)
//   const onClickTabTitle = index => {
//     setActiveTabIndex(index)
//     if(typeof onTabChange === 'function') {
//       onTabChange(tabs[index])
//     }
//   }
//   if(!tabs.length) {
//     return <></>
//   }
//   return (
//     <>
//       <ul className="overflow-x-auto">
//         {tabs.map((tab,index) => (
//           <li key={tab?.id ?? "tab-id-" + index} onClick={e => onClickTabTitle(index)} className={'inline px-4 py-2 whitespace-nowrap ' + (activeTabIndex === index ? "font-medium text-black" : "")}>
//             {tab.title}
//           </li>
//         ))}
//       </ul>
//       <ul>
//         {tabs.map((tab,index) => (
//           <li key={tab?.id ?? "tab-content-id-" + index} className={activeTabIndex == index ? "" : "hidden"}>
//             {tab.content}
//           </li>
//         ))}
//       </ul>
//     </>
//   )
// }