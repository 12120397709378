import ProductCard from "./ProductCard.jsx";

function ProductTabContent({ products }) {
  return (  
    <>
      <div className="relative">
        {products && products?.length ? products?.map((item, index) => <ProductCard product={item} key={'b-prod-card-' + index} />) : <></>}
      </div>
    </>
  );
}

export default ProductTabContent;