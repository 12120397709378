import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { LAMBDA_API_BUSINESS_PORTAL_URL } from './contexts/MyBusinessContext';
import { useEffect } from 'react';
import axios from 'axios';
import Loading from '../../basic/Loading';

const BPage = () => {
    const { orderId } = useParams();
    const [data, setData] = useState(null);
     const [isFetchingStats, setIsFetchingStats] = useState(false);
     const [status , setStatus] = useState(data?.status)

    useEffect(() => {
      document.title = "OneCall";
      setIsFetchingStats(true);
      async function fetchData() {
        try {
          const response = await axios.get(
            `https://7b7xlap5jvkahyo5himfrzqy640qnadr.lambda-url.eu-west-1.on.aws/app/orders/user/get-order-by-id?order_id=${orderId}`
          );
          setData(response.data.data.order);
          console.log("Dataa", response.data.data.order);
          setIsFetchingStats(false);
        } catch (error) {
          setIsFetchingStats(false);
          console.error("Error fetching data: ", error);
        }
      }
      fetchData();
    }, [orderId]);
    const updateStatus =()=>{

    }
    // console.log("dataaaaaa",data)

  return (
    <div>
      {isFetchingStats ? (
        <div className="mt-[50%]">
          <Loading />
        </div>
      ) : (
        <div className="mx-2">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="font-semibold text-[22px] my-4 ">
              {data?.marker.name}
            </div>
          </div>
          <div className="flex flex-row text-[17px]">
            <div className="font-semibold">Order ID :</div>
            <div className="ml-1">
              {data?._id.slice(-6)
              }
            </div>
          </div>
          <div className="flex flex-row text-[17px]">
            <div className="font-semibold">Status :</div>
            <div className="ml-1">
              {" "}
              {status
                ? status
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                : ""}
            </div>
          </div>
          <div className="text-[17px] font-semibold">Items :</div>

          <div>
            {data?.cart.map((itemm, index) => (
              <div key={index}>
                <div className="flex flex-row w-full my-4">
                  <div className="w-[30%] flex justify-center align-center">
                    <img
                      src={itemm.line_item.image_url}
                      alt=""
                      srcset=""
                      className="rounded-lg mx-1 w-[60%]"
                    />
                  </div>

                  <div className="w-[70%] flex flex-col">
                    <div className="font-bold mt-2 text-[17px]">
                      {itemm.line_item.name}
                    </div>
                    <div className="flex flex-row text-[17px] mt-3">
                      <div className="font-semibold">Quantity : </div>
                      <div className="ml-1 font-semibold">
                        {itemm?.quantity}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="flex flex-row  my-4">
              <div className="font-semibold text-[17px]">Total Price : </div>
              <div className="ml-1 text-[18px]">
                {data?.price.amount}
                <span className="ml-1">{data?.price.currency}</span>
              </div>
            </div>
            <div className="flex flex-row text-[17px] my-4">
              <div className="font-semibold">Payment method : </div>
              <div className="ml-1 ">
                {data?.payment_method
                  ?.split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </div>
            </div>
          </div>
          <div>
           <button
            className="bg-[#D6F3FFAA] rounded-md p-2 mb-2 text-blue"  
           onClick={() => updateStatus()}
              >
                Cancel Order 
              </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BPage