import React, { useContext, useEffect, useState } from 'react'
import { AppImages } from '../../../../Asset/images/image'
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";
import { toast } from 'react-toastify';
import axios from 'axios';
import { LAMBDA_API_BUSINESS_PORTAL_URL, MyBusinessContext } from '../contexts/MyBusinessContext';
import Modal from 'react-modal';
import Loading from '../../../basic/Loading';


const Menu = () => {
  const ctx = useContext(MyBusinessContext);
  const [isFetchingStats, setIsFetchingStats] = useState(false);
  const [selectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [data, setData] = useState([]);
   
   
 useEffect(() => {
  async function fetchData() {
    try {
      let business_id = ctx.business?.id || ctx.env?.business_id;

      if (!business_id) {
        throw new Error("No business id");
      }

     //  const response = await axios.get(
     //    LAMBDA_API_BUSINESS_PORTAL_URL +
     //      "/app/my-business/products/get?business_id=" +
     //      business_id
     //  );
     const response = await axios.get(
       `${LAMBDA_API_BUSINESS_PORTAL_URL}/app/my-business/products/menu/get?business_id=${business_id}`
     );


      console.log("Dataaa", response);
      const newData = response.data.data.menus; // Use response.data directly

      if (newData.length > 0) {
        // Append the new data to the existing data
        setData(newData);
       //  setCurrentPage(currentPage + 1);
      } else {
        // No more data available
       //  setHasMore(false);
      }
     //  setData(response.data.data.products);

      if (response.data?.status !== 200) {
        throw response.data;
      } else {
        // Your other logic here
      }

      // Set isFetchingStats to false when the response is received
      setIsFetchingStats(false);
    } catch (error) {
      console.error(error);

      if (error?.error) {
        toast.error(
          error?.isOperationalError ? "Something went wrong" : error?.message,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }

      // Set isFetchingStats to false when there is an error
      setIsFetchingStats(false);
    }
  }
   setIsFetchingStats(true);
   fetchData(); // Call the async function inside useEffect
 }, [ctx.business?.id, ctx.env?.business_id]);
    
     const navigate = useNavigate();
  
     const handleRemoveMenu = () => {
    // Example: Make an API call to remove the selected menu
    if (selectedItem) {
      const menu_id = selectedItem._id;
      console.log("MenuuuuuuuIdddd",menu_id , typeof(menu_id))
      axios.post(`${LAMBDA_API_BUSINESS_PORTAL_URL}/app/my-business/products/menu/delete`, { menu_id })
        .then(response => {
          // Handle the response as needed
          console.log("responseee",response.data);
          // Close the modal after successful removal
          closeModal();
        })
        .catch(error => {
          // Handle errors
          console.error(error);
        });
    }
  };
  return (
    <div className='mt-2'>

          <div>     
                      {isFetchingStats ? (
                        <div className="text-black">
                         <div className='text-center'> Loading...</div>
                          <Loading />
                        </div>
                      ) : (
                        <div>
      {
        data?.length > 0 ? (
   <div className='px-2'>
     <div>
          <button
            className="bg-[#2196F3] rounded-md p-2 mb-4 text-white font-normal text-[16px] w-full mt-[4px]"
            onClick={() => navigate("/app/single-business/menu")}
            // onClick={() => handleButtonClick1()}
          >
           Add a new menu
          </button>
        </div>
        
      {
              data?.map(item => (
            <div>   
            {
              !item.image_url ? (<img src={AppImages.itemImage} alt="" className="h-[110px] w-full" />):(  <img src={item.image_url} alt="" className="h-[110px] w-full" />)
            }
         <div className='flex flex-row justify-between mt-2'>
          <div>
           <div className='text-[19px] font-semibold '>{item?.title}</div>
           <div className='text-[13px] mt-[1px]'>{item?.description}</div>
           </div>
                      <Link
    to={{
      pathname: `/app/menuscreen`,
    }}
    state={{ item: item }}
  >
        <IoIosArrowForward className='mt-2' />
        </Link>
         </div>
       
        
        <div className='text-[13px] font-semibold mt-2'>Items</div>
        
          {
            item.products.length > 0 ? (<div>
               {item.products.map(foodItem => (
            <div className='text-[13px] mt-[1px]'>{foodItem.name}</div>
          ))}
              
            </div>):(<div className='text-[13px] mt-[1px]'> No Items </div>)
          }

         <div className='text-[13px] font-semibold mt-2'>Tags</div>
        
         <div className='mb-3'>
          {
            item.tags.length > 0 ? (<div>
               {item.tags.map(foodItem => (
            <div className='text-[13px] mt-[1px]'>{foodItem}</div>
          ))}
              
            </div>):(<div className='text-[13px] mt-[1px]'> No Tags </div>)
          }

          </div>
          {/* <button onClick={() => handleItemClick(item)}>Open Dialog</button> */}
           <div className='border-[1px] border-gray-300 my-2' > </div>
            </div>
            ))
             }   
            
           
        </div>
        ):(
             <div className="flex items-center justify-center mb-7">
      <div className="text-center">
        <img src={AppImages.menuImage} alt="" className="h-[142px]" />
        <div className="text-[#464A52] text-[16px] mt-[24px]">
          <div>Craft your menu, share it with users, showcase</div>
          <div className="mt-[1px]">your fantastic items, and boost leads.</div>
        </div>
        <div>
          <button
            className="bg-[#2196F3] rounded-md p-2 mb-2 text-white font-normal text-[16px] w-full mt-[37px]"
            onClick={() => navigate("/app/single-business/menu")}
            // onClick={() => handleButtonClick1()}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
        )
      }  </div>
                      )}</div>
     
         <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        <div className="dialog">
          {selectedItem && (
            <>
              <div className="dialog-title">{selectedItem.title}</div>
              <div className="dialog-description">{selectedItem.description}</div>
             <div>
                <button onClick={handleRemoveMenu}>Remove Menu</button>
              </div>
            </>
          )}
          <button onClick={closeModal}>Close Dialog</button>
        </div>
      </Modal>
    </div>
  )
}

export default Menu