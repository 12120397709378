import { PiArrowLeft, PiMapPin, PiPhone } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import { isValidUrl } from "../../../../utils/helpers.js";
import FlutterInterface from "../../../../utils/flutter_interface.js";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import Modal from "react-modal";
// import SimpleModal from "../../../basic/SimpleModal.jsx";
// import Loading from "../../../basic/Loading.jsx";
import { AppImages } from "../../../../Asset/images/image.js";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
function SingleOfferScreen() {
  const modalStyle = {
    content: {
      top: "50%",
      maxWidth: "488px",
      width: "90vw",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "90vh",
      height: '42vh',
      overflowY: "hidden",
      borderRadius: "18px",
      position: "relative"
    },
    overlay: {
      backgroundColor: "rgba(0,0,0, 0.5)",
    },
  };
  const modalStyle2 = {
    content: {
      top: "50%",
      maxWidth: "488px",
      width: "90vw",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "90vh",
      height: '25vh',
      overflowY: "hidden",
      borderRadius: "18px",
      position: "relative"
    },
    overlay: {
      backgroundColor: "rgba(0,0,0, 0.5)",
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [isSMopen, setIsSMopen] = useState(false);
  const [isDiscountModalopen, setIsDiscountModalopen] = useState(false);
  const [isOffer, setIsOffer] = useState(null);
  const [userId, setUserId] = useState(null);
  const [offerId, setOfferId] = useState(null);
  const [isError, setIsError] = useState("");
  const [ifError, setIfError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(null)
  const navigate = useNavigate()
  const { state } = useLocation()
  const { offer } = state
  useEffect(() => {
    if (offer.is_offer === true) {
      setIsOffer(true);
    }
  }, [offer.is_offer]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let query_id = offer._id;
    setOfferId(query_id);
  }, [])

  console.log("queryid", offerId);
  let queryTitle = offer.query_messages?.find(item => item.type === 'text')?.content.toString()
  queryTitle = queryTitle?.toString().length > 15 ? `${queryTitle}..` : queryTitle

  let queryImage = offer.query_messages?.find(item => item.type === 'image')?.content.toString().trim()
  queryImage = isValidUrl(queryImage) ? queryImage : null

  let allTextMessages = offer.query_messages?.filter(item => item.type === 'text')
  console.log("offer type", offer)
  let description = offer?.meta?.description?.toString().trim()

  let phone = offer?.from?.toString().replace(/[^0-9+ ]/gi, "")
  phone = phone !== 'NA' && phone.length ? phone : null

  const onPhoneBtnClick = e => {
    if (phone) {
      FlutterInterface.createActivityLog('tap_on_offer_phone', offer?._id.toString())
      FlutterInterface.openDialer(phone)
    }
  }
  const onLocationBtnClick = e => {
    let location = offer?.location?.coordinates?.length === 2 ? ({ lat: offer?.location?.coordinates[1], lng: offer?.location?.coordinates[0] }) : null
    if (location) {
      FlutterInterface.createActivityLog('tap_on_offer_location', offer?._id.toString())
      FlutterInterface.openGoogleMap(location.lat, location.lng)
    }
  }
  useEffect(() => {
    FlutterInterface.createActivityLog('offer_screen_visit', offer?._id.toString())
  }, [offer?._id])

  useEffect(()=>{
    FlutterInterface.getUserId()
.then(user_id =>setUserId(user_id))
    // setUserId('6655a2ad5f8edd67bdcc7ddc')
  }, []);
  const handleRedeem = async () => {
    try {
      setLoading(true);
      
      if(userId){
      const response = await fetch(`https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/can-user-redeem-offer?user_id=${userId}&query_id=${offerId}`, {
      // const response = await fetch(`https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/can-user-redeem-offer?user_id=6655a2ad5f8edd67bdcc7ddc&query_id=${offerId}`, {
        method: 'GET'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log("response for balance check", data);
      if (data.status !== 200) {
        setIsError(data?.message ?? "Something went wrong");
        return setIsSMopen(true);
      }
      if (data?.data?.can_redeem === true) {
        setIsModalOpen(true);
      } else {
        setIsSecondModalOpen(true);
      }
      }else{
       return toast.error("You are not logged in!");
      }
    } catch (error) {
      console.error("Error", error);
    }finally{
      setLoading(false);
    }
  };
  const handleDiscount = async () => {
    debugger;
    try {
      setIsLoading(true);
      const data = { query_id: offerId, user_id: userId }
      const response = await axios.post('https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/vouchers/send-user-request-to-redeem-offer', data)
      // const responseData = await response.json();
      console.log("discount api response", response);
      if (response.data.status !== 200) {
        setIfError(data?.message ?? "Something went wrong");
        setIsDiscountModalopen(true);
      } else {
        setIsDiscountModalopen(true);
        // setCurrentBalance(response.data?.data?.udpated_balance)
      }
    } catch (error) {
      console.error("Error", error);
    }finally{
      setIsLoading(false);
      setIsModalOpen(false);

    }
    // const data = {query_id: offerId, user_id: userId}
  }
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeSecondModal = () => {
    setIsSecondModalOpen(false);
  };
  const closeSMopen = () => {
    setIsSMopen(false);
  };
  const closeDiscountModalopen = () => {
    setIsDiscountModalopen(false);
    navigate(-1);
  };
  return (
    <div className="relative">
      <div className="flex items-center gap-6 px-2 py-4">
        <div>
          <PiArrowLeft size={25} onClick={e => navigate(-1)} />
        </div>
        <div>
          <h1 className="text-[24px] font-medium">{queryTitle}</h1>
        </div>
      </div>
      <div className="h-[50vh] w-full bg-gray-50 flex items-center justify-center" >
        {queryImage && <img src={queryImage} className="object-contain max-h-[50vh]" alt="" />}
      </div>
      <div className="p-8 overflow-scroll mb-[85px]">
        <p className="text-[20px]">{allTextMessages?.map(item => item.content).join(" ")}</p>
        {description?.length ? <p className="text-[20px]">{description}</p> : <></>}
      </div>
      <div className="flex justify-between items-center p-4 fixed bottom-0 bg-white w-full pb-6 shadow-md" style={{ boxShadow: '0 0 3px 0px rgba(0, 0, 0, 0.5)' }}>
        <div className="flex gap-4" >
          <div className="border border-gray-500 rounded-full" onClick={onLocationBtnClick}>
            <PiMapPin class="my-3 text-gray-500 mx-3" size={25} />
          </div>
          {phone && (<div className="border border-gray-500 rounded-full" onClick={onPhoneBtnClick}>
            <PiPhone className="  text-gray-500 m-3" size={25} />
          </div>)}
        </div>
        <div>
          {/* {isOffer === false ? <Button variant="contained" disabled>Redeem</Button> : null} */}
          {loading ? ( <img src={AppImages.loading} alt="loading" className="w-[30px] mr-8" /> ) : (<Button variant="contained" onClick={handleRedeem} >Redeem</Button>)}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen && !isDiscountModalopen}
        style={modalStyle2}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <div className="min-h-screen flex flex-col my-4 items-center">
          <div class="px-6 py-2 text-center ">
            <div class="font-bold text-xl mb-2 ">To avail this discount</div>
          </div>
          <div class="px-6 py-2">
            {isLoading ? (<img src={AppImages.loading} alt="loading" className="w-[30px]" />) : (<button class="w-full bg-[#7CB342] hover:bg-[#4CAF50] text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline transition duration-300" onClick={handleDiscount}>Click Here</button>)}
          </div>
          <div className="ml-auto text-black"><Button onClick={closeModal}>Close</Button></div>
        </div>
      </Modal>

      <Modal
        isOpen={isSecondModalOpen}
        style={modalStyle}
        onRequestClose={() => setIsSecondModalOpen(false)}
      >
        <div className="min-h-screen flex flex-col my-4 items-center text-center">
          <h2 className="text-2xl font-bold mb-4">Insufficient Balance</h2>
          <p className="text-lg mb-4">
            Sorry, your balance is insufficient to avail of this offer.
          </p>
          <p className="text-lg mb-4">
            Please contact our support team for assistance.
          </p>
          <button className="bg-green-500 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline hover:bg-green-600 transition-colors duration-300"
           onClick={ ()=> window.open('tel: +92 (304) 1110 201', '_blank')}
          >Contact Support</button>
          <div className="ml-auto text-white"><Button className="bg-blue" varient="contained" onClick={closeSecondModal}>Close</Button></div>
        </div>
      </Modal>
      <Modal
        isOpen={isSMopen}
        onRequestClose={() => setIsSMopen(false)}
        style={modalStyle2}
      >
        
        <div className="min-h-screen flex flex-col my-4 items-center text-center">
          <h2 className="text-2xl font-bold mb-4 text-[red]">Validation Error</h2>
          <p className="text-lg mb-4">
            {isError}
          </p>
          <div className="ml-auto text-white"><Button className="bg-blue" varient="contained" onClick={closeSMopen}>Close</Button></div>
        </div>
      </Modal>
      <Modal
        isOpen={isDiscountModalopen}
        onRequestClose={() => setIsDiscountModalopen(false)}
        style={modalStyle2}
      >
        {/* <button
          className="text-[20px] font-bold absolute right-6 top-[4px]"
          onClick={closeDiscountModalopen}
          aria-label="Close"
        >
          X
        </button> */}
        <div className="min-h-screen flex flex-col my-4 items-center text-center">
          {ifError ? (
            <div>
              <h2 className="text-2xl font-bold mb-4 text-[red]">Validation Error</h2>
              <p className="text-lg mb-4">Your offer has not been redeemed.</p>
              <div className="ml-auto"><Button varient="contained" onClick={closeDiscountModalopen}>Close</Button></div>
            </div>
          ) : (
            <div>
              <p className="text-lg mb-4 font-medium">Your request to redeem this offer has been sent.</p>
              <p className="text-lg mb-4 font-medium">Thank you.</p>
              <div className="ml-auto "><Button className="bg-blue" onClick={closeDiscountModalopen}>Close</Button></div>
            </div>
          )}

        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default SingleOfferScreen;