import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import BackArrow from "../BackArrow.jsx";
import { SingleBusinessContext } from "./contexts/SingleBusinessContext.jsx";
import { formatNumber } from "../../../../utils/helpers.js";
import ModalImage from "react-modal-image";
import FlutterInterface from "../../../../utils/flutter_interface.js";
import { ToastContainer, toast } from "react-toastify";

// import { useLocation} from 'react-router-dom';

const SingleProductView = () => {
  const [userId, setUserId] = useState(null);
  const ctx = useContext(SingleBusinessContext);
  useEffect(() => {
    FlutterInterface.getUserId().then(res => {
      if(res) {
        setUserId(res)
      }
    })
  }, []);

  
  const location = useLocation();
  const { item } = location.state || {}; // Initialize with an empty object
  
  if (!item) {
    return <div>Item not found</div>;
  }

  let price = <div><b>{item?.price?.currency} {formatNumber( item?.price?.amount )}</b></div>

  if(item?.discounted_price) {
    price = <div><s>{item?.price?.currency} {formatNumber( item?.price?.amount )}</s><b className="pl-2 text-[17px]">{item?.discounted_price?.currency} {formatNumber( item?.discounted_price?.amount )}</b></div>
  }

  return (
    <div>
      <ToastContainer />
      <div className="flex flex-col mt-[10px]  w-[full] ">
        <div className="flex flex-row justify-between mr-5">
          <BackArrow />
          <Link to="/app/cart">
            <AiOutlineShoppingCart className="my-2 text-[25px]" />
            {ctx?.cart.items.length > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-[12px] w-[25px] m-1 rounded-full text-white text-xs py-1 pl-[10px] pr-2">
                {ctx?.cart.items.length}
              </span>
            )}
          </Link>
        </div>
        <div className="flex items-center justify-center w-full h-[300px]">
          {item?.images.length > 0 ? (
            <img
              src={item?.images[0]}
              alt=""
              className="rounded-md  h-full "
            />
          ) : (
            <div className="bg-slate-100 text-[22px] font-semibold w-full h-full flex items-center justify-center">
              {item?.name.slice(0, 2)}
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between mt-2 text-[#2B2B2B] text-[17px] mx-3">
          <div className="font-semibold">{item?.name}</div>
          <div>
            {" "}
            <div className="text-[#2B2B2B] font-semibold opacity-50">
              {price}
              {/* {item?.price?.amount
                ? `Rs. ${formatNumber(item.price.amount)}`
                : ""} */}
            </div>
          </div>
        </div>
        {item?.description === "" ? (
          <div></div>
        ) : (
          <div>
            {" "}
            {/* <div className="text-[14px] text-[#2B2B2B] font-semibold">
                {item?.name}
              </div> */}
            <div className="text-[13px] mt-1 text-[#2B2B2B] opacity-50 mx-3">
              {item?.description}
            </div>
          </div>
        )}
        <div className="text-[13px] mt-1 text-[#2B2B2B] opacity-50 mx-3">
          {item?.availability === "0" ? "not available" : "available"}
        </div>
        <div className="border-1 border-slate-400"></div>
        <div className=" mt-6 ">
          {/* {item?.category === "" ? (
            <div></div>
          ) : (
            <div>
              {" "}
              <div className="text-[14px] text-[#2B2B2B] mt-3">Category</div>
              <div className="text-[13px] mt-1 text-[#2B2B2B] opacity-50">
                {item?.category}
              </div>
            </div>
          )} */}

          {/* <div className="text-[14px] text-[#2B2B2B] mt-3">Price</div> */}

          {/* <div className="text-[14px] text-[#2B2B2B] mt-3">Phone</div>
          <div className="text-[13px] mt-1 text-[#2B2B2B] opacity-50">
            {ctx.marker?.phone}
          </div> */}
          {/* <div className="text-[14px] text-[#2B2B2B] mt-3">Availability</div> */}

          <div className="my-3 flex flex-wrap mx-3">
            {item?.images.map((file, index) => (
              <ModalImage
                key={index}
                small={file}
                large={file}
                // alt={`Uploaded Image ${index}`}
                className="h-[80px] w-[100px]  mr-1 mb-1 rounded-md"
              />
            ))}
          </div>
          {/* <div className="flex items-center justify-center ">
            <div>
              <Link to="/app/cart">
                <button className="w-[126px] items-center h-[35px] text-[13px] rounded-md bg-[#2196F3] text-white">
                  Add to cart
                </button>
              </Link>
            </div>
          </div> */}
        </div>
        <div className="flex items-center my-3 justify-center ">
          <div>
         
           {!userId ? (
              <div className="text-center font-semibold">
                Please <span className="text-[#2196F3]">SignIn</span> for place
                order
              </div>
            ) : (
             <button
              className="w-[126px] items-center h-[35px] text-[13px] rounded-md bg-[#2196F3] text-white"
              onClick={() => {
                if(!userId) {
                  toast.error('Please log in to continue')
                  return;
                }
                ctx.addItemToCart({
                  product: item,
                  quantity: 1,
                });
              }}
            >
              <div className="flex flex-row items-center justify-center">
                Add to cart
              </div>
            </button>
             )} 
           
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default SingleProductView;
