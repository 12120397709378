import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

const PlanSelectionForm = () => {
  const Plans = [
    { name: 'Listing Plan', package:'7$' },
    { name: 'Business Plan', package:'34$' },
    { name: 'Active Plan', package:'76$' },
    { name: 'Partner Plan', package:'447$'},
  ];

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [State, setState] = useState(1);

  const handlePlanChange = (e) => {
    setSelectedPlan(e.target.value);
  };

  const handleContinuePayment = () => {
    // Perform any logic you need when the user continues with payment
    console.log('Selected Plan:', selectedPlan);
    setState(2)
  };

  const [formFields, setFormFields] = useState({
    name: '',
    email: '',
    // password: '',
    phone: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    // password: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userId = '6414624dd37934518eefc4c1';

    // Validate form fields
    const newErrors = {};
    Object.keys(formFields).forEach((key) => {
      if (!formFields[key].trim()) {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
      }
    });

    setErrors(newErrors);

    // If there are no errors, you can proceed with form submission
    if (Object.keys(newErrors).length === 0) {
      // Perform form submission logic here
      console.log('Form submitted:', formFields);

       axios.post('https://7b7xlap5jvkahyo5himfrzqy640qnadr.lambda-url.eu-west-1.on.aws/stripe/create-customer', {
        name: formFields.name,
        email: formFields.email,
        phone: formFields.phone,
        user_id: userId

      })
      .then(async (res) => {
        console.log('Responseeeee',res.data);
        if (res.data?.status !== 200) {
          throw res.data;
        } else {
          
        //   setShowSuccessModal(true);
         
        }
      })
      .catch((e) => {
        // console.log(e);
        if (e?.error) {
          toast.error(
            e?.isOperationalError ? "Something went wrong" : e?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
    }
   
  };


  return (
    <div className="max-w-md mx-auto mt-8 p-4  rounded-md">
        {
            State === 1 ? (
               <form>
        <div className="mb-4">
          <label className="block text-[22px] font-bold text-center text-gray-600">Select a Plan</label>
          <div className="mt-2">
            {Plans.map((plan) => (
              <div key={plan.name} className='my-2 bg-gray-100 rounded-md p-2'>
               
                {/* <label htmlFor={plan.name} className="text-gray-700"> */}
                  <div className='flex flex-row justify-between'>
                    <div className='flex flex-row'>
                         <input
                  type="radio"
                  id={plan.name}
                  name="plan"
                  value={plan.name}
                  checked={selectedPlan === plan.name}
                  onChange={handlePlanChange}
                  className="mr-2"
                />
                        <div className='text-[19px] font-semibold'>{plan.name}</div></div>
                    <div className='text-[19px] font-semibold'>{plan.package}</div>
                    </div>
                {/* </label> */}
              </div>
            ))}
          </div>
        </div>

        <button
          type="button"
          onClick={handleContinuePayment}
          className={`bg-blue text-white py-2 px-4 rounded-md ${
            selectedPlan ? 'hover:bg-blue-600' : 'cursor-not-allowed opacity-50'
          }`}
          disabled={!selectedPlan}
        >
          Continue Payment
        </button>
      </form>
            ) :(
                <div>
                       <form onSubmit={handleSubmit}>
        <div className='text-center text-gray-600 font-bold text-[22px]'>Create An Account</div>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-600">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formFields.name}
            onChange={handleChange}
            className={`mt-1 p-2 w-full border ${
              errors.name ? 'border-red-500' : 'border-gray-300'
            } rounded-md`}
          />
          {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-600">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formFields.email}
            onChange={handleChange}
            className={`mt-1 p-2 w-full border ${
              errors.email ? 'border-red-500' : 'border-gray-300'
            } rounded-md`}
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </div>

        {/* <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-600">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formFields.password}
            onChange={handleChange}
            className={`mt-1 p-2 w-full border ${
              errors.password ? 'border-red-500' : 'border-gray-300'
            } rounded-md`}
          />
          {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
        </div> */}

        <div className="mb-4">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-600">
            Phone
          </label>
          <input
            type="number"
            id="phone"
            name="phone"
            value={formFields.phone}
            onChange={handleChange}
            className={`mt-1 p-2 w-full border ${
              errors.phone ? 'border-red-500' : 'border-gray-300'
            } rounded-md`}
          />
          {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
        </div>

        {/* <StripePromise/> */}

        <button
          type="submit"
          className="bg-blue text-white py-2 px-4 rounded-md hover:bg-blue-600"
        >
          Create Account
        </button>
      </form>
                </div>
            )
        }
    
    </div>
  );
};

export default PlanSelectionForm;
