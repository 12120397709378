import { Button } from '@mui/material';
import React from 'react';

const ModalImageee = ({ imageUrl, closeModal, tagLine = null, footer = null }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className='bg-white w-[95vw] rounded-md'>
        {tagLine ? tagLine : <></>}
        <img src={imageUrl} alt="" className='w-[70vw] h-auto mx-auto' />
        {footer ? footer : <></>}
        <div className='m-4 flex justify-end'>
          <Button variant='outlined' onClick={closeModal}>Close</Button>
        </div>
      </div>
    </div>
  );
};

export default ModalImageee;
