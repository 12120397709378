import { createContext, useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { LAMBDA_API_BUSINESS_PORTAL_URL } from "../../contexts/MyBusinessContext.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import { getErrorMessageFromAPIResponse } from "../../../../../utils/helpers.js";
import { v4 as uuidv4 } from 'uuid';
import FlutterInterface from "../../../../../utils/flutter_interface.js";

export const SingleBusinessContext = createContext({
  marker_id: null,
  marker: {
    name: null,
    type: null,
    address: null,
    phone: null 
  },
  setMarker: (marker) => null,
  products: null,
  setProducts: (products) => {},
  addProducts: (products) => {},
  isFetchingMarker: false, 
  setIsFetchingMarker: (isFetching) => {},
  isFetchingProducts: false, 
  setIsFetchingProducts: (isFetching) => {},
  populateProducts: async () => {},
  productsAPIOptions: {hasMore: true, currentPage: 1}, 
  setProductAPIOptions: (options) => {},
  cart: {items: []},
  addItemToCart: (item) => {},
  updateCartItemQuantity: (item_id, quantity) => {},
  removeItemFromCart: (item_id) => {},
  clearCart:()=>{}
})

function SingleBusinessContextProvider({ children }) {
  const [searchParams] = useSearchParams()
  const [marker_id] = useState( searchParams.get('marker_id') )
  const [marker, setMarker] = useState(null)
  const [isFetchingMarker, setIsFetchingMarker] = useState(false)
  const [isFetchingProducts, setIsFetchingProducts] = useState(false)
  const [products, setProducts] = useState(null)
  const [productsAPIOptions, setProductAPIOptions] = useState({
    hasMore: true,
    currentPage: 1,
  })
  const [cart, setCart] = useState({ items: [] })
  async function fetchBusinessById(id) {
    let url = new URL(LAMBDA_API_BUSINESS_PORTAL_URL + "/marker/get-by-id")
    url.searchParams.set('id', id)
    try {
      let loc = await FlutterInterface.getLocation()
      if(loc?.lat && loc?.lng) {
        url.searchParams.set('lat', loc?.lat.toString())
        url.searchParams.set('lng', loc?.lng.toString())
      }
    } catch(e) {}
    return axios.get(url)
  }
  async function populateProducts(businessId = null) {
    setIsFetchingProducts(true)
    let url = new URL(LAMBDA_API_BUSINESS_PORTAL_URL + "/app/my-business/products/get")
    url.searchParams.set('business_id', businessId ?? marker?.business_profile_id)
    url.searchParams.set('page', productsAPIOptions.currentPage)
    let res = await axios.get(url).catch(e => null).finally(() => setIsFetchingProducts(false))
    let opts = {hasMore: productsAPIOptions.hasMore, currentPage: productsAPIOptions.currentPage}
    if(!res?.data?.data?.products || !res?.data?.data?.products.length) {
      opts.hasMore = false
    }
    if(res?.data?.data?.products) {
      opts.currentPage = opts.currentPage + 1 
      setProducts([...(products ?? []), ...res?.data?.data?.products])
    }
    setProductAPIOptions(opts)
  }
  useEffect(() => {
    if(!marker && marker_id) {
      setIsFetchingMarker(true)
      fetchBusinessById(marker_id).then(res => {
        if(res.data?.status === 200 && res.data?.data?.marker) {
          let m = res.data?.data?.marker
          setMarker( m )
        } else {
          throw res?.data ?? "Something went wrong"
        }
      }).catch(e => {
        toast.error(getErrorMessageFromAPIResponse(e))
      }).finally(() => {
        setIsFetchingMarker(false)
      })
    }
  }, [marker, marker_id])
  const value = {
    marker_id,
    marker,
    setMarker,
    products,
    setProducts,
    addProducts: (newProducts) => setProducts([...products, ...newProducts]),
    isFetchingMarker,
    setIsFetchingMarker,
    isFetchingProducts,
    setIsFetchingProducts,
    populateProducts,
    productsAPIOptions,
    setProductAPIOptions,
    cart,
    addItemToCart: (item) => {
      if (!cart?.items?.find((j) => j.product?._id === item.product?._id)) {
        toast.success("Product is added successfully")
        setCart({
          items: [
            ...cart.items,
            {
              ...item,
              id: uuidv4(),
              date: new Date(),
              price: {
                amount: item.product.price.amount * item.quantity,
                currency: item.product.price?.currency ?? "PKR",
              },
              ...(item.product.discount && {
                discounted_price: {
                  amount: item?.product.discounted_price?.amount,
                  currency: item?.product.discounted_price?.currency ?? "PKR"
                }
              })  
            },
          ],
        });
      } else {
        toast.error("You already have this item in cart");
      }
    },
    updateCartItemQuantity: (item_id, quantity) => {
      const item = cart.items?.find((item) => item.id === item_id);
      if (item) {
        item.quantity = quantity;
        item.price = {
          ...item.price,
          amount: item.product.price.amount * quantity,
        };
        if(item.product.discounted_price?.amount) {
          item.discounted_price = {
            ...item.discounted_price,
            amount: item.product.discounted_price.amount * quantity
          }
        }
        const index = cart.items?.findIndex((item) => item.id === item_id);
        const updated_items = cart.items;
        updated_items[index] = item;
        setCart({ items: updated_items });
      }
    },
    removeItemFromCart: (item_id) => {
       setCart((prevCart) => {
         const updatedItems = prevCart.items.filter(
           (item) => item.id !== item_id
         );
         return { items: updatedItems };
       });
    },
    clearCart: () => {
       setCart({
        items: [],
         });
       },
   
  };
  return (  
    <SingleBusinessContext.Provider value={value}>
      <Outlet />
    </SingleBusinessContext.Provider>
  );
}

export default SingleBusinessContextProvider;

