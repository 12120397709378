import { useCallback, useEffect, useState } from "react";
import ModalImageee from "../MyBusiness/ModalImage.jsx";
import axios from "axios";
import FlutterInterface from "../../../../utils/flutter_interface.js";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Button } from "@mui/material";
import { BiPlus } from "react-icons/bi";
import SimpleModal from "../../../basic/SimpleModal.jsx";
import AddPeopleForm from "./worker/AddPeopleForm.jsx";
import { ToastContainer, toast } from "react-toastify";
import { FiCopy, FiShare } from "react-icons/fi";
import HKHeader from "./HKHeader.jsx";

function HKWorker() {
  const [uuid, setUUID] = useState(null)
  const [data, setData] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [isAddPeopleModelOpen, setIsAddPeopleModelOpen] = useState(false)
  const tab_classnames = "border-0 bg-transparent cursor-pointer outline-none py-2 px-2";

  

  function onAddPeople(args) {
    const { name, phone, cnic, address, area, city, setIsLoading, setErrors } = args
    setIsLoading(true)
    axios.post(`https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/hkp/on-worker-add-member-via-form?uuid=${uuid}`, {
      name, 
      phone, 
      cnic, 
      address,
      area, 
      city,
      worker_uuid: uuid
    }).then(res => {
      if(res?.data?.status !== 200) {
        if(res?.data?.is_operational_error) {
          setErrors({ _form: 'Something went wrong' })
          return;
        } else {
          setErrors({ _form: res?.data?.message })
          return;
        }
      }
      toast.success('Added member')
      setIsAddPeopleModelOpen(false)
      populateData()
      console.log(res?.data)
    }).catch(e => {
      console.log(e)
    }).finally(() => {
      console.log('done')
      setIsLoading(false)
    })
  }

  const handleClickOnQrCode = (e) => {
    setSelectedImage(e.target.src)
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedImage(null)
    setShowModal(false)
  }

  function onCopy() {
    const link = data?.dynamic_link
    const textArea = document.createElement("textarea");
    textArea.value = link;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast.success("Copied")
  }

  function onShare() {
    const link = data?.dynamic_link
    FlutterInterface.share(link);
  }

  const populateData = useCallback(() => function () {
    FlutterInterface.getUUID().then(uuid => {
      if(uuid?.toString().length) {
        setUUID(uuid)
        axios.get(`https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/get-user-data?uuid=${uuid}`).then(res => {
          const response = res?.data?.data 
          const newData = {...data}
          if(response?.dynamic_link) {
            FlutterInterface.setNativePreference('hkp_user_dynamic_link', response?.dynamic_link.toString())
            newData.dynamic_link = response?.dynamic_link
            newData.qrcodeImageUrl = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${response?.dynamic_link}`
          }

          if(response?.total_members) {
            newData.totalMembers = response?.total_members
          }

          if(response?.todays_members) {
            newData.todaysMembers = response?.todays_members
          }

          setData(newData)
        })
      }
    })
  }, [data])

  useEffect(() => {
    populateData()
  }, [populateData])
  
  return (  
    <div>
      <HKHeader />

      <div className="p-4 mb-2">
        <Tabs selectedTabClassName="border-b-2 border-[#24ACE3] text-black font-semibold">
          <TabList className={"flex items-center gap-6 border-b-2 mb-4"}>
            <Tab className={tab_classnames}>Dashboard</Tab>
            <Tab className={tab_classnames}>My Account</Tab>
          </TabList>
          <TabPanel>
            { !data?.qrcodeImageUrl?.length ? <></> :
              <div className="flex items-start justify-between border-b-2 border-slate-100 pb-2">
                <div>
                  <p className="text-[12px]">My QR Code:</p>
                  <div className="flex gap-2 text-[10px] mt-2">
                    <button className="px-2 py-1 border-[1px] border-blue rounded-md text-blue" onClick={onCopy}>
                      <FiCopy className="inline mr-2" />
                      <span>Copy Link</span>
                    </button>
                    <button className="px-2 py-1 border-[1px] border-blue rounded-md text-blue" onClick={onShare}>
                      <FiShare className="inline mr-2" />
                      <span>Share Link</span>
                    </button>
                  </div>
                </div>
                <img src={data?.qrcodeImageUrl} className="w-[50px]" onClick={handleClickOnQrCode} alt="" />
              </div>
            }
            <div className="flex items-center justify-between py-2 border-b-2 border-slate-100">
              <div>
                <p className="text-[12px]">Today's Added Members:</p>
                <p className="font-semibold text-[18px]">{data?.todaysMembers ?? "--"}</p>
              </div>
              <div>
                <p className="text-[12px]">Total Added Members:</p>
                <p className="font-semibold text-[18px]">{data?.totalMembers ?? "--"}</p>
              </div>
            </div>
            <div className="my-2 flex justify-end">
              <Button variant="contained" startIcon={<BiPlus />} onClick={() => setIsAddPeopleModelOpen(true)}>
                <span>Add People</span>
              </Button>
            </div>
          </TabPanel>
          <TabPanel>
            <p className="my-6 text-center">Coming Soon!</p>
          </TabPanel>
        </Tabs>

        
      </div>

      {showModal && (
        <ModalImageee 
          imageUrl={selectedImage} 
          closeModal={closeModal} 
          tagLine={<div dir="rtl" className="ur text-[14px] text-center bg-white p-2 my-2">ہمارے ساتھ مل کر، انسانیت کو زندہ کریں اور دوسروں کی خوشیاں بڑھائیں</div>} 
        />
      )}

      {(
        <SimpleModal isOpen={isAddPeopleModelOpen} onClose={() => setIsAddPeopleModelOpen(false)}>
          <div className="min-h-[20vh] m-2 w-[80vw]">
            <AddPeopleForm onSubmit={onAddPeople} onClose={() => setIsAddPeopleModelOpen(false)} />
          </div>
        </SimpleModal>
      )}

      <ToastContainer 
        autoClose={2000}
        hideProgressBar
        position="top-center"
      />
    </div>
  );
}

export default HKWorker;