import React, { useEffect, useRef, useState } from "react";

const Map = ({ lat, lng }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    const mapOptions = {
      center: { lat, lng }, // Center using the provided lat and lng props
      zoom: 15, // Initial zoom level
    };

    const googleMap = new window.google.maps.Map(mapRef.current, mapOptions);
    setMap(googleMap);

    // Function to handle errors in case geolocation is not available
    const handleLocationError = (error) => {
      console.error("Error getting user's location:", error);
    };

    // Try to get the user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        googleMap.setCenter(userLocation);

        // Add a marker at the user's current location
        new window.google.maps.Marker({
          position: userLocation,
          map: googleMap,
          title: "Your Location",
        });
      }, handleLocationError);
    } else {
      handleLocationError("Geolocation is not supported by this browser.");
    }
  }, [lat, lng]); // Add lat and lng to the dependency array

  return <div ref={mapRef} style={{ width: "100%", height: "400px" }} />;
};

export default Map;
