import axios from "axios";
import { browserName, deviceType, mobileModel, osName } from "react-device-detect";
import FlutterInterface from "./flutter_interface";

export const convertMySqlDateToReadable = (date) => {
    date = new Date(date)
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear() === (new Date()).getFullYear() ? null : date.getFullYear()
    const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    return (month) + " " + date.getDate() + (year ? `, ${year}` : '') + (!year ? ", " : " ") + time
}

export function convertMongoDateToReadable(dateString, options = { skipTime: false }) {
    const date = new Date(dateString)
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate()
    const year = date.getFullYear().toString()
    const hour = date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()
    const mins = date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()
    let final = ""
    if(!options.skipTime) {
        final = `${hour}:${mins} `
    }
    final += `${month} ${day}, ${year}` 
    return final
}

export const range = (size, startAt = 0) => {
    return [...Array(size).keys()].map(i => i + startAt);
}

export const titleCase = (str) => {
    return str.split(" ").map(word => {
        const letters = word.split("")
        return letters[0].toUpperCase() + letters.slice(1).join("").toLowerCase()
    }).join(" ")
}

export const formatNumberAsReadable = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const removeSpecialCharacters = (str) => {
    return str.replace(/[^A-Za-z0-9_\- ]/g, '')
}

export const arrayUniqueByKey = (array, key) => {
    return [...new Map(array.map(item => [item[key], item])).values()];
}

export const isValidLatitude = (lat) => {
    return !isNaN(lat) && isFinite(lat) && Math.abs(lat) <= 90;
}

export const isValidLongitude = (lng) => {
    return !isNaN(lng) && isFinite(lng) && Math.abs(lng) <= 180;
}

export async function createLog(tag, value) {
    try {
        if(!tag || typeof tag !== 'string') {
            throw new Error("tag is required")
        } 
        value = {
            ...value,
            url: window.location.href,
            os: osName,
            browserName: browserName,
            mobileModel: mobileModel,
            deviceType: deviceType
        }
        const { data } = await axios.post("https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/log", {
            tag: tag,
            value: value 
        })
        if(data.status === 200) {
            return data.data ?? true 
        }
        throw new Error("Something went wrong")
    } catch(e) {
        return null 
    }
}

export function isValidJSON(string) {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }
    return true;
}

export function isEmail(input) {
    // Regular expression pattern to check if the input is in email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Test the input against the regex pattern and return true if it matches, false otherwise
    return emailRegex.test(input);
}

export function getOperatingSystem(window) {
    let operatingSystem = 'Not known';
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
    if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
    if (/android/i.test(userAgent)) { operatingSystem = "Android" }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) { operatingSystem = "iOS" }
    return operatingSystem;
}

export function isAndroid() {
    return getOperatingSystem(window) === 'Android' 
}

export function isIOS() {
    return getOperatingSystem(window) === 'iOS'
}

export function isMobileOS() {
    return isAndroid() || isIOS()
}

export function getBrowser(window) {
    let currentBrowser = 'Not known';
    if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
    else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
    else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
    else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
    else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
    else { console.log('Others'); }
    return currentBrowser;
}

export function getErrorMessageFromAPIResponse(e, options = { defaultMessage: "Something went wrong" }) {
    
    if(typeof e === 'string') {
        return e 
    }
    if(e instanceof Error) {
        return e.message 
    }
    if(typeof e === 'object') {
        if(e?.error) {
            if(e?.isOperationalError) {
                return options.defaultMessage
            }
            if(e?.message) {
                return e?.message 
            }
        }
    }
    return options.defaultMessage
}

/**
 * 
 * @param {number} number 
 * @param {object} options 
 */
export function formatNumber(number, options = {}) {
    return number.toLocaleString()
}

export async function createActivityLog(activity, value, meta = {}) {
    try {
        if(!activity) {
            throw new Error('activity is required')
        }
        const url = new URL('https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/webview/activity-log')
        const loc = await FlutterInterface.getLocation()
        const basicInfo = await FlutterInterface.getBasicInfo()
        const currentDate = new Date();
        const localTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}T${currentDate.getHours().toString().padStart(2, "0")}:${currentDate.getMinutes().toString().padStart(2, "0")}:${currentDate.getSeconds().toString().padStart(2, "0")}.${currentDate.getMilliseconds().toString().padStart(3, "0")}Z`;
        const payload = {
            activity,
            value,
            localTime: localTime,
            platform: isAndroid() ? 'android' : isIOS() ? 'ios' : null,
            lat: loc?.lat,
            lng: loc?.lng,
            meta: {
                ...meta,
                ...basicInfo,
                url: window.location.href
            },
            uuid: basicInfo?.tuid,
        }
        const res = await axios.post(url, payload)
        return res?.data 
    } catch(e) {
        return null
    }
}

export function isValidUrl(inputUrl) {
    try {
      // Parsing the URL, and if successful, it's a valid URL
      new URL(inputUrl);
      return true;
    } catch (error) {
      // If parsing fails, it's not a valid URL
      return false;
    }
}

export function getFirstLetters(inputString) {
    // Split the input string into words
    const words = inputString.split(' ');
  
    // Extract the first letters of each word
    const firstLetters = words.map(word => {
      // Take the first letter or first two letters if only one word
      const lettersToTake = words.length === 1 ? 2 : 1;
      return word.slice(0, lettersToTake).toUpperCase();
    });
  
    // Join the resulting array into a string
    const resultString = firstLetters.join('');
  
    // Ensure the result is not longer than 2 letters
    return resultString.slice(0, 2);
}

export function calculateDistance(lat1, lon1, lat2, lon2) {
    function deg2rad(deg) {
        return deg * (Math.PI/180)
    }
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d.toFixed(2);
}