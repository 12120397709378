import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppImages } from "../../../../Asset/images/image.js";
import ReactModal from "react-modal";
import firebase_service from "../../../../utils/firebase_service.js";
import {
  LAMBDA_API_BUSINESS_PORTAL_URL,
  MyBusinessContext,
} from "../contexts/MyBusinessContext.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import AppToastContainer from "../../../basic/AppToast.jsx";
import FlutterInterface from "../../../../utils/flutter_interface.js";
import { isIOS } from "../../../../utils/helpers.js";
import MBUploadPhotos1 from "./MBUploadPhotos1.jsx";

function Product() {
  const ctx = useContext(MyBusinessContext);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const isApple = useMemo(() => isIOS(), []);
  const fields = {
    description: null,
    category:null,
    name: null,
    price: null,
    availability: null,
    // images: null,
    // terms: null,
  };
  const option = [
    { name: "available", value: 1 },
    { name: "not available", value: 0 },
  ];

   const handleNextClick = () => {
     // Initialize an array to store the names of missing required fields.
     const missingFields = [];

     // Check if each required field is filled and add its name to the array if not.
     if (!formFields.description) {
       missingFields.push("Description");
     }
     if (!formFields.name) {
       missingFields.push("Name");
     }
     if (!formFields.price) {
       missingFields.push("Price");
     }
     if (formFields.availability === null) {
       missingFields.push("Availability");
     }

     if (missingFields.length === 0) {
       // If no required fields are missing, move to the next step.
       setNext(1);
     } else {
       // If there are missing required fields, generate the error message.
       const errorMessage = `Please fill in the following required fields: ${missingFields.join(
         ", "
       )}`;

       // Show the error message in a toast.
       toast.error(errorMessage);
     }
   };

  const [formFields, setFormFields] = useState(fields);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState(null);
  const [next1 , setNext] = useState(0);
  useEffect(() => {
    if (ctx.business) {
      setFormFields({
        ...formFields,
        category: ctx.business.category,
      });
    }
  }, [ctx.business, formFields]);
 
  const onSubmit = async (e) => {
    e.preventDefault();
    setFieldErrors(null);
    console.log(formFields);
    setIsLoading(true);

    const errors = {};
    if (!formFields.description) {
      errors.description = "Please enter description";
    }
    if (!formFields.name) {
      errors.name = "Please enter name of your business";
    }
    if (!formFields.price) {
      errors.price = "Please enter address of your price";
    }
    // if (!formFields.category) {
    //   errors.category = "Please choose category your business belongs to.";
    // }
    if (!formFields.availability) {
      errors.availability = "This field is availabilty";
    }

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      setIsLoading(false);
      return;
    }

    let image_urls = [];
    if (imageFiles?.length) {
      image_urls = await firebase_service.uploadManyFilesOnFirebase({
        files: imageFiles.map((f) => f),
        foldername: "business-portal-images",
      });
    }

    let business_id = ctx.env?.business_id
    
    if (!business_id) {
      business_id = await FlutterInterface.getUserId();
      if (business_id) {
        ctx.setEnv({ ...ctx.env, business_id: business_id });
      }
    }

    axios.post(LAMBDA_API_BUSINESS_PORTAL_URL + "/app/my-business/products/add", {
        business_id: business_id,
        name: formFields.name,
        description: formFields.description,
        price: formFields.price,
        availability: formFields.availability,
        category: formFields.category,
        image_urls: image_urls,
      })
      .then(async (res) => {
        
        if (res.data?.status !== 200) {
          throw res.data;
        } else {
          setShowSuccessModal(true);
        }
      })
      .catch((e) => {
        if (e?.error) {
          toast.error(
            e?.isOperationalError ? "Something went wrong" : e?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <style>
          {`
          .PhoneInputInput {
            background-color: rgb(243 244 246 / var(--tw-bg-opacity));
            padding: 0.75rem;
            outline-color: #2196F3;
          }
          `}
        </style>
      </Helmet>
      <AppToastContainer />
      <div className="flex flex-col mt-[10px] items-center w-[full]">
        <div className="flex items-center justify-center text-[#2B2B2B] text-[22px]">
          Add new product
        </div>
        <div className=" border-slate-400"></div>
        <form
          onSubmit={onSubmit}
          className="text-[#2B2B2B]  mt-[10px] text-[20px] w-[80%] md:w-[60%] mx-auto"
        >
          <div className={next1 === 0 ? "" : "hidden"}>
            <div className="">
              <div className=" ">
                <TextField
                  label={"Item Name"}
                  placeholder="Enter your item name"
                  value={formFields?.name ?? ""}
                  error={fieldErrors?.name}
                  onChange={(e) =>
                    setFormFields({ ...formFields, name: e.target.value })
                  }
                />
                <TextField
                  label={"Description"}
                  placeholder="Type here"
                  error={fieldErrors?.description}
                  onChange={(e) =>
                    setFormFields({
                      ...formFields,
                      description: e.target.value,
                    })
                  }
                />

                <div className="mt-4">
                  <TextFieldLabel label={"Business Category"} />
                  <select
                    className="text-[13px] block w-full bg-gray-100 disabled:bg-gray-300 py-3 rounded-md px-3 focus:outline-[#2196F3]"
                    onChange={(e) =>
                      setFormFields({ ...formFields, category: e.target.value })
                    }
                    value={ctx.business?.category}
                    disabled
                  >
                    <option>Choose one..</option>
                    <option value={ctx.business?.category}>
                      {ctx.business?.category}
                    </option>
                  </select>
                  {fieldErrors?.category ? (
                    <TextFieldError text={fieldErrors?.category} />
                  ) : (
                    <></>
                  )}
                </div>

                <TextField
                  label={"Price"}
                  placeholder="Enter price"
                  error={fieldErrors?.price}
                  onChange={(e) =>
                    setFormFields({ ...formFields, price: e.target.value })
                  }
                />

                <div className="mt-4">
                  <TextFieldLabel label={"Availability"} />
                  <select
                    className={
                      "text-[13px] block w-full bg-gray-100 py-3 rounded-md px-3 focus:outline-[#2196F3]" +
                      (isApple ? " appearance-none h-[42px]" : "")
                    }
                    onChange={(e) =>
                      setFormFields({
                        ...formFields,
                        availability: e.target.value,
                      })
                    }
                  >
                    <option>Select</option>
                    {option.map((item, index) => (
                      <option key={"item" + index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {fieldErrors?.availability ? (
                    <TextFieldError text={fieldErrors?.availability} />
                  ) : (
                    <></>
                  )}
                </div>

                <div className="mt-4">
                  <TextFieldLabel label="Upload Images" />
                  {fieldErrors?.images ? (
                    <TextFieldError text={fieldErrors?.images} />
                  ) : (
                    <></>
                  )}

                  <MBUploadPhotos1
                    onChange={(files) => {
                      if (files?.length > 0) {
                        setImageFiles(files);
                        // compressImages(files);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div
                className="rounded-md py-2 mb-5 text-[15px] w-full bg-[#009bfb] hover:bg-lightBlue-600 text-white disabled:bg-slate-200"
                onClick={handleNextClick}
              >
                <div className="ml-[140px]">next</div>
              </div>
            </div>
          </div>

          <div className={next1 === 0 ? "hidden" : ""}>
            <div>
              <div className="text-[14px] text-[#2B2B2B] font-semibold">
                {formFields.name}
              </div>
              <div className="text-[13px] mt-1 text-[#2B2B2B] opacity-50">
                {formFields.description}
              </div>
              <div className="text-[14px] text-[#2B2B2B] mt-3">Category</div>
              <div className="text-[13px] mt-1 text-[#2B2B2B] opacity-50">
                {formFields.category}
              </div>
              <div className="text-[14px] text-[#2B2B2B] mt-3">Price</div>
              <div className="text-[13px] mt-1 text-[#2B2B2B] opacity-50">
                Rs. {formFields?.price}
              </div>
              <div className="text-[14px] text-[#2B2B2B] mt-3">
                Availability
              </div>
              <div className="text-[13px] mt-1 text-[#2B2B2B] opacity-50">
                {formFields.availability === "0"
                  ? "not available"
                  : "available"}
              </div>
              <div className="my-3 flex flex-wrap">
                {imageFiles?.map((file, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt={`${index}`}
                    className="w-[80px] h-[75px] rounded-md ml-1 mt-1"
                  />
                ))}
              </div>
            </div>

            <div className="">
              <button
                className="rounded-md py-2 mb-5 text-[15px] w-full bg-[#009bfb] hover:bg-lightBlue-600 text-white disabled:bg-slate-200"
                disabled={isLoading}
              >
                {isLoading ? (
                  <img src={AppImages.loading} className="w-[20px] mx-auto" alt="" />
                ) : (
                  <span>Submit</span>
                )}
              </button>
              <div
                className="text-center text-[#24ACE3] border-b-[1px] px-4 border-[#24ACE3] block mx-auto text-[14px]"
                onClick={() => setNext(0)}
              >
                Back
              </div>
            </div>
          </div>
        </form>
        {showSuccessModal && (
          <ReactModal
            isOpen={true}
            className="Modal Success-Modal"
            overlayClassName="Modal-Overlay"
          >
            <div className="p-4">
              <div className="flex items-center justify-center">
                <img
                  src={AppImages.checkmark}
                  alt="checkmark"
                  className="w-[60px] svg-green"
                />
              </div>
              <p className="text-center my-4 text-green-600 font-medium">
                Your Product has been added successfully!
              </p>
              <div
                className="text-center p-2 cursor-pointer"
                onClick={(e) => {
                  navigate("/app/business");
                  // window.location.reload();
                }}
              >
                <p className=" font-bold text-[14px] text-green-600 border-dotted border-b-[1px] inline">
                  Click to view
                </p>
              </div>
            </div>
          </ReactModal>
        )}
      </div>
    </>
  );
}

function TextFieldLabel({ label, htmlFor }) {
  return (
    <label htmlFor={htmlFor} className="text-[13px] my-1">
      {label}
    </label>
  );
}

function TextField({ label, id, placeholder = "", onChange, error = null }) {
  return (
    <div className="mt-4">
      <TextFieldLabel label={label} htmlFor={id} />
      <input
        id={id}
        type="text"
        placeholder={placeholder ?? label}
        className="bg-gray-100 px-3 py-3 border-[#5e5954] rounded-md focus:outline-[#2196F3] w-full text-[13px]"
        onChange={onChange}
      />
      {error ? <TextFieldError text={error} /> : <></>}
    </div>
  );
}

function TextFieldError({ text }) {
  return <div className="text-red-500 text-[13px] my-1">{text}</div>;
}

export default Product;
