import React, { useEffect } from "react";
import { useState } from "react";
// import { AppImages } from "../Asset/images/image";
// import Footer from "./Footer";
// import Loading from "./basic/Loading";
import { FiMapPin } from "react-icons/fi";
import { FaGreaterThan } from "react-icons/fa6";

import { BsLink } from "react-icons/bs";
//  <BsLink className="mx-auto" size={"20px"} />;
import axios from "axios";
import Loading from "../../basic/Loading.jsx";
import { AppImages } from "../../../Asset/images/image.js";
import AppToastContainer from "../../basic/AppToast.jsx";
import { toast } from "react-toastify";
import { LAMBDA_API_BUSINESS_PORTAL_URL } from "./contexts/MyBusinessContext.jsx";
import { useSearchParams } from "react-router-dom";


const NearByBusinesses = () => {
  const [searchParams] = useSearchParams()

  const [showLoader, setShowLoader] = useState(true);
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });

  async function getLocation() {
    // let lsLoc = localStorage.getItem('user.location')
    // if(lsLoc) {
    //   lsLoc = JSON.parse(lsLoc)
    //   const now = Date.now()
    //   // use saved location only for 1 hour
    //   if(now - lsLoc.time <= 3.6e+6) {
    //     return lsLoc.data 
    //   }
    // }
    
    return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        localStorage.setItem(
          "user.location",
          JSON.stringify({
            time: Date.now(),
            data: { lat, lng },
          })
        );
        resolve({ lat, lng });
      },
      (error) => reject(error),
      { enableHighAccuracy: true }
    );

    })
  }
  

  useEffect(() => {
    const fetchData = async (lat, lng) => {
      setShowLoader(true);
      const url = new URL('https://7b7xlap5jvkahyo5himfrzqy640qnadr.lambda-url.eu-west-1.on.aws/markers/nearby/of-any-category')
      if(searchParams.has('lat') && searchParams.get('lat') && searchParams.has('lng') && searchParams.get('lng')) {
        lat = searchParams.get('lat')
        lng = searchParams.get('lng')
      }
      url.searchParams.set('lat', lat)
      url.searchParams.set('lng', lng)
      url.searchParams.set('dlink', '1')
      url.searchParams.set('max_distance', '50')
      url.searchParams.set('skip_cats', 'jobs')
      if(searchParams.has('must_include')) {
        url.searchParams.set('must_include', searchParams.get('must_include'))
      }
      axios.get(url).then((res) => {
      
        if(res?.data?.data?.markers) {
          setData(res?.data?.data?.markers);
        }
      }).catch(e => {
        console.log('error', e)
      }).finally(() => {
        setShowLoader(false);
      });
    };
    // fetchBusinessById();
    getLocation().then(res => {
      console.log("lataa",res.lat)
      setLocation({
        latitude: res.lat,
        longitude: res.lng 
      })
      fetchData(res.lat, res.lng)
    }).catch(e => {
      console.log(e)
      toast.error(
        "Please enable your location. If unallowed already, please enable it in your browser's site settings",
        { autoClose: 4000 }
      )
      setShowLoader(false)
    })
  }, [searchParams]);
  const[serviceCode , setServiceCode] = useState('')

  const [dataa, setData] = useState("");
  const [message, setMessage] = useState("");
  
  console.log('Dataaaaa',dataa)
const handleButtonClick = async() => {
  setMessage("")
  if(serviceCode !== ''){
const response = await axios.get(
  `${LAMBDA_API_BUSINESS_PORTAL_URL}/markers/nearby/search?q=${serviceCode}`
);
if (response.data.data.markers.length > 0) {setData(response.data.data.markers)}
else {
  setMessage("No Product exists against this service code")
};
console.log("ServiceCodeData", response);
  }
   
  };
  return (
    <>
      {showLoader && <Loading />}
      {location.latitude === null && location.longitude === null ? (
        <div className="text-center mt-5 text-[16px] font-semibold">
          <p>Please allow location access to proceed further.</p>
        </div>
      ) : (
        <div className="container mx-auto px-auto flex flex-col w-full justify-center mb-[40px] md:mx-[3%]">
          <div className="flex flex-wrap">
            <div className="flex flex-row w-full justify-between mx-[5%]">
              <div className="w-[12%] my-1">
                <img
                  src={AppImages.onecall}
                  className="w-full my-1"
                  alt=""
                  srcset=""
                />
              </div>
              <div className="w-[62%]">
                {/* <form> */}
                <input
                  type="text"
                  placeholder="Service Code"
                  value={serviceCode}
                  className="px-4 py-2 my-2 w-full border border-gray-300 rounded-lg focus:outline-none"
                  onChange={(e) => {
                    // setFormFields(() => ({ search: e.target.value }));
                    setServiceCode(e.target.value);
                  }}
                />

                {/* </form> */}
              </div>
              <div className="w-[20%]">
                <button
                  className="w-[100%] h-[41px] mt-2 ml-1 rounded-md border-slate-300 hover:bg-slate-300 hover:text-white border text-slate-300"
                  onClick={() => handleButtonClick()}
                >
                  <FaGreaterThan className="mx-auto" size={"15px"} />
                </button>
              </div>
            </div>
            {dataa === "" ? (
              <Loading />
            ) : message === "" ? (
              dataa?.map((item, index) => (
                <div key={index} className="w-full md:w-1/5 my-1 ">
                  <div className="border-[1px] border-slate-300" />
                  <div className="rounded-xl w-full mx-[2%] mt-1 justify-between flex flex-row">
                    <div className="w-[20%] flex justify-center">
                      <div className="h-[41px] w-[41px] md:h-52 mt-1 bg-slate-200 rounded-full flex justify-center items-center">
                        {/* <div className="text-[22px] font-bold text-gray-700"> */}
                        {item.photo_urls === "NA" || !item.photo_urls ? (
                          <div className="text-[11px] font-bold text-gray-700">
                            {item.address
                              .replace(/name-/gi, "")
                              .slice(0, 2)
                              .toUpperCase()}
                          </div>
                        ) : (
                          <img
                            src={item.photo_urls}
                            alt=""
                            className="h-full w-full rounded-full object-cover"
                            onError={(e) => {
                              e.target.src = AppImages.briefcase;
                              e.target.style =
                                "width:20px;height:20px;border-radius:0";
                            }}
                          />
                        )}
                        {/* </div> */}
                      </div>
                    </div>

                    <div className="w-[50%]">
                      <div className="flex flex-col justify-start">
                        <div className="">
                          {item.name === "" || !item.name ? (
                            <div className="text-[16px] font-semibold text-gray-700">
                              {item.address
                                .replace(/name-/gi, "")
                                .slice(0, 3)
                                .toUpperCase()}
                            </div>
                          ) : (
                            <div className="text-[16px] font-semibold text-gray-700">
                              {item.name.replace(/name-/gi, "").slice(0, 17)}
                            </div>
                          )}
                        </div>
                        <div>
                          {!item?.serviceCodes ?  (
                            <div className="flex flex-row">
                              <FiMapPin
                                className="text-slate-400"
                                size={"15px"}
                              />
                              <div>
                                <a
                                  href={`https://www.google.com/maps/?q=${item.address}`}
                                  target="_blank"
                                  className="text-blue-400 "
                                  rel="noreferrer"
                                >
                                  <div className="text-[11px] text-green-500 font-semibold pl-2">
                                    {item.address.slice(0, 12)}
                                  </div>
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-row">
                              <div className="text-[11px] text-slate-400 font-semibold">
                                ServiceCode
                              </div>
                              <div className="text-[11px] text-green-500 font-semibold pl-2">
                               {item?.serviceCodes[0]}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-[25%]">
                      <a href={item?.dynamic_link}>
                        <button className="w-[79px] h-[28px] mt-2 rounded-md bg-blue text-white">
                          <BsLink className="mx-auto" size={"20px"} />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-[16px] font-semibold mx-2 text-center">
                {message}
              </div>
            )}
          </div>
        </div>
      )}
      {/* <Footer /> */}
      <AppToastContainer />
    </>
  );
};

export default NearByBusinesses;