import axios from "axios";
import { createContext, useEffect, useState } from "react";
import FlutterInterface from "../utils/flutter_interface.js";

export const GlobalContext = createContext({
  config: { location: null, ipMeta: null }
})

function GlobalContextProvider({ children }) {
  const [config, setConfig] = useState({ location: null, ipMeta: null })
  useEffect(() => {
    FlutterInterface.getLocation().then(res => {
      if(res?.lat && res?.lng) {
        const loc = { lat: res?.lat, lng: res?.lng, createdAt: Date.now() }
        setConfig(old => ({...old, location: loc}))
        localStorage.setItem('config.location', JSON.stringify(loc))
      }
    })
    axios.get(`https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/get-info-by-ip-address`).then(res => {
      if(res?.data?.data) {
        setConfig(old => ({...old, ipMeta: res?.data?.data}))
        localStorage.setItem('config.ipMeta', JSON.stringify({...res?.data?.data, createdAt: Date.now()}))
      }
    })
  }, [])
  console.log(config)
  return (  
    <GlobalContext.Provider value={{ config }}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalContextProvider;