import React, { useEffect, useState } from 'react';
import { AppImages } from '../../../../Asset/images/image.js';
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import FlutterInterface from '../../../../utils/flutter_interface.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import HKWorker from './HKWorker.jsx';

import { Slide, ToastContainer, toast } from 'react-toastify';
import HKMember from './HKMember.jsx';
import HKHeader from './HKHeader.jsx';

const HKMainScreen = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [errors, setErrors] = useState({name: null, phone: null})
  const [isSubmitRequestInProcess, setIsSubmitRequestInProcess] = useState(false)
  const [isAlreadyLoggedIn, setIsAlreadyLoggedIn] = useState(false)
  const [isAlreadyWorker, setIsAlreadyWorker] = useState(false)
  const [isAlreadyMember, setIsAlreadyMember] = useState(false)
  const [hasSubmittedMembershipForm, setHasSubmittedMembershipForm] = useState(false)
  let cameViaBusinessQRCode = searchParams.get('came_from_business_qrcode') === 'true'
  const cameViaUserQRCode = searchParams.get('came_from_user_qrcode') === 'true'
  let cameViaAnyQRCode = true 
  if(!searchParams.has('came_from_business_qrcode') && !searchParams.has('came_from_user_qrcode')) {
    cameViaBusinessQRCode = true 
    cameViaAnyQRCode = false 
  }
  useEffect(() => {
    FlutterInterface.isLoggedIn().then(res => setIsAlreadyLoggedIn(res))
    FlutterInterface.getNativePreference('hkp_role').then(res => {
      setIsAlreadyWorker(res === 'worker')
      setIsAlreadyMember(res === 'member')
    })
    FlutterInterface.getNativePreference('hkp_mem_form_submitted').then(res => {
      if(typeof res === 'boolean') {
        setHasSubmittedMembershipForm(res)
      }
    })
  }, [])
  function handleSubmitFormType1(e) {
    e.preventDefault()
    setErrors({name: null, phone: null})
    const fd = new FormData(e.target)
    const name = fd.get('name')
    let phone = fd.get('phone')
    if(!name || !name.length) {
      setErrors({...errors, name: 'name is required'})
      return;
    }
    if(!phone || !phone.length) {
      setErrors({...errors, phone: 'phone is required'})
      return;
    }
    phone = phone.replace(/\D/g, "")
    phone = `${phone[0]}${phone[1]}` === "92" ? phone.substring(2) : phone 
    phone = phone[0] === "0" ? phone.substring(1) : phone
    if(phone.length !== 10) {
      setErrors({...errors, phone: 'Invalid phone'})
      return;
    }
    setIsSubmitRequestInProcess(true)
    FlutterInterface.login(name, phone, { ref: "hkp", action: "generate_user_dlink", dlink_params: { base_marker_id: "65e9d59a07346e96241b4c16" } }).then(res => {
      if(res?.success) {
        FlutterInterface.setNativePreference('hkp_role', 'worker')
        navigate("/app/single-business/hkp/worker")
      }
    }).catch(e => {
      console.log('login error')
      console.log(e)
    }).finally(() => {
      setIsSubmitRequestInProcess(false)
    })
  }
  async function handleSubmitFormType2(e) {
    e.preventDefault()
    setIsSubmitRequestInProcess(true)
    const uuid = await FlutterInterface.getUUID()
    axios.post(`https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/join-hkp-as-worker`, {
      uuid: uuid,
    }).then(res => {
      console.log(res)
      if(res?.data?.data?.joined) {
        navigate("/app/single-business/hkp/worker")
        FlutterInterface.setNativePreference('hkp_role', 'worker')
      }
    }).finally(() => {
      setIsSubmitRequestInProcess(false)
    })
  }
  async function handleSubmitFormType3(e) {
    e.preventDefault()
    setErrors({name: null, phone: null})
    const fd = new FormData(e.target)
    const name = fd.get('name')
    let phone = fd.get('phone')
    let area = fd.get('area')
    let city = fd.get('city')
    if(!name) {
      setErrors({...errors, name: 'Name is required'})
      return;
    }
    if(!phone) {
      setErrors({...errors, phone: 'Phone is required'})
      return;
    }
    if(phone.length) {
      phone = phone.replace(/\D/g, "")
      phone = `${phone[0]}${phone[1]}` === "92" ? phone.substring(2) : phone 
      phone = phone[0] === "0" ? phone.substring(1) : phone
      if(phone.length !== 10) {
        setErrors({...errors, phone: 'Invalid phone'})
        return;
      }
    }

    if(!area) {
      setErrors({...errors, area: 'Area is required'})
      return;
    }
    if(!city) {
      setErrors({...errors, city: 'City is required'})
      return;
    }

    setIsSubmitRequestInProcess(true)
    axios.post(`https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/hkp/on-membership-form-submitted`, {
      name, phone, uuid: await FlutterInterface.getUUID(),
      other: {
        membership_type: fd.get('membership_type'),
        area: area,
        city: city,
        address: fd.get('address'),
        cnic: fd.get('cnic'),
      }
    }).then(res => {
      if(res?.data?.status !== 200 ) {
        if(res?.data.isOperationalError) {
          toast.error("Something went wrong")
          return;
        } else {
          let msg = res?.data?.message
          if(msg === 'uuid is required') {
            msg = "You are not logged in"
          }
          toast.error(msg)
          return;
        }
      }
      navigate("/app/single-business/hkp/member")
      FlutterInterface.setNativePreference('hkp_role', 'member')
      if(res?.data?.data?.joined) {
      }
      toast.success('Form submitted')
    }).finally(() => {
      setIsSubmitRequestInProcess(false)
    })
  }
  async function handleSubmitFormType4(e) {
    e.preventDefault()
    setIsSubmitRequestInProcess(true)
    const fd = new FormData(e.target)
    const uuid = await FlutterInterface.getUUID()
    const membership_type = fd.get('membership_type')
    axios.post(`https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/hkp/on-membership-form-submitted`, {
      action: 'logged_in_user_wants_to_be_hkp_member',
      userId: await FlutterInterface.getUserId(),
      uuid: uuid,
      other: {
        membership_type
      }
    }).then(res => {
      if(res?.data?.status !== 200 ) {
        if(res?.data.isOperationalError) {
          toast.error("Something went wrong")
          return;
        } else {
          let msg = res?.data?.message
          toast.error(msg)
          return;
        }
      }
      navigate("/app/single-business/hkp/member")
      FlutterInterface.setNativePreference('hkp_role', 'member')
      toast.success('Form submitted')
    }).catch(e => {
      toast.error("Something went wrong")
      return;
    }).finally(() => {
      setIsSubmitRequestInProcess(false)
    }) 
  } 

  /**
   * Form Types:
   * 1. Worker login form
   * 2. Worker join form (if already logged in)
   * 3. Member login form 
   * 4. Member join form (if already logged in)
   */
  let formType = 3
  if(!isAlreadyLoggedIn && cameViaBusinessQRCode) {
    if(cameViaAnyQRCode) {
      formType = 1
    } else {
      formType = 3
    }
  } else if (isAlreadyLoggedIn && cameViaBusinessQRCode) {
    formType = 2
  } else if (!isAlreadyLoggedIn && cameViaUserQRCode) {
    formType = 3 
  } else if (isAlreadyLoggedIn && cameViaUserQRCode) {
    formType = 4
  } else {
    formType = 3
  }

  const FormType1 = () => {
    return (
      <>
        <h3 className='font-semibold'>Join "HKP" as Worker</h3>
        <form className='my-2' onSubmit={handleSubmitFormType1}>
          <div className='m-2'>
            <TextField label="Your Name" name="name" className='w-full box-border' error={errors?.name != null} disabled={isSubmitRequestInProcess} />
            {errors?.name && <p className='text-red-500 text-[12px] font-semibold'>{errors.name}</p>}
          </div>
          <div className='m-2'>
            <TextField label="Your Mobile Number" name="phone" className='w-full box-border' error={errors?.phone != null} disabled={isSubmitRequestInProcess} />
            {errors?.phone && <p className='text-red-500 text-[12px] font-semibold'>{errors.phone}</p>}
          </div>
          <div className='flex justify-end mx-2'>
            <Button variant="contained" type='submit' disabled={isSubmitRequestInProcess}>
              <span className={isSubmitRequestInProcess ? "" : 'hidden'}><img src={AppImages.loading} className='w-[20px]' alt="" /></span>
              <span>Submit</span>
            </Button>
          </div>
        </form>
      </>
    )
  }

  const FormType2 = () => {
    return (
      <>
        <h3 className='font-semibold'>Join "HKP" as Worker</h3>
        <form className='my-2' onSubmit={handleSubmitFormType2}>
          <div className='flex justify-end mx-2'>
            <Button variant="contained" type='submit'>
              <span className={ isSubmitRequestInProcess ? '' : 'hidden'}><img src={AppImages.loading} className='w-[20px]' alt="" /></span>
              <span>Join Now</span>
            </Button>
          </div>
        </form>
      </>
    )
  }

  // Member login form
  const FormType3 = () => {
    return (
      <>
        <h3 className='font-semibold'>Become Member of HKP!</h3>
        <form className='my-2' onSubmit={handleSubmitFormType3}>
          <div className='m-2'>
            <TextField label="Full Name" name="name" className='w-full box-border' error={errors?.name != null} disabled={isSubmitRequestInProcess} />
            {errors?.name && <p className='text-red-500 text-[12px] font-semibold'>{errors.name}</p>}
          </div>
          <div className='m-2'>
            <TextField label="Mobile Number" name="phone" className='w-full box-border' error={errors?.phone != null} disabled={isSubmitRequestInProcess} />
            {errors?.phone && <p className='text-red-500 text-[12px] font-semibold'>{errors.phone}</p>}
          </div>
          <div className='m-2'>
            <TextField label="CNIC" name="cnic" className='w-full box-border' error={errors?.cnic != null} disabled={isSubmitRequestInProcess} />
            {errors?.cnic && <p className='text-red-500 text-[12px] font-semibold'>{errors.cnic}</p>}
          </div>
          <div className='m-2'>
            <TextField label="Area (علاقہ)" name="area" className='w-full box-border' error={errors?.area != null} disabled={isSubmitRequestInProcess} />
            {errors?.area && <p className='text-red-500 text-[12px] font-semibold'>{errors.area}</p>}
          </div>
          <div className='m-2'>
            <TextField label="City" name="city" className='w-full box-border' error={errors?.city != null} disabled={isSubmitRequestInProcess} />
            {errors?.city && <p className='text-red-500 text-[12px] font-semibold'>{errors.city}</p>}
          </div>
          <div className='m-2'>
            <TextField label="Address" name="address" className='w-full box-border' error={errors?.address != null} disabled={isSubmitRequestInProcess} />
            {errors?.address && <p className='text-red-500 text-[12px] font-semibold'>{errors.address}</p>}
          </div>
          <div className='m-2'>
            <FormControl>
              <FormLabel>Choose membership type</FormLabel>
              <RadioGroup name="membership_type">
                <FormControlLabel value="contributor" control={<Radio />} label="Contributor" />
                <FormControlLabel value="receiver" control={<Radio />} label="Receiver" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='flex justify-end mx-2'>
            <Button variant="contained" type='submit' disabled={isSubmitRequestInProcess}>
              <span className={isSubmitRequestInProcess ? "" : 'hidden'}><img src={AppImages.loading} className='w-[20px]' alt="" /></span>
              <span>Submit</span>
            </Button>
          </div>
        </form>
      </>
    )
  }

  const FormType4 = () => {
    return (
      <>
        <h3 className='font-semibold'>Become Member of HKP!</h3>
        <form className='my-2' onSubmit={handleSubmitFormType4}>
          <div className='m-2'>
            <FormControl>
              <FormLabel>Choose membership type</FormLabel>
              <RadioGroup name="membership_type">
                <FormControlLabel value="contributor" control={<Radio />} label="Contributor" />
                <FormControlLabel value="receiver" control={<Radio />} label="Receiver" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='flex justify-end mx-2'>
            <Button variant="contained" type='submit'>
              <span className={ isSubmitRequestInProcess ? '' : 'hidden'}><img src={AppImages.loading} className='w-[20px]' alt="" /></span>
              <span>Join Now</span>
            </Button>
          </div>
        </form>
      </>
    )
  }

  let activeForm = <></>

  if(!hasSubmittedMembershipForm) {
    switch(formType) {
      case 1:
        activeForm = FormType1()
      break;
      case 2:
        activeForm = FormType2()
      break;
      case 3:
        activeForm = FormType3()
      break;
      case 4:
        activeForm = FormType4()
      break;
      default:
        activeForm = <></> 
    }
  }


  if(isAlreadyWorker) {
    return <HKWorker />
  }
  if(isAlreadyMember) {
    return <HKMember />
  }
  return (
    <div>
      <HKHeader />
      <div className='p-2 my-2'>
        {activeForm}
      </div>
      <ToastContainer 
        hideProgressBar 
        autoClose={2000} 
        position="top-center" 
        transition={Slide} 
      />
    </div>
  );
};

export default HKMainScreen;
