import React, { useEffect, useState } from 'react';
import WebHeader1 from './WebHeader1';
import Footer1 from './Footer1';
import { AppImages } from '../Asset/images/image';
import axios from 'axios';



const TeacherNotification = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    document.title = "OneCall";
    
  }, []);
    
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://crm-lara-mongo-7azts5zmra-uc.a.run.app/api/dynamic-notification?markerId=65deedcd9135274171b0faa5');
        console.log("Responseee",response.data.data._id)
        setData(response.data.data);
        log('teacherNotification', {
          url:'teacherNotification',
          notificationId: response.data.data._id,
         });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
async function log(tag, value) {
     console.log(tag,value)
     return axios.post(
       "https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/log",
       {
         tag,
         value: JSON.stringify({
           ...value,
           localTime: new Date(),
           link: window.location.href,
         }),
         decodeJson: "true",
       }
     );
   }

  
  return (
    <div className='mainn'>
      <WebHeader1 />
      <div className='border-[1px]'></div>
       
      {
        data?.content_type === 'image'?(
            <div className='px-[2%] my-[5%]'>
        <img src={data?.content_body} alt={AppImages.teacherNeed} srcset="" />
      </div>
        ):(
            <div className='px-[2%] my-[15%]'>
        <div className='text-[18px] font-medium text-center text-slate-600'>
          {data?.content_body}
        </div>
      </div>
        )
      }
    

     

      <div className='border-[1px]'></div>
      <div className='mt-[8%] mb-[2%]'>
        <Footer1 />
      </div>
    </div>
  );
};

export default TeacherNotification;