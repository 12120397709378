import { AppImages } from "../../../../Asset/images/image";

function hwmLogo (){
    return(
        <div className="flex items-center justify-center min-h-screen">
  <img src={AppImages.hwmLogo} alt="Logo" className="h-[500px] object-contain" />
</div>

    )
}
export default hwmLogo