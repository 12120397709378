import React, { useContext, useState } from 'react'
import Map from '../../../../Map'
import { Link, useNavigate } from 'react-router-dom';
import BackArrow from '../../BackArrow';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { SingleBusinessContext } from '../contexts/SingleBusinessContext';
import { LAMBDA_API_BUSINESS_PORTAL_URL } from '../../contexts/MyBusinessContext';
import firebase_service from '../../../../../utils/firebase_service1';
import MBUploadReceipt from '../../MyBusiness/MBUploadReceipt';
import axios from 'axios';
import { toast } from 'react-toastify';
import FlutterInterface from '../../../../../utils/flutter_interface';
import { useEffect } from 'react';
import ReactModal from 'react-modal';
import { AppImages } from '../../../../../Asset/images/image';
import { formatNumber } from '../../../../../utils/helpers';

const Checkout = () => {
  const navigate = useNavigate();
  const dataa = useContext(SingleBusinessContext);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState(false)
  const [Lat , setLat]= useState('')
  const [lng, setLng] = useState(" ");
  const [userId, setUserId]= useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  
  useEffect(() => {
    const getlocation = async()=>{
      const loc = await FlutterInterface.getLocation();
      if (loc?.lat && loc?.lng) {
        setLat(loc?.lat);
        setLng(loc?.lng);
      }
    }
    async function fetchData() {
      const result = await FlutterInterface.getUserId();
      setUserId(result);
    }
    getlocation()
    fetchData();
  }, []);
   
  //  console.log("Locationnn",location)
  const [data] = useState(dataa.cart.items);
  const [fieldErrors] = useState(null);
   const totalAmount = data?.reduce((total, item) => {
    if(item.discounted_price?.amount) {
      return total + item.discounted_price.amount
    }
     return total + item?.price?.amount;
   }, 0);
  const [imageFiles, setImageFiles] = useState(null);
  console.log("Imagesss", imageFiles);
  const [selectedPayment, setSelectedPayment] = useState("cash_on_delivery");
  const [address, setAddress] = useState(''); // State variable for address
  const [addressError, setAddressError] = useState(null);
  const onSubmit = async (e) => {
     setIsLoading(true);
    console.log("slectedPaymentttt",selectedPayment.length)
     if (selectedPayment.length === 0) {
       // Show a toast error message if selectedPayment is null
       toast.error("Please select a payment method", {
         position: toast.POSITION.TOP_RIGHT,
       });
       return;
     }
      if (address.trim() === '') {
      setAddressError('Please enter your address');
      setIsLoading(false);
       toast.error("Please enter your address", {
         position: toast.POSITION.TOP_RIGHT,
       });
      return;
    } else {
      setAddressError(null); // Clear the error message if the address is not null
    }
    let image_urls = [];
     if (
      selectedPayment === "onlinePayment" &&
       (!imageFiles || imageFiles.length === 0)
     ) {
       // Show a toast error message if "onlinePayment" is selected and no image is uploaded
       toast.error("Please upload receipt image", {
         position: toast.POSITION.TOP_RIGHT,
       });
       return;
     }
    if (imageFiles?.length) {
      image_urls = await firebase_service.uploadManyFilesOnFirebase({
        files: imageFiles.map((f) => f.file),
        foldername: "business-portal-images",
      });
    }
    console.log("imagesss", image_urls);
    axios
      .post(LAMBDA_API_BUSINESS_PORTAL_URL + "/app/orders/create", {
        user_id: userId,
        marker_id: dataa.marker_id,
        payment_method: selectedPayment,
        address:address,
        cart: data,
        lat: Lat,
        lng: lng,
        billing_receipts: image_urls,
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data?.status !== 200) {
          toast.error(res.data.message);
          setIsLoading(false);
          setOrder(false);
          throw res.data;
        } else {
          setOrder(true);
          dataa.clearCart();
          setIsLoading(false);
          setShowSuccessModal(true);
          // navigate("/app/my-business/stats");
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setOrder(false);
        console.log(e);
        if (e?.error) {
          toast.error(
            e?.isOperationalError ? "Something went wrong" : e?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handlePaymentChange = (value) => {
    setSelectedPayment(value);
  };
  function formatProductPrice(product) {
    let price = <div><b>{product?.price?.currency} {formatNumber( product?.price?.amount )}</b></div>

    if(product?.discounted_price) {
      price = <div><span>{product?.discounted_price?.currency} {formatNumber( product?.discounted_price?.amount )}</span></div>
    }

    return price
   }
  return (
    <div className="flex flex-col mt-[10px]  w-[full]">
      <div className="flex flex-row justify-between mr-5">
        <Link to="/app/cart">
          <BackArrow />
        </Link>
        <Link to="/app/cart">
          <AiOutlineShoppingCart className="my-2 text-[25px]" />
          {dataa?.cart.items.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-[12px] w-[25px] m-1 rounded-full text-white text-xs py-1 pl-[11px] pr-[9px]">
              {dataa?.cart.items.length}
            </span>
          )}
        </Link>
      </div>

      {/* <div className="flex items-center mx-2">
        <div className="font-semibold text-lg">{ctx?.business.user_id}</div>
      </div> */}
      {/* <div className="flex items-center mx-2">
        <div className="font-semibold text-lg">{dataa?.marker_id}</div>
      </div> */}
      <Map lat={Lat} lng={lng} />
      {order === true || totalAmount === 0 ? (
        <div className="flex items-center justify-center flex-col mt-[20%]">
          <div className="text-blue font-semibold text-[18px]">
            Your order was placed Successfully
          </div>
          <div
            className="text-center p-2 cursor-pointer mt-[50px] border border-orange-600"
            onClick={(e) => {
              // setShowSuccessModal(false);
              navigate(`/app/single-business?marker_id=${dataa?.marker_id}`);
              // window.location reload();
            }}
          >
            <p className="font-bold text-[14px] text-orange-600 border-dotted border-b-[1px] inline">
              Continue Order
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-2 font-semibold text-[22px] mx-4 ">Summary</div>
          <div className="mt-3 border-blueGray-200 border-[1px]">
            {data?.map((item, index) => (
              <div key={index}>
                <div className="h-[48px] flex flex-row mx-4 my-1 justify-between">
                  <div className="flex flex-row">
                    <div className="text-[19px] ">{item.quantity}x</div>
                    <div className="text-[19px] ml-2">{item.product.name}</div>
                  </div>
                  <div className="">
                    <div className="text-[19px]">{formatProductPrice(item)}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="my-2 mx-3">
            {dataa.marker?.business?.bank_details ? (
              <div className="flex flex-row">
                <div>
                  <input
                    type="checkbox"
                    checked={selectedPayment === "receipt"}
                    onChange={() => handlePaymentChange("receipt")}
                  />
                </div>
                <div className="ml-1">Online Payment</div>
              </div>
            ) : (
              <></>
            )}

            <div className="flex flex-row">
              <div>
                <input
                  type="checkbox"
                  checked={selectedPayment === "cash_on_delivery"}
                  onChange={() => handlePaymentChange("cash_on_delivery")}
                />
              </div>
              <div className="ml-1">Cash On Delivery</div>
            </div>
          </div>
          {dataa.marker?.business?.bank_details ? (
            <div className="my-2">
              {selectedPayment === "cash_on_delivery" ? (
                ""
              ) : (
                <div className="mx-3">
                  {dataa.marker?.business?.bank_details.account_holder_name ? (
                    <div className="flex flex-row my-2">
                      <div className="font-semibold">
                        Account Holder Name :{" "}
                      </div>
                      <div>
                        {
                          dataa.marker?.business?.bank_details
                            .account_holder_name
                        }
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {dataa.marker?.business?.bank_details.bank_code ? (
                    <div className=" my-2">
                      <div className="flex flex-row">
                        <div className="font-semibold">Bank Code : </div>
                        <div>
                          {dataa.marker?.business?.bank_details.bank_code}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {dataa.marker?.business?.bank_details.branch_code ? (
                    <div className=" my-2">
                      <div className="flex flex-row">
                        <div className="font-semibold">Branch Code : </div>
                        <div>
                          {dataa.marker?.business?.bank_details.branch_code}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {dataa.marker?.business?.bank_details.account_number ? (
                    <div>
                      <div className="flex flex-row my-2">
                        <div className="font-semibold">Account Number : </div>
                        <div>
                          {dataa.marker?.business?.bank_details.account_number}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="mt-4 mx-3">
                    <TextFieldLabel label="Upload Receipt Image" />
                    {fieldErrors?.images ? (
                      <TextFieldError text={fieldErrors?.images} />
                    ) : (
                      <></>
                    )}
                    <MBUploadReceipt
                      onChange={(files) => {
                        if (files?.length) {
                          setImageFiles(files);
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
            <div className="mt-4 mx-3">
        <TextField
          label="Enter Your Address"
          id="address"
          placeholder="Address"
          onChange={(e) => {
            setAddress(e.target.value);
            setAddressError(null); // Clear the error message when input changes
          }}
          error={addressError}
        />
      </div>
          <div className="flex flex-row justify-between text-[25px] px-4 py-7 rounded-md border-blueGray-200 border-[1px]">
            <div className="font-semibold">Total Amount:</div>
            <div>Rs.{totalAmount}</div>
          </div>
          <div className="flex items-center justify-center mt-3 ">
            <div className="">
              <button
                className="rounded-md py-2 mb-5 text-[15px] w-full bg-[#009bfb] hover:bg-lightBlue-600 text-white disabled:bg-slate-200"
                onClick={(e) => onSubmit()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <img src={AppImages.loading} className="w-[20px] mx-5 py-2" alt="" />
                ) : (
                  <span className="mx-2 ">Confirm Order</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <ReactModal
          isOpen={true}
          className="Modal Success-Modal"
          overlayClassName="Modal-Overlay"
        >
          <div className="p-4">
            <div className="flex items-center justify-center">
              <img
                src={AppImages.checkmark}
                alt="checkmark"
                className="w-[60px] svg-green"
              />
            </div>
            <p className="text-center my-4 text-green-600 font-medium">
              Your Order was placed successfully!
            </p>
            <div
              className="text-center p-2 cursor-pointer"
              onClick={(e) => {
                setShowSuccessModal(false);
                FlutterInterface.goToMessagesChatScreen();
                // navigate(`/app/single-business?marker_id=${dataa?.marker_id}`);
                // window.location.reload();
              }}
            >
              <p className=" font-bold text-[14px] text-green-600 border-dotted border-b-[1px] inline">
                Click to view
              </p>
            </div>
          </div>
        </ReactModal>
      )}
    </div>
  );
}
function TextFieldLabel({ label, htmlFor }) {
  return (
    <label htmlFor={htmlFor} className="text-[13px] my-1">
      {label}
    </label>
  );
}
function TextField({ label, id, placeholder = "", onChange, error = null }) {
  return (
    <div className="mt-4">
      <TextFieldLabel label={label} htmlFor={id} />
      <input
        id={id}
        type="text"
        placeholder={placeholder ?? label}
        className="bg-gray-100 px-3 py-3 border-[#5e5954] rounded-md focus:outline-[#2196F3] w-full text-[13px]"
        onChange={onChange}
      />
      {error ? <TextFieldError text={error} /> : <></>}
    </div>
  );
}

function TextFieldError({ text }) {
  return <div className="text-red-500 text-[13px] my-1">{text}</div>;
}

export default Checkout