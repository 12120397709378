import { isValidJSON } from "./helpers.js";

async function callHandler(funcName, args = []) {
  if(typeof funcName !== 'string' || !Array.isArray(args)) {
    return null;
  }
  return window.flutter_inappwebview?.callHandler(funcName, ...args)
}
const FlutterInterface = {
  getUserId: async () => {
    try {
      let res = await window.flutter_inappwebview?.callHandler("getUserId", []);
      return typeof res === "string" ? res : null;
    } catch (e) {
      return null;
    }
  },
  getBusinessId: async () => {
    try {
      let res = await callHandler("getBusinessId");
      if (typeof res === "string" && res.length) {
        return res;
      }
      return null;
    } catch (e) {
      return null;
    }
  },
  getLocation: async () => {
    try {
      let res = await callHandler("getLocation");
      if (typeof res === "string" && isValidJSON(res)) {
        return JSON.parse(res);
      }
      if (typeof res === "object") {
        return res;
      }
      return null;
    } catch (e) {
      return null;
    }
  },
  askCameraPermission: async () => {
    try {
      let res = await callHandler("askPermissions", ["camera"]);
      if (typeof res === "string" && isValidJSON(res)) {
        res = JSON.parse(res);
      }
      if (typeof res !== "object" || !res) {
        return false;
      }
      if (res.camera) {
        return res.camera === true || res.camera === "true";
      }
      return false;
    } catch (e) {
      return false;
    }
  },
  goToLoginScreen: async () => {
    try {
      return callHandler("goToLoginScreen");
    } catch (e) {
      return false;
    }
  },
  updateBusinessInsideApp: async (businessId) => {
    try {
      if (!businessId) {
        throw new Error("no business id");
      }
      if (typeof businessId !== "string") {
        throw new Error("business id must be a string");
      }
      let res = await callHandler("updateBusinessInsideApp", [businessId]);
      return res;
    } catch (e) {
      return false;
    }
  },
  openDialer: async (phone) => {
    try {
      return callHandler("openDialer", [phone]);
    } catch (e) {
      return null;
    }
  },
  share: async (text) => {
    try {
      return callHandler("openShareDialog", [text]);
    } catch (e) {
      return null;
    }
  },
  goToMessagesChatScreen: async () => {
    try {
      return callHandler("goToMessagesChatScreen");
    } catch (e) {
      return false;
    }
  },
  openBrowser: async (url) => {
      try {
          return callHandler('openBrowser', [url])
      } catch(e) {
          return null 
      }
  },
  goToBusinessScreen: async (markerId) => {
    try {
      return callHandler('goToBusinessScreen', [markerId])
    } catch(e) {
      return null 
    }
  },
  getUUID: async (defaultValue = null) => {
    try {
      let res = await callHandler('getNativePreference', ["tempuser.uuid"])
      if(!res) {
        return defaultValue 
      }
      return res
    } catch(e) {
      return null 
    }
  },
  login: async (name, phone, meta = null) => {
    try {
      return callHandler('login', [{
        name,
        phone,
        meta: meta ? JSON.stringify(meta) : null 
      }])
    } catch(e) {
      return null 
    }
  },
  isLoggedIn: async () => {
    try {
      let res = await callHandler('isLoggedIn')
      if(typeof res === 'boolean') {
        return res;
      }
      return false 
    } catch(e) {
      return false 
    }
  },
  setAppbarTitle: async (title) => {
    try {
      return callHandler("setAppbarTitle", [title])
    } catch(e) {
      return null 
    }
  },
  unsetAppbarTitle: async () => {
    try {
      return callHandler("unsetAppbarTitle")
    } catch(e) {
      return null 
    }
  },
  setNativePreference: async (key, value) => {
    try {
      return callHandler("setNativePreference", [key, value])
    } catch(e) {
      return null 
    }
  },
  getNativePreference: async (key) => {
    try {
      return callHandler("getNativePreference", [key])
    } catch(e) {
      return null 
    }
  },
  openGoogleMap: async (lat, lng) => {
    try {
      return callHandler('openGoogleMap', [`${lat},${lng}`])
    } catch(e) {
      return null 
    }
  },
  getBasicInfo: async () => {
    try {
      return callHandler('getBasicInfo')
    } catch(e) {
      return null 
    }
  },
  createActivityLog: async (activity, value = null, meta = null) => {
    try {
      return callHandler('createActivityLog', [{
        activity,
        value,
        meta 
      }])
    } catch(e) {
      return null 
    }
  },
  showToast: async (text) => {
    try {
      return callHandler('showToast', [text])
    } catch(e) {
      return null 
    }
  },
  goToMessagesChatScreen: async (text) => {
    try {
      return callHandler('goToMessagesChatScreen', [text])
    } catch(e) {
      return null 
    }
  }
};

export default FlutterInterface