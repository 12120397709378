import React from "react"
function DiscountNotification() {
    return (
        <div className="min-h-screen flex flex-col my-4 items-center">
            <div class="px-6 py-2 text-center ">
                <div class="font-bold mb-2 text-[20px]">Notification</div>
                <div class="font-bold text-[20px] mb-2 mt-6 ">Account Details</div>

                {/* <p className="text-[17px]">You have Rejected the redeem request of the customer.</p> */}
                <p className="text-[17px] font-medium">10% has been deducted from your account.</p>
                <div class="font-bold text-[20px] mb-2 mt-6 ">Remaining Balance</div>
                {/* <p>{isBalance}</p> */}
            </div>
        </div>
    )
}
export default DiscountNotification