import { Button, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useContext, useRef, useState } from "react";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { PiMinusCircleBold,PiPlusLight, PiWarningBold } from "react-icons/pi";
import axios from "axios";
import * as uuid from "uuid";
import firebase_service from "../../../../utils/firebase_service.js";
import { MyBusinessContext } from "../contexts/MyBusinessContext";
import FlutterInterface from "../../../../utils/flutter_interface.js";
import { toast, ToastContainer } from "react-toastify";
import LoadingModal from "../../../basic/LoadingModal.jsx";

const Label = function ({ htmlFor, text }) {
    return (
        <label htmlFor={htmlFor} className="font-medium text-[15px] my-1">{text}</label>
    )
}

const Error = function ({ text }) {
    return (
        text?.toString().length ? <div className="text-red-500 text-[14px] font-medium my-1">* {text}</div> : <></>
    )
}

const ErrorBox = function ({ text }) {
    if (!text?.length) {
        return <></>
    }
    return (
        <div className="bg-red-300 px-4 py-2 rounded-sm my-4 flex items-center gap-4">
            <PiWarningBold color="rgb(185, 28, 28)" size="20px" />
            <p className="text-red-700 text-[14px] font-medium">{text}</p>
        </div>
    )
}

function PostAddScreen() {
    const currencies = [
        {
            value: 'Single Purchase',
            label: 'Single Purchase',
        },
        ...Array.from({ length: 10 }, (_, index) => ({
            value: (index + 1).toString(),
            label: (index + 1).toString(),
        }))
    ];

    const mbCtx = useContext(MyBusinessContext);
    const [activeTab, setActiveTab] = useState('discount');
    const [isDiscount, setIsDiscount] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const imagesInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loadingText, setLoadingText] = useState(null)
    const [errors, setErrors] = useState({
        date: null,
        discountAmount: null,
        purchase: null,
        heading: null,
        description: null,
        api: null
    });
    const [imagePreviewModal, setImagePreviewModal] = useState({
        isOpen: false,
        imageFile: null,
      })
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const [selectedValue, setSelectedValue] = useState('Single Purchase');
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const onClickChooseFile = e => {
        setErrors({
          ...errors,
          images: null
        })
        imagesInputRef?.current?.click()
      }
      const onChooseFile = e => {
        const files = []
        const ALLOWED_SIZE = 10e+6
        const ALLOWED_FILETYPES = ['jpeg', 'jpg', 'png']
        const inputFiles = Array.from(e.target.files)
        if((inputFiles.length + selectedFiles.length) === 0) {
          setErrors({
            ...errors,
            images: 'Please choose some images'
          })
          return;
        }
        if((inputFiles.length + selectedFiles.length) > 10) {
          setErrors({
            ...errors,
            images: 'You can upload maximum 10 images'
          })
          return;
        }
        for (let file of inputFiles) {
          if(file.size > ALLOWED_SIZE) {
            setErrors({
              ...errors,
              images: 'Each image must be less than 10 mb'
            })
            return;
          }
          if(!ALLOWED_FILETYPES.map(item => `image/${item}`).includes(file.type)) {
            setErrors({
              ...errors,
              images: 'Allowed file types: ' + ALLOWED_FILETYPES.join(",")
            })
            return;
          }
          files.push({
            file: file,
            id: uuid.v4()
          })
        }
        setSelectedFiles([...selectedFiles, ...files])
        if(imagesInputRef?.current && imagesInputRef?.current?.value) {
          imagesInputRef.current.value = ""
        }
      }
      const onDeleteFile = id => {
        setSelectedFiles(
          selectedFiles.filter(file => file.id !== id)
        )
      }
      const bytesToMB = (bytes) => {
        if (bytes === 0) return '0 MB';
        const mb = bytes / (1024 * 1024);
        return `${mb.toFixed(1)} MB`;
      }
      const onClickImage = file => {
        setImagePreviewModal({
          isOpen: true,
          imageFile: file 
        })
      }



    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({
            date: null,
            discountAmount: null,
            heading: null,
            purchase: null,
            description: null,
            api: null
        });

        const _errors = {};
        const fd = new FormData(e.target);
        const discountAmount = parseFloat(fd.get('discount'));
        const heading = fd.get('heading');
        const description = fd.get('description');
        const purchase = selectedValue;

        if (activeTab === 'discount' && (isNaN(discountAmount) || discountAmount <= 0 || discountAmount > 100)) {
            _errors.discountAmount = 'Please enter a valid discount between 1 and 100';
        }
        if (!heading) {
            _errors.heading = 'This field is required';
        }
        if (!description) {
            _errors.description = 'This field is required';
        }
        if (Object.keys(_errors).length) {
            setErrors(_errors);
            return;
        }

        if (description.length > 200) {
            _errors.description = "Too long. Maximum characters allowed: 200";
        }
        if (heading.length > 100) {
            _errors.heading = "Too long. Maximum characters allowed: 100";
        }

        if (Object.keys(_errors).length) {
            setErrors(_errors);
            return;
        }

    // upload images 
    for await (let item of selectedFiles) {
        if(item.url) {
          continue;
        }
        const index = selectedFiles.indexOf(item)
        setLoadingText('Uploading image ' + (index + 1) + "/" + selectedFiles.length)
        await firebase_service.uploadFileOnFirebase({
          file: item.file,
          filename: `offers_images/business_${mbCtx.business?._id.toString()}/${Date.now()}-${item.file.name}`
        }).then(res => {
          setSelectedFiles(selectedFiles.map(file => {
            if(file.id === item.id) {
              file.url = res 
            }
            return file;
          }))
        })
      }
      setLoadingText("Publishing offer")
        const payload = {
            businessId: mbCtx.business._id,
            messages: [
                ...selectedFiles.filter(item => item.url).map(item => ({
                    type: 'image',
                    content: item.url,
                  })),
                {
                    type: 'text',
                    content: heading
                },
                {
                    type: 'text',
                    content: description,
                }
            ],
            offerType: activeTab,
            discount: discountAmount,
            heading,
            purchase,
            description,
            date: selectedDate ? selectedDate.toISOString() : new Date().toISOString(),
            meta: await FlutterInterface.getBasicInfo()
        };

        const loc = await FlutterInterface.getLocation();
        const url = new URL('https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/my-business/offers/publish');
        url.searchParams.set('tuid', mbCtx?.user?.uuid);
        if (loc?.lat && loc?.lng) {
            url.searchParams.set('lat', loc?.lat.toString());
            url.searchParams.set('lng', loc?.lng.toString());
        }
        axios.post(url, payload).then(res => {
            if (!res?.data) {
                throw new Error({ error: true, message: 'Something went wrong' });
            }
            if (res.data?.status !== 200) {
                throw res?.data;
            }
            setIsDiscount(false);
            if (activeTab === 'offer') {
                toast.success("Your Offer has been Published Successfully!")
            }
            console.log("form submitted successfully");
        }).catch(e => {
            let message = "Something went wrong";
            if (typeof e === 'object') {
                if (e.error) {
                    if (!e.isOperationalError) {
                        message = e.message ?? 'Something went wrong';
                    } else {
                        message = 'Something went wrong';
                    }
                }
            }
            setErrors({
                ...errors,
                api: message
            });
        }).finally(() => {
            setLoadingText(null)
          });
    };

    return (
        <div className="my-6 mx-3">
            <div className="lg:w-3/5 md:w-[95%] flex  flex-col flex-wrap mx-auto">
                <div className="flex justify-between">
                    <div className="text-bolder text-[25px]" style={{ fontWeight: '700' }}>POST ADD</div>
                    <p>DRAFT</p>
                </div>
                <div className=" mt-3" style={{ fontWeight: '500' }}>Discount offers get maximum response.</div>
                <div>
                    <div className="flex justify-between mt-4">
                        <div
                            className={`cursor-pointer text-[20px] ${activeTab === 'discount' ? 'font-bold border-b-2 border-black' : 'text-gray-400 font-bold'}`}
                            onClick={() => handleTabClick('discount')}
                        >
                            Discount
                        </div>
                        <div
                            className={`cursor-pointer text-[20px] ${activeTab === 'offer' ? 'font-bold border-b-2 border-black' : 'text-gray-400 font-bold'}`}
                            onClick={() => handleTabClick('offer')}
                        >
                            Offer
                        </div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="mt-4">
                        {activeTab === 'discount' && (
                            <div>
                                {!isDiscount ? (
                                    <div className="flex flex-col justify-center items-center my-6">
                                        <IoCheckmarkCircleSharp style={{ color: 'green', fontSize: 150, marginTop: '30px' }} />
                                        <div className="text-center mt-4 text-black font-bold">
                                            <p>Your ad has been submitted successfully.</p>
                                            <p>It usually takes 1-2 hours to go live.</p>
                                        </div>
                                        {/* <div className='text-white rounded-md px-3 py-2 mt-3 justify-center'>
                                            <button className="bg-[#2396F1] px-6 py-2 rounded-md">CONTINUE</button>
                                        </div> */}
                                    </div>
                                ) : (
                                    <div>
                                        <ErrorBox text={errors.api} />
                                        <form onSubmit={handleSubmit}>
                                            <div className="flex justify-center mb-3">
                                                <div className="w-[50%]">
                                                    <TextField id="filled-basic" name="discount" label="% Add Discount i.e 10" variant="filled" className="w-full" />
                                                </div>
                                                <p className="w-[50%] text-[11px] ml-2">Offered discount percentage(%) to customers from your business.</p>
                                            </div>
                                            <div className="flex justify-center mb-3">
                                                {/* <div className="w-[50%]">
                                                    <TextField
                                                        id="standard-select-currency"
                                                        select
                                                        defaultValue="USD"
                                                        variant="filled"
                                                        className="w-full pt-2"
                                                    >
                                                        {currencies.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div> */}
                                                <div className="w-[50%]">
                                                    <TextField
                                                        id="standard-select-currency"
                                                        select
                                                        value={selectedValue}
                                                        onChange={handleChange}
                                                        variant="filled"
                                                        className="w-full pt-2"
                                                    >
                                                        {currencies.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>

                                                <p className="w-[50%] text-[11px] ml-2">Customers can use their discount for one big purchase or spread it out over several smaller purchases.</p>
                                            </div>
                                            <div className="flex justify-center mb-3">
                                                <div className="w-[50%]">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="Validity"
                                                            value={selectedDate}
                                                            onChange={handleDateChange}
                                                            className="border-none border-b-2 outline-none w-full"
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="filled"
                                                                    className="border-none border-b-2 outline-none w-full"
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                <p className="w-[50%] text-[11px] ml-2">Set an end date for this discount. If you don't set an end date, the discount will stay active.</p>
                                            </div>


                                            <div className="border-[1px] border-gray-400 bg-gray-100 w-full h-[100px] p-2 border-dashed flex flex-col justify-center items-center cursor-pointer mb-2 rounded-md" onClick={onClickChooseFile}>
                                                <PiPlusLight size="20px" color="grey" className="mb-1" />
                                                <p className="text-[12px] text-gray-500">Upload Images</p>
                                                <input type="file" name="images" className="hidden" ref={imagesInputRef} accept="image/*" multiple onChange={onChooseFile} />
                                            </div>
                                            <Error text={errors.images} />
                                            {selectedFiles?.length ? <div className="overflow-x-auto">
                                                <ul className="flex">
                                                    {selectedFiles.map((item, index) => (
                                                        <li key={"preview-file-" + index} className="flex-none mr-2 my-2">
                                                            <div className="relative">
                                                                <img src={URL.createObjectURL(item.file)} className="w-[80px] h-[80px] object-cover rounded-md" onClick={e => onClickImage(item)} alt="" />
                                                                <div className="text-center">
                                                                    <span className="text-gray-400 text-[10px]">{bytesToMB(item.file.size)}</span>
                                                                </div>
                                                                <div className="absolute top-0 right-0 bg-red-500 rounded-full cursor-pointer" onClick={e => onDeleteFile(item.id)}>
                                                                    <PiMinusCircleBold size={15} color="white" />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div> : <></>}



                                            <div className="mb-3">
                                                <p className="text-[15px]">Heading (100 Characters)</p>
                                                <TextField id="filled-basic" name="heading" label="Get 10% Discount on Every item" variant="filled" className="w-full" />
                                            </div>
                                            <div className="mb-3">
                                                <p className="text-[15px]">Description (200 Characters)</p>
                                                <TextField id="filled-basic" name="description" label="Limited time discount Offer" multiline rows={4} variant="filled" className="w-full" />
                                            </div>
                                            <div className='text-white rounded-md px-3 py-2 mt-3 float-right'>
                                                <Button className="bg-[#2396F1] px-6 py-2 rounded-md" type="submit">PUBLISH</Button>
                                            </div>
                                        </form>
                                    </div>
                                )}
                                <LoadingModal isOpen={loadingText != null} title={loadingText} />
                            </div>
                        )}
                        {activeTab === 'offer' && (
                            <div>
                                <ErrorBox text={errors.api} />
                                <form onSubmit={handleSubmit}>
                                    <div className="flex justify-center mb-3">
                                        <div className="w-[50%]">
                                            <TextField
                                                id="standard-select-currency"
                                                select
                                                value={selectedValue}
                                                onChange={handleChange}
                                                variant="filled"
                                                className="w-full pt-2"
                                            >
                                                {currencies.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <p className="w-[50%] text-[11px] ml-2">Customers can use their discount for one big purchase or spread it out over several smaller purchases.</p>
                                    </div>
                                    <div className="flex justify-center mb-3">
                                        <div className="w-[50%]">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Validity"
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    variant="filled"
                                                    className="border-none border-b-2 outline-none w-full"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="filled"
                                                            className="w-full"
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <p className="w-[50%] text-[11px] ml-2">Set an end date for this discount. If you don't set an end date, the discount will stay active.</p>
                                    </div>

                                    <div className="border-[1px] border-gray-400 bg-gray-100 w-full h-[100px] p-2 border-dashed flex flex-col justify-center items-center cursor-pointer mb-2 rounded-md" onClick={onClickChooseFile}>
                                                <PiPlusLight size="20px" color="grey" className="mb-1" />
                                                <p className="text-[12px] text-gray-500">Upload Images</p>
                                                <input type="file" name="images" className="hidden" ref={imagesInputRef} accept="image/*" multiple onChange={onChooseFile} />
                                            </div>
                                            <Error text={errors.images} />
                                            {selectedFiles?.length ? <div className="overflow-x-auto">
                                                <ul className="flex">
                                                    {selectedFiles.map((item, index) => (
                                                        <li key={"preview-file-" + index} className="flex-none mr-2 my-2">
                                                            <div className="relative">
                                                                <img src={URL.createObjectURL(item.file)} className="w-[80px] h-[80px] object-cover rounded-md" onClick={e => onClickImage(item)} alt="" />
                                                                <div className="text-center">
                                                                    <span className="text-gray-400 text-[10px]">{bytesToMB(item.file.size)}</span>
                                                                </div>
                                                                <div className="absolute top-0 right-0 bg-red-500 rounded-full cursor-pointer" onClick={e => onDeleteFile(item.id)}>
                                                                    <PiMinusCircleBold size={15} color="white" />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div> : <></>}


                                    <div className="mb-3">
                                        <p className="text-[15px]">Heading (100 Characters)</p>
                                        <TextField id="filled-basic" name="heading" label="Get 10% Discount on Every item" variant="filled" className="w-full" />
                                    </div>
                                    <div className="mb-3">
                                        <p className="text-[15px]">Description (200 Characters)</p>
                                        <TextField id="filled-basic" name="description" label="Limited time discount Offer" multiline rows={4} variant="filled" className="w-full" />
                                    </div>
                                    <div className='text-white rounded-md px-3 py-2 mt-3 float-right'>
                                        <Button type="submit" className="bg-[#2396F1] px-6 py-2 rounded-md">PUBLISH</Button>
                                    </div>
                                </form>
                            </div>
                        )}
                        <LoadingModal isOpen={loadingText != null} title={loadingText} />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default PostAddScreen;
