import { useCallback, useContext, useEffect, useState } from "react";
import {
  LAMBDA_API_BUSINESS_PORTAL_URL,
  MyBusinessContext,
} from "../contexts/MyBusinessContext.jsx";
import InfiniteScroll from "react-infinite-scroll-component";
import { CiMenuKebab } from "react-icons/ci";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { AppImages } from "../../../../Asset/images/image.js";
import { toast } from "react-toastify";
import Loading from "../../../basic/Loading.jsx";
import { createActivityLog, formatNumber } from "../../../../utils/helpers.js";

function SingleBusinessScreen1() {
  const ctx = useContext(MyBusinessContext);
  const [isFetchingStats, setIsFetchingStats] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [hasMore, setHasMore] = useState(true);
  const [openDialogIndex, setOpenDialogIndex] = useState(null);

   const handleMenuClick = (index) => {
    // Toggle: If the dialog is already open for the clicked index, close it; otherwise, open it.
    setOpenDialogIndex((prevIndex) => (prevIndex === index ? null : index));
  };

   const fetchData = useCallback(() => async function () {
    
     try {
       let business_id = ctx.business?.id || ctx.env?.business_id;
      // let business_id = '6414624dd37934518eefc4c1'

       if (!business_id) {
         throw new Error("No business id");
       }

      const response = await axios.get(
        `${LAMBDA_API_BUSINESS_PORTAL_URL}/app/my-business/products/get?business_id=${business_id}&page=${currentPage}`
      );


       console.log("Dataaa", response);
       const newData = response.data.data.products; // Use response.data directly

       if (newData.length > 0) {
         // Append the new data to the existing data
         setData([...data, ...newData]);
         setCurrentPage(currentPage + 1);
       } else {
         // No more data available
         setHasMore(false);
       }
      //  setData(response.data.data.products);

       if (response.data?.status !== 200) {
         throw response.data;
       } else {
         // Your other logic here
       }

       // Set isFetchingStats to false when the response is received
       setIsFetchingStats(false);
     } catch (error) {
       console.error(error);

       if (error?.error) {
         toast.error(
           error?.isOperationalError ? "Something went wrong" : error?.message,
           {
             position: toast.POSITION.TOP_RIGHT,
           }
         );
       }

       // Set isFetchingStats to false when there is an error
       setIsFetchingStats(false);
     }
   }, [ctx.business?.id, ctx.env?.business_id, currentPage, data])
   async function checkRole(){
      try {
        const apiUrl = 'https://crm-lara-mongo-7azts5zmra-uc.a.run.app/api/webhook-fav-haqooq-bus';
        const params = {
          user_type: 'subrole',
          uuid: '94613fa8-1a65-48ab-93ea-a759f044f8e8',
          sub_role: 'worker',
        };

        await axios.get(apiUrl, { params });
        
      } catch (error) {
        console.log("Error",error)
      }
   }
 useEffect(() => {
   setIsFetchingStats(true);
   checkRole();
   fetchData(); // Call the async function inside useEffect
 }, [fetchData]);
  return (
    <>
     <div>
                      <div className="flex flex-col items-center justify-center ">
                        <div>
                          {
                            ctx?.business?.id === ''? (
                               <Link to="/app/single-business/item">
                           <button
                             className="bg-[#2196F3] rounded-md p-2 mb-2 text-white font-normal text-[16px] w-[350px] mt-[5px]"
                           >
                           Haqooq e khalq
                           </button>
                          </Link>
                            ) : ('')
                          }
                        </div>
                          <div>     
                      {isFetchingStats ? (
                        <div className="text-black">
                          Loading...
                          <Loading />
                        </div>
                      ) : (
                        <div>
                          {
                          data.length > 0 ? (
                            <div>
                            <div>
                          <Link to="/app/single-business/item">
                           <button
                             className="bg-[#2196F3] rounded-md p-2 mb-2 text-white font-normal text-[16px] w-[350px] mt-[5px]"
                           >
                           Add a new item
                           </button>
                          </Link>
                        </div>
                          <InfiniteScroll
                            dataLength={data.length}
                            next={fetchData}
                            hasMore={hasMore}
                            loader=<Loading /> // You can customize the loading indicator
                          >
                         
                            {data?.map((item, index) => (
                              <div key={index} className="w-full flex flex-row border-b border-gray-300 mt-3">
          
    <div className="w-full flex flex-row">
      <div className="w-[25%]">
        <div className="w-[50px] h-[50px] bg-slate-100 rounded-full">
          {item.images.length > 0 ? (
            <img
              src={item.images[0]}
              alt=""
              className="h-full w-full rounded-full object-cover shadow-md"
            />
          ) : (
            <div className="bg-slate-100 rounded-full text-[22px] font-semibold w-full h-full flex items-center justify-center">
              {item?.name.slice(0, 2)}
            </div>
          )}
        </div>
      </div>
      <div className=" flex flex-row w-[75%] relative"> {/* Add padding to create space */}
      <div className="flex flex-col w-full">
        <div className="text-[16px] font-semibold text-[#181D27]">
          {item?.name}
        </div>
        <div className="text-[13px] text-[#464A52]">
          {!item?.description ? '' : item?.description}
        </div>
        <div className="text-[13px] text-[#464A52]">
          {item?.price?.amount
            ? `Rs. ${formatNumber(item?.price.amount)}`
            : ""}
        </div>
        <div className="text-[13px] text-[#464A52]">
          {!item?.discount ? '' : 'Discount : ' + item?.discount+'%'}
        </div>
        <div className="text-[13px] ">
          {
            !item.discounted_price ? (''):(<div className="flex flex-row">  <div className="text-[#464A52]">
               Price after Discount <span className="text-[#00C851]">{item.discounted_price.amount} {item.discounted_price.currency}</span>
          </div>
         </div>)
          } 
        </div>
         <div className="text-[13px] text-[#464A52]">
          {item?.availability===1 ? 'Not Availble' : 'Available'}
        </div>
      </div>
      <div><CiMenuKebab onClick={() => handleMenuClick(index)} /></div>
      </div>
    </div>
  

          {/* Dialog/Menu component */}
          {openDialogIndex === index && (
            <div className="your-dialog-style bg-slate-100 absolute right-6 mt-3 w-[230px] rounded-lg border-[1px] border-slate-300 ">
              {/* Add your menu content here using item and index */}
              <Link
    to={{
      pathname: `/app/single-business/businessproduct`,
    }}
    onClick={()=> createActivityLog('wv_mb_clicked_on_product_card', item._id).then(res => console.log(res, item._id))}
    state={{ item: item }}
  ><div className="p-2">View</div></Link>
 
            </div>
          )}
{/* </div> */}

                               
                              </div>
                            ))}
                          </InfiniteScroll></div>):( 
                       <div className="text-center">
        <img src={AppImages.itemImage} alt="" className="" />
        <div className="text-[#464A52] text-[16px] mt-[28px]">
          <div>Items are products. Add a title and price for users to order, or use them to build a menu.</div>
          <div className="mt-[1px]">to order, or use them to build a menu.</div>
        </div>
          <Link to="/app/single-business/item">
                           <button
            className="bg-[#2196F3] rounded-md p-2 mb-2 text-white font-normal text-[16px] w-full mt-[37px]"
          >
            Add a new item
          </button>
                          </Link>
    
      </div>)
                        } 
                        </div>
                      )}
                       
     
    
                      </div>
                    </div>
           
          </div>
        
     
    </>
  );
}

export default SingleBusinessScreen1;
