import { useContext, useState } from "react";
import {
  LAMBDA_API_BUSINESS_PORTAL_URL,
  MyBusinessContext,
} from "../contexts/MyBusinessContext.jsx";
import { CiMenuKebab } from "react-icons/ci";
import "react-tabs/style/react-tabs.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { formatNumber } from "../../../../utils/helpers.js";
import BackArrow from "../BackArrow.jsx";

function MenuScreen() {
  const location = useLocation();
  const { item } = location.state || {}; // Initialize with an empty object
  const ctx = useContext(MyBusinessContext);
  const [openDialogIndex, setOpenDialogIndex] = useState(null);

  const handleMenuClick = (index) => {
    setOpenDialogIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleMenuClose = () => {
    setOpenDialogIndex(null);
  };
  
  const [products, setProducts] = useState(item.products);
  
  if (!item) {
    return <div>Item not found</div>;
  }
  
  // if (item.products.length > 0 ){
  //   setProducts(item.products)
  //   console.log("Productsss",products)
  // }
  
    const deleteProduct = async (productId) => {
    // Filter out the product with the specified _id
    const updatedProducts = products.filter(product => product._id !== productId);
    // Update the state with the new array of products
    setProducts(updatedProducts);
    try {
      // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
      const response = await axios.post(`${LAMBDA_API_BUSINESS_PORTAL_URL}/app/my-business/products/menu/remove-item`, {
        menu_id: item._id,  // Replace with the actual menu_id
        product_id: productId,
      });

      // Handle success, e.g., update state, close the dialog, etc.
      console.log('Item deleted successfully', response.data);
    //  navigate("/app/menu")
      // Close the dialog after successful deletion
      // setOpenDialogIndex(null);
    } catch (error) {
      // Handle error, e.g., show an error message
       if (error?.error) {
          toast.error(
            error?.isOperationalError ? "Something went wrong" : error?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      console.error('Error deleting item:', error.message);
    }
  };

  //  const handleDeleteItem = async (productId) => {
  //   // console.log("ProductIdddd",productId)
  //   try {
  //     // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
  //     const response = await axios.post(`${LAMBDA_API_BUSINESS_PORTAL_URL}/app/my-business/products/menu/remove-item`, {
  //       menu_id: item._id,  // Replace with the actual menu_id
  //       product_id: productId,
  //     });

  //     // Handle success, e.g., update state, close the dialog, etc.
  //     console.log('Item deleted successfully', response.data);
  //    navigate("/app/menu")
  //     // Close the dialog after successful deletion
  //     // setOpenDialogIndex(null);
  //   } catch (error) {
  //     // Handle error, e.g., show an error message
  //     console.error('Error deleting item:', error.message);
  //   }
  // };
//  useEffect(() => {
//    setIsFetchingStats(true);
//    fetchData(); // Call the async function inside useEffect
//  }, []);


  return (
    <>
      <div>
        
          <div className="mx-2">
            <div><BackArrow /></div>
            <div className="text-[23px] font-bold">{ctx?.business.name}</div>
            <div className="text-[13px]">
              <span>{ctx.business.category} | {ctx.business.phone}</span>
            </div>
                            <div>
                           <img src={item.image_url} alt="" className=" mt-3" />
        <div className='text-[19px] font-semibold mt-2'>{item.title}</div>
        <div className='text-[13px] mt-[1px]'>{item.description}</div>
        <div className='text-[13px] font-semibold mt-2'>Items</div>
                        </div>
                        {
                          item.products.length > 0  ?(       
                            <div>{products?.map((item, index) => (
                              <div key={index} className="w-full flex flex-row border-b border-gray-300 mt-3 pb-3">
                      
                                    <div className="w-full flex flex-row">
                                      <div className="w-[25%]">
                                        <div className="w-[50px] h-[50px] bg-slate-100 rounded-full">
                                          {item.images.length > 0 ? (
                                            <img
                                              src={item.images[0]}
                                              alt=""
                                              className="h-full w-full rounded-full object-cover shadow-md"
                                            />
                                          ) : (
                                            <div className="bg-slate-100 rounded-full text-[22px] font-semibold w-full h-full flex items-center justify-center">
                                              {item?.name.slice(0, 2)}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className=" flex flex-row w-[75%] relative"> {/* Add padding to create space */}
                                       <div className="flex flex-col w-full">
                                        <div className="text-[16px] font-semibold text-[#181D27]">
                                          {item?.name}
                                        </div>
                                        <div className="text-[13px] text-[#464A52]">
                                          {!item?.description ? '' : item?.description}
                                        </div>
                                        <div className="text-[13px] text-[#464A52]">
                                          {item?.price?.amount
                                            ? `Rs. ${formatNumber(item?.price.amount)}`
                                            : ""}
                                        </div>
                                        <div className="text-[13px] text-[#464A52]">
                                          {!item?.discount ? '' : 'Discount : ' + item?.discount+'%'}
                                        </div>
                                        <div className="text-[13px] ">
                                          {
                                            !item.discounted_price ? (''):(<div className="flex flex-row">  <div className="text-[#464A52]">
                                              Price after Discount <span className="text-[#00C851]">{item.discounted_price.amount} {item.discounted_price.currency}</span>
                                          </div>
                                        </div>)
                                          } 
                                        </div>
                                        <div className="text-[13px] text-[#464A52]">
                                          {item?.availability===1 ? 'Not Available' : 'Available'  }
                                        </div>
                                        </div>
                                        <div><CiMenuKebab onClick={() => handleMenuClick(index)} /></div>
                                      </div>
                                    </div>
                                  {/* </Link> */}
                                  {/* <div className="w-[10%] flex justify-end">
                                            
                                          </div> */}

                                          {/* Dialog/Menu component */}
                                          {openDialogIndex === index && (
                                            <div className="your-dialog-style bg-slate-100 absolute right-6 mt-2 w-[230px] rounded-lg border-[1px] border-slate-300 text-[16px]">
                                              {/* Add your menu content here using item and index */}
                                              <Link
                                    to={{
                                      pathname: `/app/single-business/businessproduct`,
                                    }}
                                    state={{ item: item }}
                                  ><div className="p-2">View</div></Link>
                                  <div className="border-[1px] border-black px-2"></div>
                                  <div className="p-2" onClick={() => deleteProduct(item._id)}>Delete</div>
                                  <div className="border-[1px] border-black px-2"></div>
                                              {/* Add a close button or use any other mechanism to close the menu */}
                                              <button onClick={handleMenuClose} className="p-2">Close</button>
                                            </div>
                                          )}
                               
                              </div>
                            ))}</div>                      
                             ): (<div className="text-center text-[18px] mt-4"> No items in this menu</div>)
                        }                         
                        </div>

                    </div>
    
    </>
  );
}

export default MenuScreen;
