import { Button } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { PiArrowLeftBold, PiCheckCircleBold, PiMinusCircleBold, PiPlusLight, PiWarningBold } from "react-icons/pi"
import * as uuid from "uuid"
import LoadingModal from "../../../basic/LoadingModal.jsx";
import SimpleModal from "../../../basic/SimpleModal.jsx";
import firebase_service from "../../../../utils/firebase_service.js";
import { MyBusinessContext } from "../contexts/MyBusinessContext.jsx";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FlutterInterface from "../../../../utils/flutter_interface.js";

const Label = function({ htmlFor, text }) {
  return (
    <label htmlFor={htmlFor} className="font-medium text-[15px] my-1">{text}</label>
  )
}

const Error = function({text}) {
  return (
    text?.toString().length ? <div className="text-red-500 text-[14px] font-medium my-1">* {text}</div> : <></>
  )
}

const ErrorBox = function({text}) {
  if(!text?.length) {
    return <></>
  }
  return (
    <div className="bg-red-300 px-4 py-2 rounded-sm my-4 flex items-center gap-4">
      <PiWarningBold color="rgb(185, 28, 28)" size="20px" />
      <p className="text-red-700 text-[14px] font-medium">{text}</p>
    </div>
  )
}

function CreateOfferScreen() {
  const navigate = useNavigate()
  const mbCtx = useContext(MyBusinessContext)
  const discountAmountRef = useRef(null)
  const imagesInputRef = useRef(null)
  const [offerType, setOfferType] = useState('offer')
  const [selectedFiles, setSelectedFiles] = useState([])
  const [loadingText, setLoadingText] = useState(null)
  const [imagePreviewModal, setImagePreviewModal] = useState({
    isOpen: false,
    imageFile: null,
  })
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    content: null 
  })
  const defaultErrors = {
    images: null,
    discountAmount: null,
    heading: null,
    description: null,
    api: null 
  }
  const [errors, setErrors] = useState(defaultErrors)
  const onChangeOfferType = e => {
    setOfferType(e.target.value) 
    if(e.target.value === 'discount') {
      discountAmountRef?.current?.focus()
    }
  }
  const onClickChooseFile = e => {
    setErrors({
      ...errors,
      images: null
    })
    imagesInputRef?.current?.click()
  }
  const onChooseFile = e => {
    const files = []
    const ALLOWED_SIZE = 10e+6
    const ALLOWED_FILETYPES = ['jpeg', 'jpg', 'png']
    const inputFiles = Array.from(e.target.files)
    if((inputFiles.length + selectedFiles.length) === 0) {
      setErrors({
        ...errors,
        images: 'Please choose some images'
      })
      return;
    }
    if((inputFiles.length + selectedFiles.length) > 10) {
      setErrors({
        ...errors,
        images: 'You can upload maximum 10 images'
      })
      return;
    }
    for (let file of inputFiles) {
      if(file.size > ALLOWED_SIZE) {
        setErrors({
          ...errors,
          images: 'Each image must be less than 10 mb'
        })
        return;
      }
      if(!ALLOWED_FILETYPES.map(item => `image/${item}`).includes(file.type)) {
        setErrors({
          ...errors,
          images: 'Allowed file types: ' + ALLOWED_FILETYPES.join(",")
        })
        return;
      }
      files.push({
        file: file,
        id: uuid.v4()
      })
    }
    setSelectedFiles([...selectedFiles, ...files])
    if(imagesInputRef?.current && imagesInputRef?.current?.value) {
      imagesInputRef.current.value = ""
    }
  }
  const onDeleteFile = id => {
    setSelectedFiles(
      selectedFiles.filter(file => file.id !== id)
    )
  }
  const bytesToMB = (bytes) => {
    if (bytes === 0) return '0 MB';
    const mb = bytes / (1024 * 1024);
    return `${mb.toFixed(1)} MB`;
  }
  const onClickImage = file => {
    setImagePreviewModal({
      isOpen: true,
      imageFile: file 
    })
  }
  const showSuccessModal = () => {
    setInfoModal({
      isOpen: true,
      content: <>
        <div>
          <PiCheckCircleBold color="green" size="40px" className="my-2 mx-auto" />
          <p className="text-green-700 text-center text-[20px] font-medium mb-4">Published</p>
          <p className="text-center text-gray-600">Your offer has been published successfully!</p>
          <div className="h-[50px]"></div>
          <div className="flex justify-end">
            <Button onClick={e => {
              setInfoModal({
                isOpen: false,
                content: null,
              })
              navigate(-1)
            }}>Go Back</Button>
          </div>
        </div>
      </>
    })
  }
  const onSubmit = async e => {
    e.preventDefault()
    setErrors(defaultErrors)
    
    const _errors = {}
    const fd = new FormData(e.target)
    const offerType = fd.get('offer_type')
    const discountAmount = parseFloat( fd.get('discount') )
    const heading = fd.get('heading')
    const description = fd.get('description')

    if(!selectedFiles.length) {
      _errors.images = "Please choose some images"
    }
    if(offerType === 'discount' && (isNaN(discountAmount) || discountAmount <= 0 || discountAmount > 100) ) {
      _errors.discountAmount = 'Please enter a valid discount between 1 and 100'
    }
    if(!heading) {
      _errors.heading = 'This field is required'
    }
    if(!description) {
      _errors.description = 'This field is required'
    }
    if(Object.keys(_errors).length) {
      setErrors(_errors)
      return;
    }

    if(description.length > 200) {
      _errors.description = "Too long. Maximum characters allowed: 200"
    }
    if(heading.length > 100) {
      _errors.heading = "Too long. Maximum characters allowed: 100"
    }

    if(Object.keys(_errors).length) {
      setErrors(_errors)
      return;
    }

    // upload images 
    for await (let item of selectedFiles) {
      if(item.url) {
        continue;
      }
      const index = selectedFiles.indexOf(item)
      setLoadingText('Uploading image ' + (index + 1) + "/" + selectedFiles.length)
      await firebase_service.uploadFileOnFirebase({
        file: item.file,
        filename: `offers_images/business_${mbCtx.business?._id.toString()}/${Date.now()}-${item.file.name}`
      }).then(res => {
        setSelectedFiles(selectedFiles.map(file => {
          if(file.id === item.id) {
            file.url = res 
          }
          return file;
        }))
      })
    }

    setLoadingText("Publishing offer")

    const payload = {
      businessId: mbCtx.business._id,
      messages: [
        ...selectedFiles.filter(item => item.url).map(item => ({
          type: 'image',
          content: item.url,
        })),
        {
          type: 'text',
          content: heading
        },
        {
          type: 'text',
          content: description,
        }
      ],
      offerType,
      discount: discountAmount,
      heading,
      description,
      date: new Date(),
      meta: await FlutterInterface.getBasicInfo()
    }

    const loc = await FlutterInterface.getLocation()
    const url = new URL('https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/app/my-business/offers/publish')
    url.searchParams.set('tuid', mbCtx?.user?.uuid)
    if(loc?.lat && loc?.lng) {
      url.searchParams.set('lat', loc?.lat.toString())
      url.searchParams.set('lng', loc?.lng.toString())
    }
    axios.post(url, payload).then(res => {
      if(!res?.data) {
        throw new Error({error: true, message: 'Something went wrong'})
      }
      if(res.data?.status !== 200) {
        throw res?.data
      }
      showSuccessModal()
    }).catch(e => {
      let message = "Something went wrong"
      if(typeof e === 'object') {
        if(e.error) {
          if(!e.isOperationalError) {
            message = e.message ?? 'Something went wrong'
          } else {
            message = 'Something went wrong'
          }
        }
      }
      setErrors({
        ...errors,
        api: message 
      })
    }).finally(() => {
      setLoadingText(null)
    })
    
  }
  return (  
    <>
      <div>
        <div className="bg-white p-2 flex items-center gap-2 w-full shadow-sm mb-2">
          <div className="w-[25px] h-[25px] flex justify-center items-center" onClick={e => navigate(-1)}>
            <PiArrowLeftBold />
          </div>
          <div className="flex justify-center items-center w-full">
            <p className="text-center text-[18px] font-medium">Offers & Discounts</p>
          </div>
        </div>
      </div>
      <div className="m-2">
        <ErrorBox text={errors.api} />
        <form onSubmit={onSubmit}>
          <div className="border-[1px] border-gray-400 bg-gray-100 w-full h-[100px] p-2 border-dashed flex flex-col justify-center items-center cursor-pointer mb-2 rounded-md" onClick={onClickChooseFile}>
            <PiPlusLight size="20px" color="grey" className="mb-1" />
            <p className="text-[12px] text-gray-500">Upload Images</p>
            <input type="file" name="images" className="hidden" ref={imagesInputRef} accept="image/*" multiple onChange={onChooseFile} />
          </div>
          <Error text={errors.images} />
          {selectedFiles?.length ? <div className="overflow-x-auto">
            <ul className="flex">
              {selectedFiles.map((item,index) => (
                <li key={"preview-file-" + index} className="flex-none mr-2 my-2">
                  <div className="relative">
                    <img src={URL.createObjectURL(item.file)} className="w-[80px] h-[80px] object-cover rounded-md" onClick={e => onClickImage(item)} alt="" />
                    <div className="text-center">
                      <span className="text-gray-400 text-[10px]">{bytesToMB(item.file.size)}</span>
                    </div>
                    <div className="absolute top-0 right-0 bg-red-500 rounded-full cursor-pointer" onClick={e => onDeleteFile(item.id)}>
                      <PiMinusCircleBold size={15} color="white" />
                    </div>
                  </div>
                </li>
              ))}  
            </ul>
          </div> : <></>}
          <div className="mb-3 mt-4">
            <div className="py-1">
              <input type="radio" value="offer" id="offer" name="offer_type" onChange={onChangeOfferType} className="mr-2" checked={offerType === 'offer'} />
              <Label text="Offer" htmlFor={'offer'} />
            </div>
            <div className="py-1">
              <input type="radio" value="discount" id="discount" name="offer_type" onChange={onChangeOfferType} className="mr-2" checked={offerType === 'discount'} />
              <Label text="Discount" htmlFor={'discount'} />
            </div>
          </div>
          {offerType === 'discount' && <div className="my-3">
            {/* <p className="text-gray-500 text-[14px] my-1">The minimum discount to be offered is <span className="font-medium">5%</span></p> */}
            <div className="relative">
              <input type="number" name="discount" id="discount" ref={discountAmountRef} className={"border-[1px] border-gray-800 py-2 pl-10 pr-2 w-full rounded-md outline-blue " + (errors?.discountAmount ? "border-red-500" : "")} min={1} max={100} step={1} placeholder="Add discount i.e. 5" />
              <div className="absolute left-4 text-gray-300 top-[50%] translate-y-[-50%]">%</div>
            </div>
            <Error text={errors?.discountAmount} />
          </div>}
          <div className="my-3">
            <Label htmlFor={"heading"} text="Heading" />
            <input type="text" name="heading" id="heading" className={"border-[1px] border-gray-800 p-2 w-full rounded-md outline-blue " + (errors?.heading ? "border-red-500" : "")} placeholder="Add heading" />
            <Error text={errors?.heading} />
          </div>
          <div className="my-3">
            <label htmlFor="description" className="font-medium text-[15px] my-1">Description</label>
            <textarea name="description" id="description" className={"w-full rounded-md border-[1px] border-gray-800 p-2 outline-blue resize-none " + (errors?.description ? "border-red-500" : "")} rows={4} placeholder="Describe your offer in brief detail"></textarea>
            <Error text={errors?.description} />
          </div>
          <div className="my-2 flex justify-end">
            <Button variant="contained" type="submit">Publish</Button>
          </div>
        </form>
      </div>
      <LoadingModal isOpen={loadingText != null} title={loadingText} />
      <SimpleModal
        isOpen={imagePreviewModal.isOpen && imagePreviewModal.imageFile != null}
      >
        {imagePreviewModal.imageFile && <div>
          <img src={URL.createObjectURL(imagePreviewModal.imageFile?.file)} alt="Preview" className="max-w-[80%] max-h-[80%] block mx-auto object-contain" />
        </div>}
        <div className="flex justify-end mt-4">
          <Button onClick={e => setImagePreviewModal({
            imageFile: null,
            isOpen: false
          })}>Close</Button>
        </div>
      </SimpleModal>
      <SimpleModal isOpen={infoModal.isOpen && infoModal.content !== null}>
        {infoModal.content}
      </SimpleModal>
    </>
  );
}

export default CreateOfferScreen;