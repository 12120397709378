import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Loading from "../../basic/Loading.jsx";
import { getFirstLetters, isValidUrl } from "../../../utils/helpers.js";
import FlutterInterface from "../../../utils/flutter_interface.js";
import { Button } from "@mui/material";
import { AppImages } from "../../../Asset/images/image.js";

function OfferWebviewScreen() {
  const [searchParams] = useSearchParams()
  const tag = searchParams.get('query_cat')
  const [isLoading, setIsLoading] = useState(false)
  const [markers, setMarkers] = useState([])
  const [error, setError] = useState(null)

  async function callApi() {
    return {
      error: true,
      message: "No such businesses are found near your area" 
    }
    // try {
      // const url = new URL('https://rogvftzrsuaealt3f7htqchmfa0zfumz.lambda-url.eu-west-1.on.aws/get-related-markers-by-tag')
      // url.searchParams.set('tag', tag)
      // url.searchParams.set('lat', searchParams.get('lat'))
      // url.searchParams.set('lng', searchParams.get('lng'))
      // const res = await axios.get(url)
      // if(res?.status == 200 && res?.data?.status == 200 && res?.data?.data?.markers?.length) {
      //   return res?.data?.data?.markers
      // } 
      // throw res 
    // } catch(e) {
      // let message = "No businesses found near you"
      // if(tag?.toString().length) {
      //   message = `No "${tag.toString()}" businesses found near you`
      // }
      // if(typeof e === 'object' && e?.data?.error) {
      //   if(e?.isOperationError) {
      //     message = "Something went wrong"
      //   } else {
      //     if(['lat is required', 'lng is required', 'Invalid coordinates'].includes(e?.data?.message)) {
      //       message = "Please enable your location"
      //     } else {
      //       message = e?.data?.message 
      //     }
      //   }
      // }
      // return {
      //   error: true,
      //   message: message 
      // }
    // }
  }

  function handleClickOnSingleCard(marker) {
    console.log(marker._id)
    FlutterInterface.goToBusinessScreen(marker._id.toString())
  }

  useEffect(() => {
    if(tag?.toString().length) {
      setError(null)
      setIsLoading(true)
      callApi().then(res => {
        console.log(res)
        if(res?.error) {
          setError(res?.message)
        } else if (Array.isArray(res)) {
          if(!res.length) {
            setError('No related businesses found near you')
          } else {
            setMarkers(res)
          }
        }
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }, [tag])

  if(!tag || !tag.toString().length) {
    return (
      <main>
        <Info text="Something went wrong" />
      </main>
    )
  }

  return (  
    <main>
      { !markers?.length ? <></> : <div>
        <div className="mb-10">
          <ul>
            { markers.map((marker, index) => (
              <li key={`marker-${index}`} onClick={e => handleClickOnSingleCard(marker)}>
                <div className="flex gap-2 mx-2 my-2 p-2 border-gray-300 border-[1px] bg-gray-50 rounded">
                  <div className="flex-[1_1_20%]">
                    { 'photo_urls' in marker && marker.photo_urls?.toString().length && isValidUrl(marker.photo_urls) ? <div className="">
                      <img src={marker.photo_urls} className="bg-cover w-[60px] h-[60px] block mx-auto rounded-md" onError={e => e.target.src = AppImages.mapIcon} alt='' />
                    </div> : <div>
                      <div className="w-[60px] h-[60px] bg-gray-200 flex justify-center items-center rounded-md mx-auto">
                        <p>{getFirstLetters(marker.name?.toString().replace("name-", ""))}</p>
                      </div>
                    </div> }
                  </div>
                  <div className="flex-[1_1_80%]">
                    {'distance' in marker ? <p className="text-[13px] font-semibold text-gray-700">{marker.distance} {marker.distanceUnit}</p> : <></>}
                    <p>{marker.name?.toString().replace("name-", "")}</p>
                    { 'address' in marker && marker.address?.toString().length ? <p className="text-[12px] text-gray-700">{marker.address}</p> : <></> }
                  </div>
                </div>
              </li>
            )) }
          </ul>
        </div>
      </div> }
      { isLoading ? <Loading /> : <></> }
      { error ? <Info text={error} /> : <></> }
    </main>
  );
}

export default OfferWebviewScreen;

function Info({ text }) {
  function onRefresh() {
    window.location.reload()
  }
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="bg-gray-50 border-gray-200 border-[1px] px-4 py-2 rounded min-w-[95vw]">
        <p className="text-black mb-6 text-left text-[18px] font-medium">{text}</p>
        <div className="flex justify-end">
          <Button variant="contained" onClick={onRefresh} sx={{ background: '#555' }}>Refresh</Button>
        </div>
      </div>
    </div> 
  )
}