import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loading from './basic/Loading';

const BusinessReportDetail = () => {
    const location = useLocation();
  const { item } = location.state || {}; // Initialize with an empty object
  console.log("ItemDataaa",item)
    const [data, setData] = useState([]);
     const [isFetchingStats, setIsFetchingStats] = useState(false);
    const formatDateTime = (createdAt) => {
  const options = { 
    day: 'numeric', 
    month: 'numeric', 
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  };

  return new Date(createdAt).toLocaleString('en-GB', options);
};

  useEffect(() => {
    setIsFetchingStats(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://7b7xlap5jvkahyo5himfrzqy640qnadr.lambda-url.eu-west-1.on.aws/crm/business/get-subs-date-wise?business_id=${item._id}`);
        console.log("ResponseDataaa",response)
        setData(response.data.data.subs);
        setIsFetchingStats(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsFetchingStats(false);
      }
    };

    fetchData();
  }, []);

  console.log("Dataaa",data)
  if (!item) {
    return <div>Item not found</div>;
  }
  
  return (
    <div>
        
    <div>
          <div className='flex flex-row bg-slate-500 text-white justify-between w-full py-2  border-b border-solid px-2 border-slate-500'>
                          <div className='w-[30%] font-semibold text-[18px]'>
                                    Date
                                  </div>
                                  <div className='w-[30%] font-semibold text-[18px]'>
                                    Total Subscribers
                                  </div>
                                  <div className='w-[30%] font-semibold text-[18px]'>
                                    Time
                                  </div>
                                  </div>
                                   {isFetchingStats ? (
                        <div>
                          <Loading />
                        </div>
                      ) : (
                                  <div>
                                  {data?.length === 0 ? (<div className='text-center font-semibold text-[22px] my-5'>No Subscribers</div>):(     <div>
        {data?.map((item, index) => (
        <div className='flex flex-row justify-between w-full border-b border-solid px-2 py-2 border-slate-500'>
        <div className='w-[30%]'>{item.date}</div>
        <div className='w-[30%]'>{item.count}</div>
        <div className='w-[30%]'>{item.createdAt.map((itemm, index)=>(
           <div>{ itemm ? formatDateTime(itemm) : 'N/A'}</div> 
        ))}</div>
        </div>
    ))}
    </div>) }
    </div>)}
                             
    </div>
    </div>
  )
}

export default BusinessReportDetail