import { useContext,  useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppImages } from "../../../../Asset/images/image.js";
import ReactModal from "react-modal";
import firebase_service from "../../../../utils/firebase_service.js";
import {
  
  MyBusinessContext,
} from "../contexts/MyBusinessContext.jsx";
import AppToastContainer from "../../../basic/AppToast.jsx";
import FlutterInterface from "../../../../utils/flutter_interface.js";
import { isIOS } from "../../../../utils/helpers.js";
import MBUploadPhotos1 from "./MBUploadPhotos1.jsx";

function Onecallkey() {
  const ctx = useContext(MyBusinessContext);
  const navigate = useNavigate();
  const [showSuccessModal] = useState(false);

  //   console.log("isIos?", isIOS());
  const isApple = useMemo(() => isIOS(), []);
  const fields = {
    description: null,
    // category: null,
    name: null,
    price: null,
    availability: null,
    // images: null,
    // terms: null,
  };
  const option = [
    { name: "available", value: 1 },
    { name: "not available", value: 0 },
  ];
  

  const [formFields, setFormFields] = useState(fields);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState(null);
  const [next1, setNext] = useState(0);
 
  const onSubmit = async (e) => {
    e.preventDefault();
    setFieldErrors(null);
    console.log(formFields);
    setIsLoading(true);

    const errors = {};
    if (!formFields.description) {
      errors.description = "Please enter description";
    }
    if (!formFields.name) {
      errors.name = "Please enter name of your business";
    }
    if (!formFields.price) {
      errors.price = "Please enter address of your price";
    }
    // if (!formFields.category) {
    //   errors.category = "Please choose category your business belongs to.";
    // }
    if (!formFields.availability) {
      errors.availability = "This field is availabilty";
    }

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      setIsLoading(false);
      return;
    }

    let image_urls = [];
    if (imageFiles?.length) {
      image_urls = await firebase_service.uploadManyFilesOnFirebase({
        files: imageFiles.map((f) => f),
        foldername: "business-portal-images",
      });
    }

    let business_id = ctx.env?.business_id;
    console.log("BusinesssId", business_id);
    if (!business_id) {
      business_id = await FlutterInterface.getUserId();
      if (business_id) {
        ctx.setEnv({ ...ctx.env, business_id: business_id });
      }
    }

   const {  description,
    name,
    price,
    availability, } = formFields;
 const options = {
   method: "PUT", // Use 'PUT' to update or create a resource with the specified key
   headers: {
     "Content-Type": "application/json", // Correct the content type here
   },
   body: JSON.stringify({
     description,
     htmlbody: `<h1>${name}</h1> 
                <div class="col-lg-5 order-1 order-lg-2 text-center text-lg-start" style="text-align:center">   
                    <h3>${price}</h3>              
                    <img src="${image_urls[0]}" class="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="300">     
                    <p>${availability}</p>
                </div>`,
   }),
 };

 const keyOfYourChoice = name; // Replace 'name' with your custom key
 const url = `https://react-firebase-ec8e6-default-rtdb.firebaseio.com/UserData/${keyOfYourChoice}.json`;

 try {
   // Check if the key already exists by fetching the data
   const checkResponse = await fetch(url);
   if (checkResponse.ok) {
     // Key already exists, show an alert
     alert(`Data with key '${keyOfYourChoice}' already exists`);
   } else {
     // Key does not exist, create or update the data
     const createResponse = await fetch(url, options);

     if (createResponse.ok) {
       alert("Data created or updated successfully");
     } else {
       alert("Error occurred while creating or updating data");
     }
   }
 } catch (error) {
   console.error("An error occurred:", error);
 }

  };
  return (
    <>
      <Helmet>
        <style>
          {`
          .PhoneInputInput {
            background-color: rgb(243 244 246 / var(--tw-bg-opacity));
            padding: 0.75rem;
            outline-color: #2196F3;
          }
          `}
        </style>
      </Helmet>
      <AppToastContainer />
      <div className="flex flex-col mt-[10px] items-center w-[full]">
        <div className="flex items-center justify-center text-[#2B2B2B] text-[22px]">
          Add new product
        </div>
        <div className=" border-slate-400"></div>
        <form
          onSubmit={onSubmit}
          className="text-[#2B2B2B]  mt-[10px] text-[20px] w-[80%] md:w-[60%] mx-auto"
        >
          <div className={next1 === 0 ? "" : "hidden"}>
            <div className="">
              <div className=" ">
                <TextField
                  label={"Item Name"}
                  placeholder="Enter your item name"
                  value={formFields?.name ?? ""}
                  error={fieldErrors?.name}
                  onChange={(e) =>
                    setFormFields({ ...formFields, name: e.target.value })
                  }
                />
                <TextField
                  label={"Description"}
                  placeholder="Type here"
                  error={fieldErrors?.description}
                  onChange={(e) =>
                    setFormFields({
                      ...formFields,
                      description: e.target.value,
                    })
                  }
                />

                <TextField
                  label={"Price"}
                  placeholder="Enter price"
                  error={fieldErrors?.price}
                  onChange={(e) =>
                    setFormFields({ ...formFields, price: e.target.value })
                  }
                />

                <div className="mt-4">
                  <TextFieldLabel label={"Availability"} />
                  <select
                    className={
                      "text-[13px] block w-full bg-gray-100 py-3 rounded-md px-3 focus:outline-[#2196F3]" +
                      (isApple ? " appearance-none h-[42px]" : "")
                    }
                    onChange={(e) =>
                      setFormFields({
                        ...formFields,
                        availability: e.target.value,
                      })
                    }
                  >
                    <option>Select</option>
                    {option.map((item, index) => (
                      <option key={"item" + index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {fieldErrors?.availability ? (
                    <TextFieldError text={fieldErrors?.availability} />
                  ) : (
                    <></>
                  )}
                </div>

                <div className="mt-4">
                  <TextFieldLabel label="Upload Images" />
                  {fieldErrors?.images ? (
                    <TextFieldError text={fieldErrors?.images} />
                  ) : (
                    <></>
                  )}
                  <MBUploadPhotos1
                    onChange={(files) => {
                      if (files?.length) {
                        setImageFiles(files);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <button
                className="rounded-md py-2 mb-5 text-[15px] w-full bg-[#009bfb] hover:bg-lightBlue-600 text-white disabled:bg-slate-200"
                disabled={isLoading}
              >
                {/* {isLoading ? (
                  <img src={AppImages.loading} className="w-[20px] mx-auto" />
                ) : ( */}
                  <span>Submit</span>
                {/* )} */}
              </button>
              <div
                className="text-center text-[#24ACE3] border-b-[1px] px-4 border-[#24ACE3] block mx-auto text-[14px]"
                onClick={() => setNext(0)}
              >
                Back
              </div>
            </div>
          </div>
        </form>
        {showSuccessModal && (
          <ReactModal
            isOpen={true}
            className="Modal Success-Modal"
            overlayClassName="Modal-Overlay"
          >
            <div className="p-4">
              <div className="flex items-center justify-center">
                <img
                  src={AppImages.checkmark}
                  alt="checkmark"
                  className="w-[60px] svg-green"
                />
              </div>
              <p className="text-center my-4 text-green-600 font-medium">
                Your Product has been added successfully!
              </p>
              <div
                className="text-center p-2 cursor-pointer"
                onClick={(e) => {
                  navigate("/app/business");
                  // window.location.reload();
                }}
              >
                <p className=" font-bold text-[14px] text-green-600 border-dotted border-b-[1px] inline">
                  Click to view
                </p>
              </div>
            </div>
          </ReactModal>
        )}
      </div>
    </>
  );
}

function TextFieldLabel({ label, htmlFor }) {
  return (
    <label htmlFor={htmlFor} className="text-[13px] my-1">
      {label}
    </label>
  );
}

function TextField({ label, id, placeholder = "", onChange, error = null }) {
  return (
    <div className="mt-4">
      <TextFieldLabel label={label} htmlFor={id} />
      <input
        id={id}
        type="text"
        placeholder={placeholder ?? label}
        className="bg-gray-100 px-3 py-3 border-[#5e5954] rounded-md focus:outline-[#2196F3] w-full text-[13px]"
        onChange={onChange}
      />
      {error ? <TextFieldError text={error} /> : <></>}
    </div>
  );
}

function TextFieldError({ text }) {
  return <div className="text-red-500 text-[13px] my-1">{text}</div>;
}

export default Onecallkey;
