import { useContext, useEffect, useState } from "react";
import {
  LAMBDA_API_BUSINESS_PORTAL_URL,
} from "../contexts/MyBusinessContext.jsx";
import { CiImageOn } from "react-icons/ci";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../../basic/Loading.jsx";
import { SingleBusinessContext } from "../SingleBusiness/contexts/SingleBusinessContext.jsx";

function BusinessOffers() {
  const sbc = useContext(SingleBusinessContext);
  const [isFetchingStats, setIsFetchingStats] = useState(false);
  const [data, setData] = useState([]);
  
   
 useEffect(() => {
  async function fetchData() {
    try {
      let marker_id = sbc?.marker_id || sbc.marker?._id;

      if (!marker_id) {
        throw new Error("No marker id");
      }

     const response = await axios.get(
       `${LAMBDA_API_BUSINESS_PORTAL_URL}/app/single-business/get-offers?marker_id=${marker_id}`
     );


      console.log("Dataaa", response);
      const newData = response.data.data.offers; // Use response.data directly

      if (newData.length > 0) {
        // Append the new data to the existing data
        setData(newData);
       //  setCurrentPage(currentPage + 1);
      } else {
        // No more data available
      }
     //  setData(response.data.data.products);

      if (response.data?.status !== 200) {
        throw response.data;
      } else {
        // Your other logic here
      }

      // Set isFetchingStats to false when the response is received
      setIsFetchingStats(false);
    } catch (error) {
      console.error(error);

      if (error?.error) {
        toast.error(
          error?.isOperationalError ? "Something went wrong" : error?.message,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }

      // Set isFetchingStats to false when there is an error
      setIsFetchingStats(false);
    }
  }
   setIsFetchingStats(true);
   fetchData(); // Call the async function inside useEffect
 }, [sbc.marker?._id, sbc?.marker_id]);
  return (
    <>
     <div className="w-[398px]">
        <div className="flex flex-col items-center justify-center w-[398px]">             
          <div>     
            {isFetchingStats ? (
              <div className="text-black">
                Loading...
                <Loading />
              </div>
            ) : (
              data?.length > 0 ? (
              <div>
                <div className="text-[18px] text-[#7d7d7d] mx-[2%] w-[350px] my-6 font-medium">
                  Ongoing offers:
                </div>
                <div>{data?.map((item, index) => (
                  <>
                   <Link
                                  to={{
                                    pathname: '/app/view/offer',
                                  }}
                                  state={{ item: item }}
                                >
                    <div key={index} className=" flex flex-row my-2 w-[370px]">
                      <div className="w-[30%] py-2">
                        {item?.query_messages.find(itemm => itemm.type === 'image') ? (
                          <img
                            src= {item.query_messages.find(itemm => itemm.type === 'image').content}
                            alt="checkmark"
                            className=" mx-auto rounded-full object-contain shadow-md w-[70px] h-[70px] "
                          />
                          ) : (  
                          <div className="bg-slate-100 mx-auto  rounded-full text-[24px] font-semibold w-[70px] h-[70px] flex items-center justify-center">
                            <CiImageOn />
                          </div>
                        )}
                      </div>
                      <div className="w-[70%] relative">
                        <div className="flex justify-between">
                          <div className=" text-[17px] font-bold py-2 pl-2 text-[#464A52]">{item?.category}</div>
                         
                        </div>
                        {item?.query_messages.find(itemm => itemm.type === 'text') ? (
                          <div className=" text-[15px] font-medium pb-2 pl-2 text-[#464A52]">
                            {item.query_messages.find(itemm => itemm.type === 'text').content}
                          </div>
                          ) : ('')
                        }
                      </div>             
                    </div>
                    </Link>
                    
                    <div className="border-[1px] border-slate-200"></div>
                  </>
                ))}
              </div>
            </div>):(<div className="text-center mt-4 text-[18px] font-semibold">No Offers yet</div>)
            )}
          </div>
        </div> 
      </div>
    </>
  );
}

export default BusinessOffers;
